import React from 'react';
import {
    NavigationLevelContextProvider,
    useNavigationLevelContext,
} from './NagivationLevel';
import { NavigationItemRegistration } from './NavigationRegistration';
import { NavigatedRoutedLinkProps } from './NavigationRoutedLinkProps';
import { ACCESS_OK } from '../../hooks/useAccessControl';

export const NavigationRoutedLink: React.FC<
    NavigatedRoutedLinkProps
> = props => {
    /**
     * This component creates metadata for routing and navigation
     * it's children should be either
     *  - NavigationRouted
     *      - you create a next-level navigation link (L1, L2, L3 supported at this moment)
     *  - NavigationRouted
     *      - the content in this will render in case you're on the route of the link
     *
     * The real navigation/routing then spawns from the SideBar, SecondaryNavigation, RoutedContent components
     *
     * <RouteContextProvider>
     *      <NavigationRegistrationContextProvider ...>
     *        <NavigationRoutedLink addressPart='toplvl/' ....> -| renders in the sidebar (L1, L2)
     *           <NavigationRouted ...>                          | or secondary navigation (L3) based on the level nesting
     *               <MyPageContent .../>  --------------------------------- \
     *           <NavigationRouted .../>                         |           |
     *        <NavigationRoutedLink/>                            |           | when route is active,
     *        <SideBar  .../>            <-----------------------/           | this renders here
     *        <SecondaryNavigation />    <-----------------------/           |
     *     </NavigationRegistrationContextProvider>                          |
     *   ...                                                                 |
     *   <RoutedContent/>   <------------------------------------------------/
     *
     *  <RouteContextProvider/>
     */

    const higherLvlContext = useNavigationLevelContext();

    const { children, ...propsWithoutChildren } = props;

    const pathWithSlash = props.path.startsWith('/')
        ? props.path
        : `/${props.path}`;
    const pathWithoutSlash = pathWithSlash.slice(1);

    const registrationProps = {
        ...propsWithoutChildren,
        path: props.routeWithoutContext
            ? pathWithSlash
            : higherLvlContext.address +
              (higherLvlContext.address.endsWith('/') ? '' : '/') +
              pathWithoutSlash,
    };
    const access = props?.access ?? ACCESS_OK;

    if (!access.ok && access.reason !== 'REGION_UNAVAILABLE') {
        return <></>;
    }

    const availableInRegion = access.reason !== 'REGION_UNAVAILABLE';

    return (
        <NavigationLevelContextProvider
            addressPart={props.path}
            routeWithoutContext={props.routeWithoutContext}
            hierarchyIndex={props.index}
        >
            <NavigationItemRegistration {...registrationProps} />
            {availableInRegion && props.children}
        </NavigationLevelContextProvider>
    );
};
