import React from 'react';
import { useAppConfigContext } from '../AppConfigProvider';
import { IApiConfig, useApi } from '../../hooks/useApi';
import { UniversalAuthUser, useUser } from '../../hooks/useUser';
import {
    TenantStatusContextProps,
    TenantQuicksightSleepStatusResponse,
} from './TenantStatusContext.types';

export const TenantStatusContext =
    React.createContext<TenantStatusContextProps>({
        loading: false,
    });

export const useTenantStatusContext = () =>
    React.useContext(TenantStatusContext);

export const TenantStatusProvider: React.FunctionComponent = props => {
    const { api } = useAppConfigContext();
    const { children } = props;
    const user = useUser();
    const tenantId = user?.isUniversalUser()
        ? (user as UniversalAuthUser).tenantId
        : null;

    const quicksightSleepStatusApiConfig = React.useMemo<IApiConfig>(
        () => ({
            url: encodeURI(
                `${api?.quicksightDashboards}/v1/data/tenants/${tenantId}/quicksight/sleep-status`,
            ),
            useAuth: true,
            executeType: 'manual',
            method: 'GET',
        }),
        [api, tenantId],
    );

    const {
        data: sleepStatusData,
        loading: fetchingSleepStatus,
        error: sleepStatusError,
        execute: fetchSleepStatus,
    } = useApi<TenantQuicksightSleepStatusResponse>(
        quicksightSleepStatusApiConfig,
    );

    React.useEffect(() => {
        if (tenantId) {
            fetchSleepStatus();
        }
    }, [fetchSleepStatus, tenantId]);

    const noQuicksightSleepStatus = sleepStatusError?.response?.status === 404;
    const quicksightSleepStatus = sleepStatusData?.status;

    return (
        <TenantStatusContext.Provider
            value={{
                loading: fetchingSleepStatus,
                data: { quicksightSleepStatus },
                error: noQuicksightSleepStatus ? null : sleepStatusError,
            }}
        >
            {children}
        </TenantStatusContext.Provider>
    );
};

export default TenantStatusProvider;
