import useAxios from 'axios-hooks';
import React from 'react';
import axios, {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponseTransformer,
} from 'axios';
import { usePrevious } from 'react-use';
import isEqual from 'lodash/isEqual';
import { useAuth0Context } from '../auth/auth0/Auth0Context';

export interface IApiConfig extends AxiosRequestConfig {
    isLocalRequest?: boolean;
    useAuth?: boolean;
    executeType?: 'automatic' | 'manual' | 'onFirstRender';
    useCache?: boolean;
}

const apiConfigDefaults: IApiConfig = {
    isLocalRequest: false,
    useAuth: true,
    executeType: 'automatic',
    useCache: true,
};

const transformApiConfig: (
    apiConfig: IApiConfig,
    accessToken?: string,
) => AxiosRequestConfig = (apiConfig, accessToken) => {
    const result: AxiosRequestConfig = { ...apiConfig };

    if (apiConfig.useAuth) {
        if (!result.headers) result.headers = {};
        result.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (apiConfig.transformResponse) {
        result.transformResponse = (
            axios.defaults.transformResponse as AxiosResponseTransformer[]
        ).concat(
            Array.isArray(apiConfig.transformResponse)
                ? apiConfig.transformResponse
                : [apiConfig.transformResponse],
        );
    }
    return result;
};

export const useApi = <T>(config: IApiConfig) => {
    const apiConfig = React.useMemo(
        () => ({ ...apiConfigDefaults, ...config }),
        [config],
    );

    const { accessToken } = useAuth0Context();
    const executeType = apiConfig.executeType || 'automatic';

    const [executed, setExecuted] = React.useState(false);

    const axiosConfig = React.useMemo(
        () => transformApiConfig(apiConfig, accessToken),
        [accessToken, apiConfig],
    );

    const [responseValues, executeRequest] = useAxios<T>(
        {},
        {
            // Setting manual to false seems to cause several problems
            // it seems better to keep it manual and simulate automatic via a useEffect
            manual: true,
            useCache: apiConfig.useCache,
        },
    );

    const [hookResponseValues, setHookResponseValues] = React.useState<{
        data?: T;
        loading: boolean;
        error: AxiosError<any> | null;
    }>({
        data: undefined,
        loading: false,
        error: null,
    });

    React.useEffect(() => {
        setHookResponseValues(responseValues);
    }, [responseValues]);

    const [loading, setLoading] = React.useState(executeType !== 'manual');

    React.useEffect(() => {
        if (!executed && executeType === 'onFirstRender') {
            setExecuted(true);
            setLoading(true);
            executeRequest(axiosConfig).catch(() => {});
        }
    }, [executed, executeRequest, executeType, axiosConfig]);

    const previousData = usePrevious(axiosConfig.data);
    const previousUrl = usePrevious(axiosConfig.url);

    React.useEffect(() => {
        if (
            executeType === 'automatic' &&
            (!isEqual(previousData, axiosConfig.data) ||
                previousUrl !== axiosConfig.url)
        ) {
            setExecuted(true);
            setLoading(true);
            executeRequest(axiosConfig).catch(() => {});
        }
    }, [executeRequest, executeType, axiosConfig, previousData, previousUrl]);

    // Axios loading state seems to switch to 'true' way too late, which causes some issues
    // that's why we're using our own loading state and only watching the axios loading to go true -> false
    const previousAxiosLoading = usePrevious(responseValues.loading);
    React.useEffect(() => {
        if (previousAxiosLoading && !responseValues.loading) {
            setLoading(false);
        }
    }, [responseValues.loading, previousAxiosLoading]);

    const execute = React.useCallback(
        (newConfig?: AxiosRequestConfig) => {
            setExecuted(true);
            setLoading(true);
            if (!newConfig) executeRequest(axiosConfig).catch(() => {});
            else
                executeRequest(
                    transformApiConfig(
                        { ...apiConfig, ...newConfig },
                        accessToken,
                    ),
                ).catch(() => {});
        },
        [executeRequest, accessToken, apiConfig, axiosConfig],
    );

    return {
        ...hookResponseValues,
        loading,
        reset: () => {
            if (loading) {
                console.warn(
                    `useApi hook was reset during loading state 
                    (data will be overridden once request is finished)`,
                );
            }
            setHookResponseValues({
                data: undefined,
                loading: false,
                error: null,
            });
        },
        execute,
    };
};
