import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

export interface AuthProviderCacheProps {
    audience: string;
    client_id: string;
    bb_login_params?: string;
    domain: string;
}

export const useAuthProviderCache = () => {
    const [dataStr, setDataStr] = useLocalStorage('authContextData', '');
    const history = useHistory();
    const error = React.useRef<string>();

    const dataJson: AuthProviderCacheProps | undefined = React.useMemo(() => {
        if (dataStr === '') {
            return undefined;
        }
        let dataObject;

        try {
            dataObject = JSON.parse(dataStr);
        } catch {
            error.current = `Invalid AuthProviderCache -> expected JSON serializable, got ${dataStr}`;
            return undefined;
        }

        if (typeof dataObject !== 'object') {
            error.current = `Invalid AuthProviderCache -> expected JSON serializable object, got ${dataStr}`;
            return undefined;
        }

        if (
            !Object.keys(dataObject).includes('audience') ||
            !Object.keys(dataObject).includes('client_id') ||
            !Object.keys(dataObject).includes('domain')
        ) {
            error.current = `Invalid AuthProviderCache -> expected properties audience, client_id, domain to be present; got: ${dataStr}`;
            return undefined;
        }

        return dataObject;
    }, [dataStr]);

    const setJson = React.useCallback(
        (data: AuthProviderCacheProps | undefined) => {
            setDataStr(data === undefined ? '' : JSON.stringify(data));
        },
        [setDataStr],
    );

    if (error.current) {
        history.push('/');
        console.error(error.current);
        // unset it outside of hook, as the hook takes 2+ render cycles to write it
        localStorage.setItem('authContextData', '');
        throw new Error(`Failed to load Authentication provider cache.`);
    }

    return {
        cache: dataJson,
        setCache: setJson,
    };
};
