import React from 'react';
import { useNavigationLevelContext } from './NagivationLevel';
import { Routed } from './RouteContext';

export const NavigationRouted: React.FC<{
    exact?: false;
    path?: string;
}> = props => {
    /**
     * Based on the level of the route (created from NavigationRoutedLink/NavigationLevelContext)
     * registers the child of this component to be rendered on that route
     */

    const navContext = useNavigationLevelContext();
    let addr = navContext.address;

    if (props.path) {
        addr += `/${props.path}`;
    }

    return (
        <Routed key={addr} path={addr} exact={props.exact ?? true}>
            {props.children}
        </Routed>
    );
};
