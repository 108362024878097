import * as React from 'react';
import { SvgIcon } from '@bb-ui/react-library/dist/components/SvgIcon';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import classNames from 'classnames';

interface IAnthologyLogoProps {
    className?: string;
}

const styles = () =>
    createStyles({
        logo: {
            height: '4rem',
        },
        gradientFill: {
            fill: 'url(#linear-gradient)',
            strokeWidth: '0px',
        },
        blackFill: {
            fill: 'black',
            strokeWidth: '0px',
        },
    });

const options = {
    name: 'AnthologyLogo-',
};

export const useStyles = makeStyles(styles, options);

export const AnthologyLogo: React.FunctionComponent<
    IAnthologyLogoProps
> = props => {
    const classes = useStyles();

    return (
        <SvgIcon
            viewBox="0 0 1297.65 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={
                props.className
                    ? classNames(classes.logo, props.className)
                    : classes.logo
            }
        >
            <g>
                <defs>
                    <linearGradient
                        id="linear-gradient"
                        x1="1124.53"
                        y1="204.06"
                        x2="1239.94"
                        y2="4.16"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset=".55" stopColor="#0054bc" />
                        <stop offset=".85" stopColor="#0dac41" />
                        <stop offset=".99" stopColor="#caef01" />
                    </linearGradient>
                </defs>
                <path
                    className={classes.gradientFill}
                    d="M1166.21,148.53l-34.46,59.69h32.06s18.43-31.93,18.43-31.93l18.43,31.93h32.06s-34.46-59.69-34.46-59.69h-32.06ZM1212.69,140.2l34.46,59.69,16.03-27.76-18.43-31.93h36.87s16.03-27.76,16.03-27.76h-68.92s-16.03,27.76-16.03,27.76ZM1228.72,95.78h68.92s-16.03-27.76-16.03-27.76h-36.87s18.43-31.93,18.43-31.93l-16.03-27.76-34.46,59.69,16.03,27.76ZM1198.27,59.69L1232.73,0h-32.06s-18.43,31.93-18.43,31.93L1163.8,0h-32.06s34.46,59.69,34.46,59.69h32.06ZM1151.78,68.02l-34.46-59.69-16.03,27.76,18.43,31.93h-36.87s-16.03,27.76-16.03,27.76h68.92s16.03-27.76,16.03-27.76Z"
                />
                <polygon
                    className={classes.blackFill}
                    points="1096.8 130.81 1069.71 177.72 1042.62 130.81 1003.43 130.81 1050.12 211.67 1012 277.68 1012.2 277.49 1051.31 277.49 1136 130.81 1096.8 130.81"
                />
                <path
                    className={classes.blackFill}
                    d="M237.66,247.89v-64.98c0-15.82-5.49-23.21-18.35-23.21-14.13,0-20.04,7.81-20.04,23.63v64.56h-35.02v-117.09h32.7v12.45h.42c7.38-12.02,17.72-16.45,31.86-16.45,21.1,0,43.46,11.81,43.46,46.41v74.68h-35.02Z"
                />
                <path
                    className={classes.blackFill}
                    d="M580.94,251.48c-35.65,0-63.08-26.37-63.08-62.24s28.06-62.24,63.08-62.24,63.08,26.37,63.08,61.81-27.85,62.66-63.08,62.66ZM609,189.24c0-15.61-11.81-29.54-28.27-29.54-15.4,0-27.85,13.5-27.85,29.54s12.24,29.54,28.06,29.54,28.06-13.92,28.06-29.54Z"
                />
                <path
                    className={classes.blackFill}
                    d="M780.09,251.48c-35.65,0-63.08-26.37-63.08-62.24s28.06-62.24,63.08-62.24,63.08,26.37,63.08,61.81-27.85,62.66-63.08,62.66ZM808.14,189.24c0-15.61-11.81-29.54-28.27-29.54-15.4,0-27.85,13.5-27.85,29.54s12.24,29.54,28.06,29.54,28.06-13.92,28.06-29.54Z"
                />
                <path
                    className={classes.blackFill}
                    d="M954.1,234.18c-5.27,11.18-18.35,17.51-32.7,17.51-34.18,0-58.86-26.37-58.86-61.6s25.11-63.08,58.86-63.08c15.19,0,26.79,5.7,32.49,14.77h.42v-10.97h35.02v104.64c0,15.82-2.11,27.85-6.33,35.86-8.44,16.24-31.43,28.69-60.76,28.69-33.12,0-54.64-18.78-57.59-41.14h40.51c4.01,8.65,11.81,11.6,20.46,11.6,17.72,0,28.48-11.6,28.48-32.91v-3.38ZM954.32,189.88c0-17.09-11.6-30.17-28.69-30.17-15.61,0-28.06,13.08-28.06,29.11s11.18,30.17,28.06,30.17,28.69-12.24,28.69-29.11Z"
                />
                <polygon
                    className={classes.blackFill}
                    points="347.46 91.78 312.44 91.78 312.44 130.81 289.1 130.81 289.1 157.39 312.44 157.39 312.44 247.89 347.46 247.89 347.46 157.39 370.81 157.39 370.81 130.81 347.46 130.81 347.46 91.78"
                />
                <path
                    className={classes.blackFill}
                    d="M456.82,126.8c-13.92,0-25.32,5.7-30.38,15.19v-50.21h-35.02v156.11h35.02v-65.82c0-14.56,6.33-22.36,19.62-22.36s18.78,8.65,18.78,23.84v64.34h35.02v-73.63c0-30.59-16.24-47.47-43.04-47.47Z"
                />
                <rect
                    className={classes.blackFill}
                    x="663.56"
                    y="91.78"
                    width="35.02"
                    height="156.11"
                />
                <polygon
                    className={classes.blackFill}
                    points="57.77 91.78 0 247.89 42.83 247.89 74.97 150.33 75.59 150.33 107.74 247.89 150.57 247.89 92.79 91.78 57.77 91.78"
                />
            </g>
        </SvgIcon>
    );
};
