import React from 'react';
import { Link, Typography } from '@bb-ui/react-library';

import { Grid } from '@material-ui/core';
import { useStyles } from '../ErrorPages.styles';
import { useApplicationTitle } from '../../../hooks/useApplicationTitle';

export const BrowserIncompatibilityErrorPage: React.FunctionComponent = () => {
    const classes = useStyles();

    // This does not get localized
    useApplicationTitle('Browser is not supported');

    return (
        <Grid
            className={classes.pageContainer}
            data-testid="browser-incompatibility-error-page-container"
            container
            alignItems="center"
            justifyContent="center"
        >
            <img
                // eslint-disable-next-line @typescript-eslint/no-require-imports
                src={require('../../../../app/assets/images/copy/ErrorPages/browserIncompatibilityIllustration.svg')}
                alt=""
                className={classes.illustration}
            />
            <Typography
                data-testid="browser-incompatibility-title"
                variant="h1"
                align="center"
            >
                We noticed the browser you're using isn't supported.
            </Typography>
            <Typography className={classes.text} align="center">
                You can switch to a&nbsp;
                <Link
                    href="https://help.blackboard.com/Anthology_Illuminate/Supported_Browsers"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="browsers-compatible-link"
                >
                    browser Anthology Illuminate supports
                </Link>
                &nbsp;to access it.
            </Typography>
            <Typography className={classes.text} align="center">
                If the problem continues, have your administrator&nbsp;
                <Link
                    href="https://blackboard.my.salesforce-sites.com/btbb_publichome"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="support-case-link"
                >
                    submit a support case on Behind the Blackboard
                </Link>
                .
            </Typography>
        </Grid>
    );
};
