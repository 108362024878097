import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useAppLoadingContext } from '../../contexts/AppLoadingContext';

type RouteContextProps = {
    addRoute: (props: {
        exact: boolean;
        path: string;
        target: React.ReactNode;
    }) => void;
    unsetRoute: (key: string) => void;
    routeData: {
        [path: string]: {
            exact: boolean;
            path: string;
            target: React.ReactNode;
        };
    };
};

export const RouteContext = React.createContext<RouteContextProps>({
    addRoute: () => {},
    unsetRoute: () => {},
    routeData: {},
});

const useRouteContext = () => React.useContext(RouteContext);

export const RouteContextProvider: React.FC = ({ children }) => {
    const routeRef = React.useRef<{
        [key: string]: {
            exact: boolean;
            path: string;
            target: React.ReactNode;
        };
    }>({});

    const [routes, setRoutes] = React.useState<{
        [key: string]: {
            exact: boolean;
            path: string;
            target: React.ReactNode;
        };
    }>({});

    const addRoute = React.useMemo(
        () =>
            (props: {
                exact: boolean;
                path: string;
                target: React.ReactNode;
            }) => {
                routeRef.current[props.path] = {
                    exact: props.exact,
                    path: props.path,
                    target: props.target,
                };
                // console.info(routeRef.current);
                setRoutes({ ...routeRef.current });
            },
        [],
    );
    const unsetRoute = React.useMemo(
        () => (key: string) => {
            delete routeRef.current[key];
            setRoutes(routeRef.current);
        },
        [],
    );

    return (
        <RouteContext.Provider
            value={{ addRoute, unsetRoute, routeData: routes }}
        >
            {children}
        </RouteContext.Provider>
    );
};

export const Routed: React.FC<{
    children: React.ReactNode;
    exact: boolean;
    path: string;
}> = props => {
    // Registers a route (and what it's rendering) into the RouteContent

    const { addRoute, unsetRoute } = useRouteContext();
    React.useEffect(() => {
        addRoute({
            exact: props.exact,
            path: props.path,
            target: props.children,
        });
    }, [addRoute, props]);

    React.useEffect(
        () =>
            // unmount
            () => {
                unsetRoute(props.path);
            },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return <></>;
};

export const RouteContent: React.FC = () => {
    // Renders all routes which were registered using the Routed component into a switch
    // When navigating into a route which does not exist, reditects to home

    const { routeData } = useRouteContext();
    const { loading } = useAppLoadingContext();
    return (
        <Switch>
            {Object.keys(routeData)
                .sort()
                .map(key => {
                    const route = routeData[key];
                    return (
                        <Route key={key} exact={route.exact} path={route.path}>
                            {route.target}
                        </Route>
                    );
                })}
            {/* redirect only in case loading is finished as during that time routes are being populated */}
            {!loading && <Redirect key="redirect.home" to="/" />}
        </Switch>
    );
};
