import React from 'react';
import { useFeatureFlagContext } from '@bb-config-ui/feature-flag-provider';
import { AppConfigContext } from './AppConfigProvider';
import { FeatureName, Features } from '../featureFlags/Feature.types';

/**
 * This context is used to combine both the feature flags pulled in from the
 * foundations service and the feature flags from the config files internal to the application.
 * It can be used to share the state of feature flags across the app.
 *  @returns {Features} An object containing flag names and statuses represented by boolean values
 */
export const useFeatureContext = (): Features => {
    // const featureFlags: Features = {};
    const flagContext = useFeatureFlagContext();
    const configContext = React.useContext(AppConfigContext);

    const featureFlags: Features = React.useMemo(() => {
        const featureFlags: Features = {};
        const { flags } = flagContext ?? {};

        const featureFlagConfig = configContext.featureFlags ?? {};

        Object.entries(featureFlagConfig).forEach(([flag, enabled]) => {
            featureFlags[flag as FeatureName] = enabled;
        });

        flags.forEach(feature => {
            featureFlags[feature.flagKey as FeatureName] =
                featureFlags[feature.flagKey as FeatureName] ||
                feature.flagValue === 'true';
        });
        return featureFlags;
    }, [flagContext, configContext]);

    return featureFlags;
};
