import React from 'react';
import { useAppConfigContext } from '../contexts/AppConfigProvider';
import { useApi } from './useApi';
import { useIntervalWithLimit } from './useIntervalWithLimit';
import { UniversalAuthUser, useUser } from './useUser';

export enum SnowflakeUserProvisioningState {
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    UNKNOWN_STATE = 'UNKNOWN_STATE',
    GETTING_INITIAL_STATE = 'GETTING_INITIAL_STATE',
    NOT_PROVISIONED = 'NOT_PROVISIONED',
    IN_PROGRESS = 'IN_PROGRESS',
    READY = 'READY',
    FAILED = 'FAILED',
}

type ResultState =
    | {
          state: Exclude<
              SnowflakeUserProvisioningState,
              | SnowflakeUserProvisioningState.NOT_PROVISIONED
              | SnowflakeUserProvisioningState.FAILED
              | SnowflakeUserProvisioningState.UNKNOWN_STATE
          >;
      }
    | {
          state: SnowflakeUserProvisioningState.NOT_PROVISIONED;
          invokeProvisioning: () => void;
      }
    | {
          state: SnowflakeUserProvisioningState.FAILED;
          retryProvisioning: () => void;
      }
    | {
          state: SnowflakeUserProvisioningState.UNKNOWN_STATE;
          getProvisioningState: () => void;
      };

export interface GetSnowflakeUserStatusResult {
    tenantId: string;
    loginName: string;
    status: 'SUCCESS' | 'IN_PROGRESS' | 'FAILED';
    statusMsg: string;
    updatedUtc: string;
}

/**
 * Calls functions until one of them returns something that is not undefined,
 * then it sets state in case previous state wasn't the same already
 */
class ActionChain<T> {
    private readonly actionList: (() => undefined | T)[] = [];

    constructor(firstAction: () => undefined | T) {
        this.next(firstAction);
    }

    next(action: () => undefined | T): ActionChain<T> {
        this.actionList.push(action);
        return this;
    }

    execute(state: [T, (newValue: T) => void]) {
        const [stateValue, setStateValue] = state;

        for (const action of this.actionList) {
            const result = action();
            if (typeof result !== 'undefined') {
                if (result !== stateValue) {
                    setStateValue(result);
                }
                return result;
            }
        }
    }
}

const manageState = <T>(
    state: [T, (newValue: T) => void],
    props: { condition?: () => boolean; actionChain: ActionChain<T> },
) => {
    if (props.condition && !props.condition()) {
        return;
    }

    props.actionChain.execute(state);
};

export const useSnowflakeUserProvisioning = (): ResultState => {
    const { api } = useAppConfigContext();
    const user = useUser();

    const logErrorMessage = React.useCallback(
        (msg: string) =>
            // eslint-disable-next-line no-console
            console.log(
                `Unexpected failure during Snowflake user provisioning for ${user}\n${msg}`,
            ),
        [user],
    );

    const [resultStateIdentifier, setResultStateIdentifier] =
        React.useState<SnowflakeUserProvisioningState>(
            SnowflakeUserProvisioningState.UNKNOWN_STATE,
        );

    const {
        data: getSnowflakeUserStatusResult,
        error: getSnowflakeUserStatusError,
        loading: getSnowflakeUserStatusLoading,
        execute: executeGetSnowflakeUserStatus,
    } = useApi<GetSnowflakeUserStatusResult>({
        useAuth: true,
        method: 'GET',
        executeType: 'manual',
    });

    const getRequestHasFailed =
        getSnowflakeUserStatusResult?.status === 'FAILED' ||
        getSnowflakeUserStatusError;

    const {
        data: createSnowflakeUserData,
        loading: createSnowflakeUserLoading,
        error: createSnowflakeUserError,
        execute: executeCreateSnowflakeUser,
        reset: resetCreateSnowflakeUserRequestData,
    } = useApi<GetSnowflakeUserStatusResult>({
        useAuth: true,
        method: 'POST',
        executeType: 'manual',
    });

    const isSupported = React.useCallback(
        (): boolean =>
            Boolean(
                api?.authProv &&
                    user &&
                    user.isUniversalUser() &&
                    user.hasSnowflakeAccess(),
            ),
        [api, user],
    );

    const currentStateIs = React.useCallback(
        (state: SnowflakeUserProvisioningState) =>
            resultStateIdentifier === state,
        [resultStateIdentifier],
    );

    // automatically check for whether user/api context has been lost, if so, set NOT_SUPPORTED state
    const manageResultState = React.useCallback(
        (
            forInitialState: SnowflakeUserProvisioningState,
            ...actions: (() => SnowflakeUserProvisioningState | undefined)[]
        ) => {
            const actionChain = new ActionChain<SnowflakeUserProvisioningState>(
                () => {
                    if (!isSupported()) {
                        return SnowflakeUserProvisioningState.NOT_SUPPORTED;
                    }
                },
            );
            actions.forEach(a => actionChain.next(a));

            return manageState(
                [resultStateIdentifier, setResultStateIdentifier],
                {
                    condition: () => currentStateIs(forInitialState),
                    actionChain,
                },
            );
        },
        [currentStateIs, isSupported, resultStateIdentifier],
    );

    const sendGetUserStatusRequest = React.useCallback(() => {
        if (!isSupported()) {
            throw Error(
                'Attempting to send user creation request without required data!',
            );
        }
        // user being universal auth is enforced in the previous check
        // can't seem to easily type guard from that though
        const universalAuthUser = user as UniversalAuthUser;
        executeGetSnowflakeUserStatus({
            url: encodeURI(
                `${api?.authProv}/v1/data/tenants/${universalAuthUser.tenantId}/snowflake/login-names/${universalAuthUser.userId}`,
            ),
        });
    }, [isSupported, user, executeGetSnowflakeUserStatus, api]);

    const {
        disable: disablePeriodicGetRequests,
        enable: enablePeriodicGetRequests,
        resetCounter: resetGetRequestCounter,
        isStopped: periodicGetRequestsStopped,
    } = useIntervalWithLimit(
        sendGetUserStatusRequest,
        2500,
        30,
        getSnowflakeUserStatusLoading,
    );

    const [getStatusFailureIdentifier, setGetStatusFailureIdentifier] =
        React.useState<undefined | string>();

    const [
        previousGetStatusFailureIdentifier,
        setPreviousGetStatusFailureIdentifier,
    ] = React.useState<undefined | string>();

    React.useEffect(() => {
        if (
            getSnowflakeUserStatusResult?.status === 'FAILED' ||
            [404, 409].includes(
                getSnowflakeUserStatusError?.response?.data.code,
            )
        ) {
            setGetStatusFailureIdentifier(
                getSnowflakeUserStatusResult?.updatedUtc ||
                    getSnowflakeUserStatusError?.response?.data.code,
            );
        }
    }, [
        getSnowflakeUserStatusResult,
        getSnowflakeUserStatusError,
        logErrorMessage,
        disablePeriodicGetRequests,
    ]);

    React.useEffect(() => {
        if (getStatusFailureIdentifier) {
            setPreviousGetStatusFailureIdentifier(getStatusFailureIdentifier);
        }
    }, [getStatusFailureIdentifier]);

    const sendCreateUserRequest = React.useCallback(() => {
        if (!isSupported()) {
            throw Error(
                'Attempting to send user creation request without required data!',
            );
        }
        // user being universal auth is enforced in the previous check
        // can't seem to easily type guard from that though
        const universalAuthUser = user as UniversalAuthUser;

        // TODO: api seems to be wrong - user id should be param in body
        executeCreateSnowflakeUser({
            url: encodeURI(
                `${api?.authProv}/v1/data/tenants/${universalAuthUser.tenantId}/snowflake/login-names/${universalAuthUser.userId}`,
            ),
        });
    }, [api, isSupported, user, executeCreateSnowflakeUser]);

    const retryProvisioning = React.useCallback(() => {
        setGetStatusFailureIdentifier(undefined);
        setPreviousGetStatusFailureIdentifier(undefined);
        resetCreateSnowflakeUserRequestData();
        setResultStateIdentifier(SnowflakeUserProvisioningState.UNKNOWN_STATE);
    }, [resetCreateSnowflakeUserRequestData]);

    const switchToReadyStateIfReady = React.useCallback(() => {
        if (getSnowflakeUserStatusResult?.status === 'SUCCESS') {
            disablePeriodicGetRequests();
            return SnowflakeUserProvisioningState.READY;
        }
    }, [disablePeriodicGetRequests, getSnowflakeUserStatusResult]);

    const switchToNotProvisionedStateIfGetFailed = React.useCallback(() => {
        if (getRequestHasFailed) {
            disablePeriodicGetRequests();
            return SnowflakeUserProvisioningState.NOT_PROVISIONED;
        }
    }, [disablePeriodicGetRequests, getRequestHasFailed]);

    const switchToFailedStateIfUnknownGetErrorHappens =
        React.useCallback(() => {
            const errorCode = getSnowflakeUserStatusError?.response?.data.code;
            if (errorCode && errorCode !== 404 && errorCode !== 409) {
                logErrorMessage(
                    `Snowflake user creation failed as unknown error code was thrown (${errorCode})!`,
                );
                return SnowflakeUserProvisioningState.FAILED;
            }
        }, [getSnowflakeUserStatusError, logErrorMessage]);

    // NOT_SUPPORTED -> UNKNOWN_STATE
    React.useEffect(() => {
        manageResultState(SnowflakeUserProvisioningState.NOT_SUPPORTED, () => {
            // can't really think of any case in which this would happen at this moment
            // so this is for future cases where something would change with the global contexts
            if (isSupported()) {
                return SnowflakeUserProvisioningState.UNKNOWN_STATE;
            }
        });
    }, [isSupported, manageResultState]);

    // UNKNOWN_STATE -> GETTING_INITIAL_STATE
    //               -> NOT_PROVISIONED
    //               -> READY
    React.useEffect(() => {
        manageResultState(
            SnowflakeUserProvisioningState.UNKNOWN_STATE,
            () => {
                // GET request was sent, switch state
                if (
                    getSnowflakeUserStatusResult ||
                    getSnowflakeUserStatusError ||
                    getSnowflakeUserStatusLoading
                ) {
                    return SnowflakeUserProvisioningState.GETTING_INITIAL_STATE;
                }
            },
            // if GET request has failed before, set to NOT_PROVISIONED
            switchToNotProvisionedStateIfGetFailed,
            // if GET request has succeeded before, set to READY
            switchToReadyStateIfReady,
        );
    }, [
        getRequestHasFailed,
        getSnowflakeUserStatusError,
        getSnowflakeUserStatusLoading,
        getSnowflakeUserStatusResult,
        manageResultState,
        sendCreateUserRequest,
        sendGetUserStatusRequest,
        switchToNotProvisionedStateIfGetFailed,
        switchToReadyStateIfReady,
    ]);

    // GETTING_INITIAL_STATE -> READY
    //                       -> NOT_PROVISIONED
    //                       -> IN_PROGRESS
    React.useEffect(() => {
        manageResultState(
            SnowflakeUserProvisioningState.GETTING_INITIAL_STATE,
            () => {
                if (getSnowflakeUserStatusResult?.status === 'IN_PROGRESS') {
                    logErrorMessage(
                        `Getting intial state of SF user provisioning resulted with IN_PROGRESS -> 
                        user is either stuck in provisioning or window was refreshed`,
                    );

                    setGetStatusFailureIdentifier('IN_PROGRESS');

                    resetGetRequestCounter();
                    enablePeriodicGetRequests();
                    return SnowflakeUserProvisioningState.IN_PROGRESS;
                }
            },
            switchToNotProvisionedStateIfGetFailed,
            switchToReadyStateIfReady,
        );
    }, [
        enablePeriodicGetRequests,
        getSnowflakeUserStatusResult,
        logErrorMessage,
        manageResultState,
        resetGetRequestCounter,
        switchToNotProvisionedStateIfGetFailed,
        switchToReadyStateIfReady,
    ]);

    // NOT_PROVISIONED -> IN_PROGRESS
    React.useEffect(() => {
        // once the invokeProvisioning callback returned from the
        // NOT_PROVISIONED state is activated, POST request is fired, at that time swap state
        manageResultState(
            SnowflakeUserProvisioningState.NOT_PROVISIONED,
            () => {
                if (
                    createSnowflakeUserData ||
                    createSnowflakeUserError ||
                    createSnowflakeUserLoading
                ) {
                    resetGetRequestCounter();
                    enablePeriodicGetRequests();
                    return SnowflakeUserProvisioningState.IN_PROGRESS;
                }
            },
        );
    }, [
        createSnowflakeUserData,
        createSnowflakeUserError,
        createSnowflakeUserLoading,
        enablePeriodicGetRequests,
        manageResultState,
        resetGetRequestCounter,
        switchToNotProvisionedStateIfGetFailed,
        switchToReadyStateIfReady,
    ]);

    // IN_PROGRESS -> READY
    // IN_PROGRESS -> FAILED
    React.useEffect(() => {
        // once the invokeProvisioning callback returned from the
        // NOT_PROVISIONED state is activated, POST request is fired, at that time swap state
        manageResultState(
            SnowflakeUserProvisioningState.IN_PROGRESS,
            () => {
                // the create request should always succeed, but if not, mark this as failed
                if (createSnowflakeUserError) {
                    logErrorMessage(
                        `Snowflake user creation failed! (${createSnowflakeUserError})`,
                    );
                    return SnowflakeUserProvisioningState.FAILED;
                }
            },
            () => {
                if (
                    getStatusFailureIdentifier &&
                    previousGetStatusFailureIdentifier &&
                    getStatusFailureIdentifier !==
                        previousGetStatusFailureIdentifier
                ) {
                    logErrorMessage(`Snowflake user creation failed!`);
                    disablePeriodicGetRequests();
                    return SnowflakeUserProvisioningState.FAILED;
                }
            },
            () => {
                if (periodicGetRequestsStopped) {
                    logErrorMessage(
                        `Snowflake user creation stuck too long on IN_PROGRESS state!`,
                    );
                    return SnowflakeUserProvisioningState.FAILED;
                }
            },
            switchToFailedStateIfUnknownGetErrorHappens,
            switchToReadyStateIfReady,
        );
    }, [
        createSnowflakeUserData,
        createSnowflakeUserError,
        createSnowflakeUserLoading,
        disablePeriodicGetRequests,
        getStatusFailureIdentifier,
        logErrorMessage,
        manageResultState,
        periodicGetRequestsStopped,
        previousGetStatusFailureIdentifier,
        switchToFailedStateIfUnknownGetErrorHappens,
        switchToNotProvisionedStateIfGetFailed,
        switchToReadyStateIfReady,
    ]);

    const [resultState, setResultState] = React.useState<ResultState>({
        state: SnowflakeUserProvisioningState.UNKNOWN_STATE,
        // UNKNOWN_STATE -> GETTING_INITIAL_STATE
        getProvisioningState: sendGetUserStatusRequest,
    });

    React.useEffect(() => {
        if (resultState.state !== resultStateIdentifier) {
            if (
                resultStateIdentifier ===
                SnowflakeUserProvisioningState.NOT_PROVISIONED
            ) {
                setResultState({
                    state: resultStateIdentifier,
                    // NOT_PROVISIONED -> IN_PROGRESS
                    invokeProvisioning: sendCreateUserRequest,
                });
            } else if (
                resultStateIdentifier ===
                SnowflakeUserProvisioningState.UNKNOWN_STATE
            ) {
                setResultState({
                    state: SnowflakeUserProvisioningState.UNKNOWN_STATE,
                    // UNKNOWN_STATE -> GETTING_INITIAL_STATE
                    getProvisioningState: () =>
                        setResultStateIdentifier(
                            SnowflakeUserProvisioningState.GETTING_INITIAL_STATE,
                        ),
                });
            } else if (
                resultStateIdentifier === SnowflakeUserProvisioningState.FAILED
            ) {
                setResultState({
                    state: resultStateIdentifier,
                    // FAILED -> UNKNOWN_STATE
                    retryProvisioning,
                });
            } else
                setResultState({
                    state: resultStateIdentifier,
                });
        }
    }, [
        resultState.state,
        resultStateIdentifier,
        retryProvisioning,
        sendCreateUserRequest,
    ]);
    return resultState;
};
