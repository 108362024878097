import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import classnames from 'classnames';
import React, { FunctionComponent, Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useLocalStorage, usePrevious, useSessionStorage } from 'react-use';
import { Key } from 'ts-key-enum';

import { ThreeBarMenu } from '@bb-ui/icons/dist/medium/ThreeBarMenu';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';

import { Close } from '@bb-ui/icons/dist/medium';
import { Link, useSnackbar } from '@bb-ui/react-library';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth0Context } from '../../auth/auth0/Auth0Context';
import { PageHeader } from '../PageHeader/PageHeader';

import { useStyles } from './MainPage.styles';

import { useAppLoadingContext } from '../../contexts/AppLoadingContext';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';
import { Footer } from '../Footer/Footer';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { SideBar } from '../SideBar/SideBar';
import { SignInModal } from '../SignInModal/SignInModal';
import { SkipLink } from '../SkipLink/SkipLink';

import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { PageExpandProvider } from '../../contexts/PageExpandContext';
import { SilentFeatureFlagIndicator } from '../../featureFlags/SilentFeatureFlagIndicator';
import { localStorageUASTenantKey } from '../../utilities/constants';
import { useTenantSettingsContext } from '../../contexts/TenantSettingsContext';
import {
    RouteContextProvider,
    RouteContent,
} from '../NavigationRouting/RouteContext';
import { HomeContent } from '../RoutedContent/Home';
import { DeveloperContent } from '../RoutedContent/Developer';
import { ReportingContent } from '../RoutedContent/Reporting';
import { PrivacyAndSecurityContent } from '../RoutedContent/PrivacyAndSecurity';
import { NavigationRegistrationContextProvider } from '../NavigationRouting/NavigationRegistration';
import { SecondaryNavigation } from '../SideBar/SecondaryNavigation';

export const MainPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const { deploymentStage } = useAppConfigContext();
    const { isAuthenticated, logout } = useAuth0Context();

    const classes = useStyles();

    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const { setLoadingFlag } = useAppLoadingContext();
    React.useEffect(() => {
        setLoadingFlag('main.init', true);
    }, [setLoadingFlag]);

    const { openNavDrawer, openGlobalNavDrawer, openSignInModal } =
        useAppNavigationContext();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const mainContentRef = useRef<HTMLElement>(null);
    const closeGlobalNavDrawerButtonRef = useRef<HTMLButtonElement>(null);
    const openGlobalNavDrawerButtonRef = useRef<HTMLButtonElement>(null);

    const { pathname: path } = useLocation();
    const previousPath = usePrevious(path);

    const globalNavOpen = openNavDrawer === 'global';
    const secondaryNavOpen = openNavDrawer === 'secondary';

    const [, setUasTenant] = useLocalStorage<string>(
        localStorageUASTenantKey,
        '',
        {
            raw: true,
        },
    );

    const { data: { timeout } = {} } = useTenantSettingsContext();

    const [sessionEnded, setSessionEnded] = useSessionStorage(
        'sessionEnded',
        false,
    );

    const logoutAndNavigateToHome = React.useMemo(
        () => () => {
            setUasTenant('');
            const loggedOutPath = `${window.location.origin}/`;
            if (logout && isAuthenticated) {
                logout({
                    returnTo: loggedOutPath,
                });
            } else {
                console.error(
                    'Failed to log out: `logout` function does not exist',
                );
            }
        },
        [logout, isAuthenticated, setUasTenant],
    );

    // This is logic for IDLE session timeout
    const onIdle = React.useMemo(
        () => () => {
            // Log out user
            if (logout && isAuthenticated) {
                setSessionEnded(true);
                logoutAndNavigateToHome();
            }
        },
        [logout, isAuthenticated, logoutAndNavigateToHome, setSessionEnded],
    );

    const onActive = React.useMemo(() => () => {}, []);

    const { reset } = useIdleTimer({
        onIdle,
        onActive,
        timeout: timeout ? timeout * 1000 : 900000,
        crossTab: true,
        eventsThrottle: 200,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
            'focus',
            'change',
            'select',
        ],
    });

    useEffect(() => {
        console.warn('Reset timer with timeout (in seconds):', timeout);
        reset();
    }, [timeout, reset]);

    // If session ended due to IDLE session timeout, snackbar has to appear
    useEffect(() => {
        if (deploymentStage === 'local') {
            return;
        }
        if (path !== previousPath && sessionEnded === true) {
            // Inform user about logout via snackbar
            enqueueSnackbar(t('landingPage.idleLogout'), {
                action: (
                    <>
                        <Link
                            component="button"
                            onClick={() => openSignInModal()}
                            aria-label={t('auth.login')}
                            rel="noopener noreferrer"
                            style={{
                                // Force font size to stay at default and weight to stay at semibold since we are using a custom style
                                fontSize: theme.typography.fontSizeDefault,
                                fontWeight: theme.typography.fontWeightSemiBold,
                            }}
                        >
                            {t('auth.login')}
                        </Link>
                        <IconButton
                            className={classes.IdleSnackbarButton}
                            onClick={() => closeSnackbar()}
                            aria-label={t('landingPage.closeSnackbar')}
                        >
                            <Close />
                        </IconButton>
                    </>
                ),
                autoHideDuration: null,
            });
            setSessionEnded(false);
        }
    }, [
        enqueueSnackbar,
        t,
        theme.direction,
        openSignInModal,
        closeSnackbar,
        classes.IdleSnackbarButton,
        theme.typography.fontSizeDefault,
        theme.typography.fontWeightSemiBold,
        sessionEnded,
        setSessionEnded,
        path,
        previousPath,
        deploymentStage,
    ]);

    const onMainMenuButtonKeyPress = React.useMemo(
        () => (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === ' ' || e.key === Key.Enter) {
                // Need to delay a little while to get focus to work
                setTimeout(() => {
                    if (closeGlobalNavDrawerButtonRef.current) {
                        closeGlobalNavDrawerButtonRef.current.focus();
                    }
                }, 200);
            }
        },
        [],
    );

    const mainMenuButton: JSX.Element = React.useMemo(
        () => (
            <IconButton
                aria-label={t('general.mainMenu')}
                aria-expanded={globalNavOpen}
                onClick={openGlobalNavDrawer}
                onKeyDown={onMainMenuButtonKeyPress}
                data-testid="open-main-menu"
                ref={openGlobalNavDrawerButtonRef}
            >
                <ThreeBarMenu />
            </IconButton>
        ),
        [openGlobalNavDrawer, onMainMenuButtonKeyPress, globalNavOpen, t],
    );

    const pageContent = (
        <>
            <div
                className={
                    mdUp && globalNavOpen
                        ? classes.globalNavDrawerOpen
                        : classes.skipLink
                }
            >
                <SkipLink
                    targetRef={() =>
                        mainContentRef?.current?.querySelector('h1')
                    }
                >
                    {t('general.skipNavigation')}
                </SkipLink>
            </div>
            <RouteContextProvider>
                <NavigationRegistrationContextProvider>
                    <HomeContent navigationIndex={1} />
                    <ReportingContent navigationIndex={2} />
                    <DeveloperContent navigationIndex={3} />
                    <PrivacyAndSecurityContent navigationIndex={4} />
                    <SideBar
                        logout={logoutAndNavigateToHome}
                        signin={openSignInModal}
                        openGlobalNavDrawerButtonRef={
                            openGlobalNavDrawerButtonRef
                        }
                        closeGlobalNavDrawerButtonRef={
                            closeGlobalNavDrawerButtonRef
                        }
                    />
                    {/* The secondary navigation needs to be rendered, even when not being used, in order 
                    for css transitions to work correctly */}
                    <SecondaryNavigation />
                </NavigationRegistrationContextProvider>

                <PageExpandProvider>
                    <div
                        className={classnames(classes.mainContainer, {
                            [classes.globalNavDrawerOpen]:
                                mdUp && globalNavOpen,
                        })}
                    >
                        <PageHeader
                            mainMenuButton={mainMenuButton}
                            showMainMenuButton={!globalNavOpen}
                            logout={logoutAndNavigateToHome}
                        />

                        <div
                            className={classnames(classes.secondaryContainer, {
                                [classes.secondaryNavDrawerOpen]:
                                    mdUp && secondaryNavOpen,
                            })}
                        >
                            <main
                                ref={mainContentRef}
                                className={classes.content}
                            >
                                <Suspense
                                    fallback={
                                        <div
                                            className={classes.loadingIndicator}
                                        >
                                            <LoadingIndicator
                                                aria-label={t(
                                                    'general.secondaryNavigationLoadingAriaLabel',
                                                )}
                                                id="loadingMainPageSecondaryNavigation"
                                            />
                                        </div>
                                    }
                                >
                                    <RouteContent />
                                </Suspense>
                            </main>
                            <Footer />
                        </div>
                    </div>
                </PageExpandProvider>
                <SignInModal />
            </RouteContextProvider>
            <SilentFeatureFlagIndicator />
        </>
    );

    React.useEffect(() => {
        // at this point sub-components should have set their loading flags if needed
        setLoadingFlag('main.init', false);
    });
    return pageContent;
};
