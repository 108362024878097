import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Table } from '@bb-ui/react-library/dist/components/Table';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import {
    OutlineButton,
    DefaultButton,
    PrimaryButton,
} from '@bb-ui/react-library/dist/components/Button';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { OffScreenAnnouncement } from '@bb-ui/react-library';
import { useApi } from '../../../../hooks/useApi';
import { Message } from '../../../Message/Message';
import { SkeletonTable } from '../../../Skeleton/SkeletonTable';
import { useUser } from '../../../../hooks/useUser';
import { useAppConfigContext } from '../../../../contexts/AppConfigProvider';
import { useAppLoadingContext } from '../../../../contexts/AppLoadingContext';
import { useStyles } from './SnowflakeServiceUsers.styles';

interface ColumnDescriptions {
    [key: string]: {
        showLabel: boolean;
        getValue: (serviceAccount: ServiceAccountResponse) => any;
    };
}

interface ServiceAccountResponse {
    username: string;
    disabled: boolean;
    hasPassword: boolean;
    daysUntilExpiration: number;
    expirationDate: string;
}

interface ResetPasswordResponse {
    url: string;
}

enum DialogState {
    closed,
    changePasswordConfirmation,
    changeEmail,
}

export const SnowflakeServiceUsers: React.FC = () => {
    const user = useUser();
    const { t } = useTranslation();
    const { api } = useAppConfigContext();
    const { setLoadingFlag } = useAppLoadingContext();
    const classes = useStyles();

    const {
        data: serviceAccountList,
        loading: serviceAccountListLoading,
        error: serviceAccountListError,
    } = useApi<ServiceAccountResponse[]>({
        url: `${
            api!.serviceAccountManagement
        }/v1/data/tenants/${user?.tenantIdentifier}/serviceAccounts`,
        useAuth: true,
        method: 'GET',
    });

    const {
        data: resetPasswordLink,
        loading: resetPasswordLinkLoading,
        error: resetPasswordLinkError,
        execute: executeResetPasswordRequest,
    } = useApi<ResetPasswordResponse>({
        useAuth: true,
        executeType: 'manual',
        method: 'PATCH',
    });
    const [modalState, setModalState] = React.useState<DialogState>(
        DialogState.closed,
    );

    const [selectedServiceAccount, setSelectedServiceAccount] =
        React.useState<ServiceAccountResponse>();

    const tableColumns: ColumnDescriptions = {
        accountName: {
            showLabel: true,
            getValue: serviceAccount => serviceAccount.username,
        },
        disabled: {
            showLabel: true,
            getValue: serviceAccount =>
                t(
                    `settings.userManagement.userState.${
                        serviceAccount.disabled ? 'disabled' : 'enabled'
                    }`,
                ),
        },
        hasPassword: {
            showLabel: true,
            getValue: serviceAccount =>
                t(`general.${serviceAccount.hasPassword ? 'yes' : 'no'}`),
        },

        daysUntilExpiration: {
            showLabel: true,
            getValue: serviceAccount => serviceAccount.daysUntilExpiration,
        },

        expirationDate: {
            showLabel: true,
            getValue: serviceAccount => serviceAccount.expirationDate,
        },
        actions: {
            showLabel: true,
            getValue: function ResetPasswordButton(serviceAccount) {
                return (
                    <OutlineButton
                        data-testid={`${serviceAccount.username}-resetPasswordButton`}
                        onClick={() => {
                            setSelectedServiceAccount(serviceAccount);
                            setModalState(
                                DialogState.changePasswordConfirmation,
                            );
                        }}
                        aria-label={t(
                            'settings.userManagement.tableButtons.changePasswordFor',
                            { username: serviceAccount.username },
                        )}
                    >
                        {t(
                            'settings.userManagement.tableButtons.changePassword',
                        )}
                    </OutlineButton>
                );
            },
        },
    };

    React.useEffect(() => {
        if (resetPasswordLinkLoading) {
            setLoadingFlag('service-users.link-gen', resetPasswordLinkLoading);
        }
    }, [resetPasswordLinkLoading, setLoadingFlag]);

    React.useEffect(() => {
        if (resetPasswordLinkError) {
            setLoadingFlag('service-users.link-gen', false);
        }
    }, [resetPasswordLinkError, setLoadingFlag]);

    React.useEffect(() => {
        if (resetPasswordLink) {
            window.location.href = resetPasswordLink.url;
        }
    }, [resetPasswordLink]);

    const ResetPasswordDialogMemoized = React.memo(() => (
        <Dialog
            open={modalState !== DialogState.closed}
            maxWidth="md"
            onClose={() => setModalState(DialogState.closed)}
            aria-labelledby="reset-password-title"
        >
            <DialogTitle
                id="reset-password-title"
                onClose={() => setModalState(DialogState.closed)}
            >
                {modalState === DialogState.changePasswordConfirmation && (
                    <>
                        {/* prettier-ignore */}
                        <Trans i18nKey="settings.userManagement.resetPasswordModal.title">
                        You’re leaving <span lang="en">Anthology Illuminate</span>
                    </Trans>
                    </>
                )}
            </DialogTitle>
            <DialogContent>
                {modalState === DialogState.changePasswordConfirmation && (
                    <>
                        {/* prettier-ignore */}
                        <Trans i18nKey="settings.userManagement.resetPasswordModal.description">
                            You’ll be redirected to <span lang="en">Snowflake</span> to complete your password change. Do you want to continue?
                        </Trans>
                        {resetPasswordLinkError && (
                            <Message
                                variant="error"
                                message={t('general.defaultPageError')}
                            />
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {modalState === DialogState.changePasswordConfirmation && (
                    <>
                        <DefaultButton
                            data-testid="resetPassword-cancel"
                            onClick={() => setModalState(DialogState.closed)}
                        >
                            {t('general.cancel')}
                        </DefaultButton>
                        <PrimaryButton
                            data-testid="resetPassword-confirm"
                            onClick={() => {
                                executeResetPasswordRequest({
                                    url: `${
                                        api!.serviceAccountManagement
                                    }/v1/data/tenants/${user?.tenantIdentifier}/serviceAccounts/${selectedServiceAccount?.username}/resetPassword`,
                                });
                            }}
                        >
                            {t('general.yes')}
                        </PrimaryButton>
                    </>
                )}
            </DialogActions>
        </Dialog>
    ));
    ResetPasswordDialogMemoized.displayName = 'ResetPasswordDialog';

    return (
        <>
            <ResetPasswordDialogMemoized />
            {serviceAccountList && (
                <div className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHeaderRow}>
                                {Object.entries(tableColumns).map(
                                    ([key, column]) => (
                                        <TableCell
                                            key={key}
                                            role="columnheader"
                                            className={classes.tableHeader}
                                            aria-label={
                                                !column.showLabel
                                                    ? t(
                                                          `settings.userManagement.tableHeaders.${key}`,
                                                      )
                                                    : undefined
                                            }
                                        >
                                            {column.showLabel &&
                                                t(
                                                    `settings.userManagement.tableHeaders.${key}`,
                                                )}
                                        </TableCell>
                                    ),
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody data-testid="service-account-list">
                            {serviceAccountList.map(serviceAccount => (
                                <TableRow key={serviceAccount.username}>
                                    {Object.entries(tableColumns).map(
                                        ([key, column]) => (
                                            <TableCell
                                                key={`${serviceAccount.username}-${key}`}
                                                data-testid={`${serviceAccount.username}-${key}`}
                                            >
                                                {column.getValue(
                                                    serviceAccount,
                                                )}
                                            </TableCell>
                                        ),
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}

            {serviceAccountListLoading && (
                <>
                    <OffScreenAnnouncement
                        type="polite"
                        message={t(
                            'settings.userManagement.tableHeaders.loading',
                        )}
                        changeTrigger={1}
                    />
                    <SkeletonTable rowNum={6} />
                </>
            )}

            {serviceAccountListError && (
                <Message
                    variant="error"
                    message={t('reports.error.dataDidNotLoad')}
                />
            )}
        </>
    );
};
