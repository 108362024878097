import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { CustomCardPrimary } from '../CustomCards/CustomCardPrimary';
import { OutlineLink } from '../StyledLinks/OutlineLink';
import { useStyles } from './HomePage.styles';
import { useAccessControl } from '../../hooks/useAccessControl';

export const HomePageNotEnhanced = () => {
    const { t } = useTranslation();
    const classes: any = useStyles();
    const access = useAccessControl();

    return (
        <>
            {access.reporting.ok && (
                <CustomCardPrimary
                    id="reporting"
                    className={classes.primaryCard}
                    title={t('homePage.cards.reportingNotEnhanced.title')}
                    imgSrc="/images/HomePage/reportingIllustration.svg"
                    imagePosition="left"
                    svgContainsBitmap={false}
                    cardActions={
                        <div className={classes.link}>
                            <OutlineLink
                                to="/reporting"
                                data-testid="go-to-reporting-card-button"
                            >
                                {t('homePage.cards.buttons.goToReporting')}
                            </OutlineLink>
                        </div>
                    }
                >
                    <Trans i18nKey="homePage.cards.reportingNotEnhanced.text">
                        <span lang="en">Illuminate</span> Reporting provides you
                        a clear understanding of the experience at your
                        institution.
                    </Trans>
                </CustomCardPrimary>
            )}
            {access.developer.ok && (
                <CustomCardPrimary
                    id="developer"
                    className={classes.primaryCard}
                    title={t('homePage.cards.developerNotEnhanced.title')}
                    imgSrc="/images/HomePage/developerIllustration.svg"
                    imagePosition="left"
                    svgContainsBitmap={false}
                    cardActions={
                        <div className={classes.link}>
                            <OutlineLink
                                to="/developer"
                                data-testid="go-to-developer-card-button"
                            >
                                {t('homePage.cards.buttons.goToDeveloper')}
                            </OutlineLink>
                        </div>
                    }
                >
                    { /* prettier-ignore */}
                    <Trans i18nKey="homePage.cards.developerNotEnhanced.text">
                            <span lang="en">Illuminate</span> Developer gives you access
                            to your institution’s activity, usage, and assessment data
                            from across multiple <span lang="en">Anthology SaaS</span> solutions.
                        </Trans>
                </CustomCardPrimary>
            )}
        </>
    );
};
