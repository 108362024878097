import {
    createStyles,
    makeStyles,
    rgba,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { ListNavVariant } from './ListNav.types';
import { illuminateColors } from '../../utilities/sharedStyles';

// Use to override the classes for ListItem. See: https://material-ui.com/api/list-item/#css
const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            '&:hover': {
                textDecoration: 'none',
            },
        },
        gutters: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        button: (props: {
            listNavVariant: ListNavVariant;
            forceEnableHover?: boolean;
        }) => {
            switch (props.listNavVariant) {
                case 'global':
                    return {
                        // This color is specifically for use in a dark theme. There currently is not an
                        // appropriate palette value in the theme that can be used here.
                        color: '#E5E5E5',
                        ...(props.forceEnableHover
                            ? { pointerEvents: 'auto !important' as any }
                            : {}),
                        '&:hover, &:focus': {
                            textDecoration: 'none',
                            backgroundColor: theme.palette.background.b3,
                            '& .primary-text': {
                                textDecoration: 'none',
                            },
                        },
                        '&.active': {
                            backgroundColor: rgba(
                                illuminateColors.brandYellow,
                                0.6,
                            ),
                            borderLeft: `4px solid ${illuminateColors.brandYellow}`,
                        },
                        '&.active > div': {
                            color: theme.palette.text.primary,
                            position: 'relative',
                            left: '-4px',
                        },
                    };
                case 'secondary':
                    return {
                        // This color is specifically for use in a dark theme. There currently is not an
                        // appropriate palette value in the theme that can be used here.
                        color: '#E5E5E5',
                        ...(props.forceEnableHover
                            ? { pointerEvents: 'visible !important' as any }
                            : {}),
                        '&:hover, &:focus': {
                            textDecoration: 'none',
                            backgroundColor: theme.palette.background.b3,
                            color: theme.palette.text.primary,
                            '& .primary-text': {
                                textDecoration: 'none',
                            },
                        },
                        '& span': {
                            fontWeight: theme.typography.fontWeightRegular,
                        },
                        '&.active > div': {
                            color: theme.palette.text.primary,
                        },
                        '&.active span': {
                            fontWeight: theme.typography.fontWeightBold,
                        },
                    };
            }
        },
    });

const options = {
    name: 'ListNavItem-',
};

export const useStyles = makeStyles(styles, options);
