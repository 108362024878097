import React, { Suspense, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { AppLoadingContext } from '../../contexts/AppLoadingContext';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { GeneralErrorPage } from '../ErrorPages/GeneralErrorPage/GeneralErrorPage';
import { localStorageUASTenantKey } from '../../utilities/constants';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { useAuthProviderCache } from '../../auth/auth0/useAuthProviderCache';

export const AppLoadingAndErrorOverlay: React.FunctionComponent = ({
    children,
}) => {
    const { t } = useTranslation(undefined, { useSuspense: false });
    const [loading, setLoading] = React.useState(true);
    const [loadingOverlayShown, setLoadingOverlayShown] = React.useState(true);
    const { setCache } = useAuthProviderCache();

    const [loadingUnsuccesful, setLoadingUnsuccesful] = React.useState(false);

    const { deploymentStage } = useAppConfigContext();

    const [, setUasTenant] = useLocalStorage<string>(
        localStorageUASTenantKey,
        '',
        {
            raw: true,
        },
    );
    const loadingFlags = React.useRef<{ [flagName: string]: boolean }>({});

    const setLoadingFlag = React.useCallback(
        (flagName: string, loading: boolean) => {
            loadingFlags.current[flagName] = loading;

            if (Object.values(loadingFlags.current).includes(true)) {
                setLoading(true);
            } else {
                setLoading(false);
            }
        },
        [],
    );

    useEffect(() => {
        const loadingTimer = setTimeout(() => {
            if (loading) {
                setLoading(false);
                setLoadingUnsuccesful(true);
            }
        }, 120000);
        return () => clearTimeout(loadingTimer);
    }, [loading, loadingUnsuccesful]);

    useEffect(() => {
        // remove loading indicator 1 render cycle later
        // to allow the rest of the app to finish rendering
        setLoadingOverlayShown(loading);
    }, [loading]);

    const loadingNode = (
        <LoadingIndicator
            aria-label={t('general.applicationLoadingAriaLabel')}
            variant="fill-window"
            id="appLoadingAndErrorOverlay"
            hideRenderingElements
        />
    );

    const onError = useCallback(() => {
        if (deploymentStage === 'local') return;
        // remove potentially blocking uasTenantUriComponent local storage value
        // clearing the value storage affects state, so it needs to be postponed after the render phase.
        setTimeout(() => {
            setUasTenant('');
            setCache(undefined);
        }, 0);
        return <GeneralErrorPage />;
    }, [deploymentStage, setUasTenant, setCache]);

    return (
        <ErrorBoundary onError={onError}>
            <AppLoadingContext.Provider
                value={{
                    loading,
                    setLoadingFlag,
                }}
            >
                <Suspense fallback={loadingNode}>
                    {loadingOverlayShown && loadingNode}
                    {children}
                    {
                        loadingUnsuccesful && (
                            <GeneralErrorPage />
                        ) /* It is here for
                    the second time because ErrorBoundary doesn't catch
                    compile-time errors */
                    }
                </Suspense>
            </AppLoadingContext.Provider>
        </ErrorBoundary>
    );
};
