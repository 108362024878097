import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { MainPage } from '../components/MainPage/MainPage';
import { IBeamer } from '../types/beamer.types';
import { AppNavigationProvider } from '../contexts/AppNavigationContext';
import { useUser } from '../hooks/useUser';

import { FeatureFlagServiceContext } from '../contexts/FeatureFlagServiceContext';
import { TenantSettingsProvider } from '../contexts/TenantSettingsContext';
import { TenantStatusProvider } from '../contexts/TenantStatusContext/TenantStatusContext';
import { useAppLoadingContext } from '../contexts/AppLoadingContext';
import { AuthHandlingWrapper } from './auth0/AuthHandlingWrapper';
import { useAuth0Context } from './auth0/Auth0Context';

export const AppWithAuthenticationInner: React.FC = () => {
    const { i18n } = useTranslation();

    const { loading: authLoading } = useAuth0Context();

    const { setLoadingFlag } = useAppLoadingContext();

    React.useEffect(() => {
        setLoadingFlag('auth.init', authLoading);
    }, [authLoading, setLoadingFlag]);

    const user = useUser();

    const beamer: IBeamer = (window as any).Beamer;

    React.useEffect(() => {
        if (!authLoading && user) {
            beamer.update({
                user_firstname: user.firstName || user.userId,
                user_lastname: user.lastName,
                user_email: user.personIdentifier,
                // sets the badge to be inline with the What's New text
                right: -5,
                top: 7,
                language: i18n.language.substring(0, 2),
            });
            beamer.init();
        }
    }, [user, authLoading, i18n, beamer]);

    if (authLoading) {
        return null;
    }

    return (
        <FeatureFlagServiceContext>
            <TenantSettingsProvider>
                <TenantStatusProvider>
                    <AppNavigationProvider>
                        <MainPage />
                    </AppNavigationProvider>
                </TenantStatusProvider>
            </TenantSettingsProvider>
        </FeatureFlagServiceContext>
    );
};

export const AppWithAuthentication: React.FunctionComponent = () => (
    <AuthHandlingWrapper>
        <AppWithAuthenticationInner />
    </AuthHandlingWrapper>
);
