import {
    Box,
    Combobox,
    ComboboxGroup,
    ComboboxOption,
    ComboboxProps,
    OutlineButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Skeleton,
    Typography,
    IconButton,
    MenuItemProps,
} from '@bb-ui/react-library';
import React, { useEffect, useMemo, useState } from 'react';
import { Trash, Information } from '@bb-ui/icons/dist/small';
import { Check } from '@bb-ui/icons/dist/medium';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { useAuth0Context } from '../../../../auth/auth0/Auth0Context';
import { useAppConfigContext } from '../../../../contexts/AppConfigProvider';
import { getTenantId } from '../../../../utilities/utilities';
import { useStyles } from './UserRoles.styles';
import { useApi } from '../../../../hooks/useApi';
import { LoadingIndicator } from '../../../LoadingIndicator/LoadingIndicator';
import { EditUserError } from '../EditUserError/EditUserError';
import { useHelpLinks } from '../../../../hooks/useHelpLinks';

interface UserRolesProps {
    userId: string;
}

export interface IUserGroupsAPI {
    groups: string[];
    nextToken?: string;
}

export interface IDeletedGroupAPI {
    groupId: string;
}

interface IGroupOptions {
    groupName: string; // this is the group name as it exists on the backend
    friendlyName: string;
    description: string;
}

interface CustomOption extends ComboboxOption {
    value: string; // this will need to be the friendly name, as it is displayed once selected.
    label: string;
    subtitle?: string;
}

const CustomCombobox = (
    props: ComboboxProps<CustomOption, ComboboxGroup<CustomOption>>,
) => <Combobox {...props} />;

export const UserRoles: React.FunctionComponent<UserRolesProps> = ({
    userId,
}) => {
    const { uas, api, includeIntegrationTestGroup } = useAppConfigContext();
    const { user } = useAuth0Context();
    const tenantId = getTenantId(user, uas);
    const { t } = useTranslation();
    const classes = useStyles();
    const getHelpLink = useHelpLinks();

    const [initialized, setInitialized] = useState<boolean>(false);

    // State used to track the groups assigned on the backend
    const [assignedGroups, setAssignedGroups] = useState<string[]>([]);

    // States used to track loading and errors
    const [updating, setUpdating] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    // This is a temporary state that is updated when the user adds/removes values in the Combobox
    const [tempAssignedGroups, setTempAssignedGroups] = useState<string[]>([]);

    const url = useMemo(
        () =>
            encodeURI(
                `${
                    api!.userManagement
                }/v1/tenants/${tenantId}/users/${userId}/groups`,
            ),
        [api, tenantId, userId],
    );

    const testOnlyGroups = useMemo(
        () => (includeIntegrationTestGroup ? ['IntegrationUITestGroup'] : []),
        [includeIntegrationTestGroup],
    );

    // Hardcoded group options for now. The 'GroupName' is the group name as it exists on the backend.
    const groupNames = useMemo(() => {
        const baseGroups = [
            'ReportViewer.LEADING',
            'ReportViewer.LEARNING',
            'ReportViewer.TEACHING',
            'BbDataReportViewer',
            'BbDataRestrictedViewer',
            'BbDataUnrestricted',
        ];

        return [...baseGroups, ...testOnlyGroups];
    }, [testOnlyGroups]);

    // TODO: figure out how to get the roleOptionsFriendlyNames translatable in the Combobox once
    // they have been selected
    const groupOptions: IGroupOptions[] = useMemo(
        () =>
            groupNames.map(groupName => {
                if (testOnlyGroups.includes(groupName)) {
                    return {
                        groupName,
                        friendlyName: groupName,
                        description: 'A group used for integration testing',
                    };
                }
                const baseKey = camelCase(groupName.replace(/\./g, ' '));
                const friendlyNameKey = `settings.editUser.roles.${baseKey}.displayName`;
                const descriptionKey = `settings.editUser.roles.${baseKey}.description`;

                return {
                    groupName,
                    friendlyName: t(friendlyNameKey),
                    description: t(descriptionKey),
                };
            }),
        [groupNames, testOnlyGroups, t],
    );

    // useApi calls for getting the initial groups, updating groups, and deleting groups
    // TODO: this currently doesn't account for NextToken pagination, but given the small number of
    // fix returns for this endpoint, it's not a concern at the moment. A hook for that will be needed
    // for the Node endpoint, however.
    const {
        data: initialGroups,
        loading: initialGroupsLoading,
        error: initialGroupsError,
        execute: getInitialGroups,
    } = useApi<IUserGroupsAPI>({
        url,
        method: 'GET',
        useAuth: true,
        executeType: 'manual',
    });

    const {
        data: updatedGroups,
        loading: updatingGroupsLoading,
        error: updatingGroupsError,
        execute: putGroups,
    } = useApi<IUserGroupsAPI>({
        url,
        method: 'PUT',
        executeType: 'manual',
        useAuth: true,
    });

    const {
        data: deletedGroup,
        loading: deleteGroupLoading,
        error: deleteGroupError,
        execute: deleteGroup,
    } = useApi<IDeletedGroupAPI>({
        method: 'DELETE',
        executeType: 'manual',
        useAuth: true,
    });

    // Call the initial groups endpoint on mount and no other time
    useEffect(() => {
        if (!initialized) {
            getInitialGroups();
            setInitialized(true);
        }
    }, [getInitialGroups, initialized]);

    useEffect(() => {
        if (initialGroups) {
            const { groups } = initialGroups;

            if (groups) {
                // fitler the groups to only include the ones that are in the groupOptions
                const filteredGroups = groups.filter(group =>
                    groupOptions.find(
                        groupOption => groupOption.groupName === group,
                    ),
                );
                setAssignedGroups(filteredGroups);
            }
        }
    }, [groupOptions, initialGroups]);

    useEffect(() => {
        if (initialGroupsError) {
            setError('Error: no user information found for this user.');
        }
    }, [t, initialGroupsError]);

    useEffect(() => {
        if (updatingGroupsError || deleteGroupError) {
            setError('Error: unable to update user roles.');
        }
    }, [t, updatingGroupsError, deleteGroupError]);

    useEffect(() => {
        setUpdating(updatingGroupsLoading || deleteGroupLoading);
    }, [updatingGroupsLoading, deleteGroupLoading]);

    useEffect(() => {
        if (updatedGroups) {
            const addedGroups = updatedGroups.groups;
            setAssignedGroups(prevGroups => [...prevGroups, ...addedGroups]);
        }
    }, [updatedGroups]);

    useEffect(() => {
        if (deletedGroup) {
            const removedGroup = deletedGroup.groupId;
            setAssignedGroups(prevGroups =>
                prevGroups.filter(group => group !== removedGroup),
            );
        }
    }, [deletedGroup]);

    useEffect(() => {
        setTempAssignedGroups([]);
    }, [assignedGroups]);

    const getGroupNameFromFriendlyName = (
        friendlyName: string,
        options: IGroupOptions[],
    ): string | undefined => {
        const mapping = options.find(
            group => group.friendlyName === friendlyName,
        );
        return mapping ? mapping.groupName : undefined;
    };

    const addGroups = (
        friendlyGroupNames: string[],
        options: IGroupOptions[],
    ) => {
        const groupNames = friendlyGroupNames
            .map(
                friendlyName =>
                    getGroupNameFromFriendlyName(friendlyName, options) || '',
            )
            .filter(group => group !== '');

        // remove the groups that are already assigned
        const filteredGroups = groupNames.filter(
            group => !assignedGroups.includes(group),
        );
        putGroups({
            data: { groups: filteredGroups },
        });
    };

    const removeGroup = (groupId: string) => {
        const deleteUrl = `${url}/${groupId}`;
        deleteGroup({
            url: deleteUrl,
        });
    };

    const convertToHyphenatedString = (input: string): string =>
        input
            .replace(/\./g, '-') // Replace dots with hyphens
            .replace(/\s/g, '-') // Replace spaces with hyphens
            .toLowerCase();

    const getCheckMark = (id: string, groups: string[]) => {
        const check = groups.find(group => group === id);
        if (check) {
            return (
                <Check
                    data-testid={`check-mark-${convertToHyphenatedString(id)}`}
                />
            );
        }
        return null;
    };

    // These provided the text strings for the Combobox
    const comboBoxTextValues = {
        announceOptionSelected: (option: any) =>
            t('settings.editUser.comboBox.optionSelected', {
                option: option.label,
            }),
        announceOptionDeselected: (option: any) =>
            t('settings.editUser.comboBox.optionDeselected', {
                option: option.label,
            }),
        announceValueCleared: t('settings.editUser.comboBox.valueCleared'),
        announceSearchResults: (count: any) => {
            switch (count) {
                case 0:
                    return t('settings.editUser.comboBox.noResultsFound');
                default:
                    return t('settings.editUser.comboBox.resultsFound', {
                        count,
                    });
            }
        },
        noResults: () => t('settings.editUser.comboBox.noResultsFound'),
        clearButtonLabel: t('settings.editUser.comboBox.clearButton'),
        searchLabel: t('settings.editUser.comboBox.searchLabel'),
    };

    const comboBoxGroupOptions = groupOptions.map(group => ({
        value: group.friendlyName, // Keep the value as the friendly name for the Combobox, so the user never sees the real one.
        label: group.friendlyName,
        subtitle: group.description,
    }));

    const selected = tempAssignedGroups.map(group => ({
        value: group,
        label: group,
    }));

    const getFriendlyNameFromGroupName = (
        groupName: string,
        options: IGroupOptions[],
    ): string | undefined => {
        const mapping = options.find(group => group.groupName === groupName);
        return mapping ? mapping.friendlyName : undefined;
    };

    const renderSkeleton = () => (
        <Box
            data-testid="user-roles-skeleton"
            className={classes.skeletonContainer}
        >
            <Skeleton className={classes.skeletonSmallBar} animation="wave" />
            <Skeleton
                className={classes.skeletonBigBar}
                height={50}
                animation="wave"
            />
            <Skeleton
                className={classes.skeletonBigBar}
                height={50}
                animation="wave"
            />
            <Skeleton className={classes.skeletonBigBar} animation="wave" />
            <Skeleton className={classes.skeletonBigBar} animation="wave" />
            <Skeleton className={classes.skeletonBigBar} animation="wave" />
        </Box>
    );

    const renderError = () => <EditUserError error={error ?? ''} />;

    const renderUpdater = () => (
        <Box data-testid="user-roles-updating" className={classes.updatingInfo}>
            <LoadingIndicator
                id="updating-roles-progress"
                label={t('settings.editUser.roles.updating.updatingRoles')}
                size="76"
            />
        </Box>
    );

    const onComboBoxRowKeyDown = (event: any, id: string) => {
        if (event.key === 'Enter') {
            if (tempAssignedGroups.includes(id)) {
                setTempAssignedGroups(
                    tempAssignedGroups.filter(group => group !== id),
                );
            } else {
                setTempAssignedGroups(prevTempAssignedGroup => [
                    ...prevTempAssignedGroup,
                    id,
                ]);
            }
        }
    };

    const renderComboBoxRows = (
        menuItemProps: React.PropsWithChildren<MenuItemProps<CustomOption>>,
    ) => (
        <div
            tabIndex={0}
            data-testid={`role-menu-container-${convertToHyphenatedString(
                menuItemProps.option.value,
            )}`}
            className={classes.userRolesMenuContainer}
            onKeyDown={event =>
                onComboBoxRowKeyDown(event, menuItemProps.option.value)
            }
        >
            <MenuItem
                data-testid={`role-menu-item-${convertToHyphenatedString(
                    menuItemProps.option.value,
                )}`}
                className={classes.userRolesMenuItem}
                {...menuItemProps}
            >
                <div
                    data-testid={`check-box-${convertToHyphenatedString(
                        menuItemProps.option.value,
                    )}`}
                    className={classes.checkBoxContainer}
                >
                    {getCheckMark(
                        menuItemProps.option.value,
                        tempAssignedGroups,
                    )}
                </div>
                <Box
                    data-testid={`role-description-container-${convertToHyphenatedString(
                        menuItemProps.option.value,
                    )}`}
                    component="span"
                    mr="auto"
                    className={classes.userRolesEntry}
                >
                    <div
                        data-testid={`role-label-${convertToHyphenatedString(
                            menuItemProps.option.value,
                        )}`}
                        className={classes.userRolesLabel}
                    >
                        {menuItemProps.option.label}
                    </div>
                    <div
                        data-testid={`role-description-${convertToHyphenatedString(
                            menuItemProps.option.value,
                        )}`}
                        className={classes.userRoleDescription}
                    >
                        {menuItemProps.option.subtitle}
                    </div>
                </Box>
            </MenuItem>
        </div>
    );

    return (
        <div
            data-testid="user-roles-container"
            className={classes.userRolesContainer}
        >
            {!error && !initialGroupsLoading && updating && renderUpdater()}
            <Box
                data-testid="user-roles-combo-box-container"
                className={`${classes.comboBoxContainer} ${
                    updating ? classes.updating : ''
                }`}
            >
                <Typography variant="h2" className={classes.infoTextMargin}>
                    {t('settings.editUser.roles.title')}
                </Typography>
                <Typography className={classes.infoTextMargin}>
                    {t('settings.editUser.roles.description')}
                </Typography>
                {error && renderError()}
                {!error && initialGroupsLoading && renderSkeleton()}
                {!error && !initialGroupsLoading && (
                    <>
                        <Box className={classes.box}>
                            <CustomCombobox
                                id="user-roles-combo-box"
                                data-testid="user-roles-combo-box"
                                closeOnSelect={false}
                                isMulti
                                strings={comboBoxTextValues}
                                label={t(
                                    'settings.editUser.roles.comboBox.mainLabel',
                                )}
                                placeholder={t(
                                    'settings.editUser.roles.comboBox.multiSelectPlaceHolder',
                                )}
                                isClearable={true}
                                options={comboBoxGroupOptions}
                                defaultValue={selected}
                                className={classes.userRolesComboBox}
                                onChange={value => {
                                    const updatedSelection = value.map(
                                        option => option.value,
                                    );
                                    setTempAssignedGroups(updatedSelection);
                                }}
                                components={{
                                    MenuItem: props =>
                                        renderComboBoxRows(props),
                                }}
                            />
                            <OutlineButton
                                data-testid="add-role-button"
                                disabled={!tempAssignedGroups.length}
                                onClick={() => {
                                    addGroups(tempAssignedGroups, groupOptions);
                                }}
                                className={classes.addRoleButton}
                            >
                                {t('settings.editUser.roles.addRoleButton')}
                            </OutlineButton>
                        </Box>
                        <Typography data-testid="role-information">
                            <Information className={classes.infoIcon} />
                            {/* TODO: Add message about needing to wait awhile for the user to have updated roles after submission */}
                            {t('settings.editUser.roles.addRoleInformation')}{' '}
                            {/* TODO: get the link for this. */}
                            <Link href={getHelpLink('roles')}>
                                {t('settings.editUser.roles.addRoleSeeHow')}
                            </Link>
                        </Typography>
                        <List data-testid="assigned-roles-list">
                            {assignedGroups.map(group => (
                                <ListItem
                                    key={group}
                                    data-testid={`assigned-role-list-item-${convertToHyphenatedString(
                                        group,
                                    )}`}
                                    className={classes.assignedRoleRow}
                                >
                                    <ListItemText
                                        data-testid={`assigned-role-name-${convertToHyphenatedString(
                                            group,
                                        )}`}
                                        className={classes.assignedRoleRowText}
                                    >
                                        {getFriendlyNameFromGroupName(
                                            group,
                                            groupOptions,
                                        )}
                                    </ListItemText>
                                    <IconButton
                                        className={classes.removeRoleButton}
                                        onClick={() => {
                                            removeGroup(group);
                                        }}
                                        data-testid={`remove-role-button-${convertToHyphenatedString(
                                            group,
                                        )}`}
                                    >
                                        <Trash />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
            </Box>
        </div>
    );
};
