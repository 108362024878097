import React from 'react';
import { Redirect } from 'react-router-dom';
import { Visualization } from '@bb-ui/icons/dist/medium';
import { Course, Settings } from '@bb-ui/icons/dist/small';
import { useTranslation } from 'react-i18next';
import { NavigationRoutedLink } from '../NavigationRouting/NavigationRoutedLink';
import { NavigationRouted } from '../NavigationRouting/NavigationRouted';
import { DeveloperPage } from '../DeveloperPage/DeveloperPage';
import { Settings as SettingsPage } from '../Settings/Settings';
import { EditUserPage } from '../Settings/EditUserPage/EditUserPage';
import { useApi } from '../../hooks/useApi';
import { ISubmodel } from '../../types/appUITypes';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { IERDData } from '../InteractiveERD/InteractiveERD.types';
import { IDataDictionaryEntry } from '../DataDictionaryEntries/DataDictionaryEntries.types';
import { useAccessControl } from '../../hooks/useAccessControl';

const DataDictionary = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'datadictionary' */ '../DataDictionary/DataDictionary'
        ),
);

export const DeveloperContent: React.FC<{
    navigationIndex: number;
}> = props => {
    const { t } = useTranslation();
    const access = useAccessControl();
    const { api } = useAppConfigContext();

    const {
        data: submodels,
        loading: submodelsLoading,
        error: submodelsError,
    } = useApi<ISubmodel[]>({
        url: `${api!.dataDictionary}/v1/data/dictionary/submodels`,
        useAuth: false,
        transformResponse: (data: ISubmodel[]) =>
            data.sort((a, b) => a.schemaId.localeCompare(b.schemaId)),
    });

    const {
        data: ERDData,
        loading: ERDDataLoading,
        error: ERDDataError,
    } = useApi<IERDData>({
        url: `${api!.dataDictionary}/v1/data/dictionary/erd`,
        useAuth: false,
    });

    const {
        data: dictionaryEntries,
        loading: dictionaryEntriesLoading,
        error: dictionaryEntriesError,
    } = useApi<IDataDictionaryEntry[]>({
        url: `${api!.dataDictionary}/v1/data/dictionary/definitions`,
        useAuth: false,
        transformResponse: (data: IDataDictionaryEntry[]) =>
            data.filter((entry: IDataDictionaryEntry) => {
                if (!entry)
                    console.warn(
                        'A `null` entry was found in the Data Dictionary. Null entries will not be displayed.',
                    );
                return !!entry;
            }),
    });

    return (
        <NavigationRoutedLink
            key="developer"
            path="developer"
            label={t('developer.title')}
            icon={<Visualization />}
            index={props.navigationIndex}
            access={access.developer}
        >
            <NavigationRouted>
                <DeveloperPage />
            </NavigationRouted>

            <NavigationRoutedLink
                key="settings"
                path="settings"
                label={t('settings.label')}
                icon={<Settings />}
                routeWithoutContext
                index={1}
                access={access['developer/settings']}
            >
                <NavigationRouted>
                    <SettingsPage />
                </NavigationRouted>

                {access['developer/settings/user-management'].ok && (
                    <NavigationRouted exact={false} path="user/:id">
                        <EditUserPage />
                    </NavigationRouted>
                )}
            </NavigationRoutedLink>

            <NavigationRoutedLink
                key="dictionary"
                path="dictionary"
                label={t('dataDictionary.title')}
                icon={<Course />}
                routeWithoutContext
                index={2}
                exact={false}
                access={access['developer/data-dictionary']}
            >
                <NavigationRoutedLink
                    path="entries"
                    index={1}
                    label={t('dataDictionary.entries')}
                    exact={false}
                    access={access['developer/data-dictionary']}
                >
                    <NavigationRouted exact={false}>
                        <DataDictionary
                            variant="entries"
                            ERDData={ERDData}
                            dictionaryEntries={dictionaryEntries}
                            submodels={submodels}
                            loading={
                                ERDDataLoading ||
                                submodelsLoading ||
                                dictionaryEntriesLoading
                            }
                            error={
                                ERDDataError ||
                                submodelsError ||
                                dictionaryEntriesError
                            }
                        />
                    </NavigationRouted>
                </NavigationRoutedLink>

                <NavigationRoutedLink
                    path="entity-relationships"
                    index={2}
                    label={t('dataDictionary.entityRelationships.tabText')}
                    exact={false}
                    access={access['developer/data-dictionary']}
                >
                    <NavigationRouted exact={false}>
                        <DataDictionary
                            variant="erd"
                            ERDData={ERDData}
                            dictionaryEntries={dictionaryEntries}
                            submodels={submodels}
                            loading={
                                ERDDataLoading ||
                                submodelsLoading ||
                                dictionaryEntriesLoading
                            }
                            error={
                                ERDDataError ||
                                submodelsError ||
                                dictionaryEntriesError
                            }
                        />
                    </NavigationRouted>
                </NavigationRoutedLink>

                <NavigationRouted>
                    <Redirect to="dictionary/entries" />
                </NavigationRouted>
            </NavigationRoutedLink>
        </NavigationRoutedLink>
    );
};
