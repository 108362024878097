import { Warning } from '@bb-ui/icons/dist/small/Warning';
import {
    createStyles,
    makeStyles,
} from '@bb-ui/react-library/dist/components/styles';
import Tooltip from '@bb-ui/react-library/dist/components/Tooltip/Tooltip';
import { TooltipProps } from '@bb-ui/react-library/dist/components/Tooltip/Tooltip.types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
        },
        icon: { alignSelf: 'center', marginRight: '10px' },
    }),
);

export const UnavailableTooltip: React.FC<{
    children: React.ReactElement<any, any>;
    placement: TooltipProps['placement'];
    show: boolean;
}> = props => {
    const { t } = useTranslation();
    const styles = useStyles();

    if (props.show) {
        return (
            <Tooltip
                title={
                    <div className={styles.container}>
                        <Warning className={styles.icon} color="primary" />
                        <span>
                            {t('general.availability.unavailableInThisRegion')}
                        </span>
                    </div>
                }
                placement={props.placement}
            >
                {props.children}
            </Tooltip>
        );
    }
    return <>{props.children}</>;
};
