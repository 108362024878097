import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lock } from '@bb-ui/icons/dist/medium';
import { NavigationRoutedLink } from '../NavigationRouting/NavigationRoutedLink';
import { NavigationRouted } from '../NavigationRouting/NavigationRouted';
import { PrivacyAndSecurity } from '../PrivacyAndSecurity/PrivacyAndSecurity';

export const PrivacyAndSecurityContent: React.FC<{
    navigationIndex: number;
}> = props => {
    const { t } = useTranslation();
    return (
        <NavigationRoutedLink
            key="privacy"
            path="privacy"
            label={t('privacyAndSecurity.title')}
            icon={<Lock />}
            index={props.navigationIndex}
        >
            <NavigationRouted>
                <PrivacyAndSecurity />
            </NavigationRouted>
        </NavigationRoutedLink>
    );
};
