import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { OutlineButton } from '@bb-ui/react-library/dist/components/Button';
import { ClickAwayListener } from '@bb-ui/react-library/dist/components/ClickAwayListener';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogContentText } from '@bb-ui/react-library/dist/components/DialogContentText';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { Popper } from '@bb-ui/react-library/dist/components/Popper';

import createAuth0Client from '@auth0/auth0-spa-js';
import { TriangleDown } from '@bb-ui/icons/dist/small/TriangleDown';
import { useLocalStorage } from 'react-use';
import { Key } from 'ts-key-enum';
import { AnthologyIlluminateLogo } from '../../../app/assets/images/SVGs/AnthologyIlluminateLogoCenter';
import { FlagAU } from '../../../app/assets/images/SVGs/FlagAU';
import { FlagCA } from '../../../app/assets/images/SVGs/FlagCA';
import { FlagEU } from '../../../app/assets/images/SVGs/FlagEU';
import { FlagSG } from '../../../app/assets/images/SVGs/FlagSG';
import { FlagUS } from '../../../app/assets/images/SVGs/FlagUS';
import {
    auth0DefaultRegionLocalStorageName,
    useAppConfigContext,
} from '../../contexts/AppConfigProvider';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { localStorageUASTenantKey } from '../../utilities/constants';
import { useStyles } from './SignInModal.styles';
import { useAuthProviderCache } from '../../auth/auth0/useAuthProviderCache';
import {
    Auth0ProviderProps,
    useAuth0Context,
} from '../../auth/auth0/Auth0Context';

enum SignInModalState {
    loginTypePicker,
    incrAuthRegionPicker,
}
interface ILoginTypePickerProps {
    uasLoginAction: () => void;
    incrAuthLoginAction: () => void;
}

interface RegionPickerProps {
    onRegionSelectedAction: () => void;
}

const LoginTypePicker: React.FunctionComponent<ILoginTypePickerProps> = ({
    uasLoginAction,
    incrAuthLoginAction,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getHelpLink = useHelpLinks();

    return (
        <>
            <DialogContentText className={classes.selectSignInText}>
                {t('auth.selectLoginMethod')}
            </DialogContentText>
            <OutlineButton
                className={classes.dialogButton}
                onClick={uasLoginAction}
                data-testid="signin-institutional-account-button"
            >
                {t('auth.signInWithInstitutionalAccount')}
            </OutlineButton>
            <DialogContentText classes={{ root: classes.orText }}>
                {t('auth.institutionalAccountsIsNotSetUp')}
            </DialogContentText>
            <OutlineButton
                className={classes.dialogButton}
                onClick={incrAuthLoginAction}
                data-testid="signin-incr-auth-account-button"
            >
                { /* prettier-ignore */ }
                <Trans i18nKey="auth.signInWithIncrementalAuthAccount">
                    Sign in with your
                    <span lang="en" style={{ padding: '0' }}>
                        Anthology Illuminate
                    </span>
                    account
                </Trans>
            </OutlineButton>
            <Link href={getHelpLink('getReportingHelp')}>
                {t('auth.noAccount')}
            </Link>
        </>
    );
};

const getFlagFromRegionName = (regionName: string, mainFlag?: boolean) => {
    const flagMap = {
        us: FlagUS,
        eu: FlagEU,
        ca: FlagCA,
        apSoutheast2: FlagAU,
        apSoutheast1: FlagSG,
    };

    for (const [flagRegion, FlagSvg] of Object.entries(flagMap)) {
        if (
            regionName
                .split('-')
                .join('')
                .toLowerCase()
                .startsWith(flagRegion.toLowerCase())
        ) {
            return <FlagSvg svgKey={mainFlag ? 'selectedFlag' : regionName} />;
        }
    }
    return null;
};

const IncrementalAuthRegionPicker: React.FunctionComponent<
    RegionPickerProps
> = ({ onRegionSelectedAction }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { setRegion: setAuth0RegionName, incrAuthRegions: auth0Regions } =
        useAppConfigContext();

    const [previoslySelectedRegion] = useLocalStorage<string | undefined>(
        auth0DefaultRegionLocalStorageName,
        undefined,
    );

    const [selectedOption, setSelectedOption] = React.useState<string>(
        previoslySelectedRegion
            ? t(`regionPicker.regions.${previoslySelectedRegion}`)
            : '',
    );

    const onClickWrapper = () => {
        onRegionSelectedAction();
    };

    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const regionPickerInputRef = React.useRef<any>();

    const bigSelect =
        t('regionPicker.selectRegion').length > 40 && !selectedOption;

    return (
        <BbThemeProvider theme="light">
            <div className={classes.regionPickerContainer}>
                <div
                    className={classes.regionPickerHeaderContainer}
                    data-testid="region-picker-title"
                >
                    <p className={classes.regionPickerHeader}>
                        <Trans i18nKey="regionPicker.title">
                            <span lang="en">Anthology Illuminate</span> Account
                            Sign In
                        </Trans>
                    </p>
                </div>
                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <div className={classes.regionPickerItemContainer}>
                        <div
                            tabIndex={0}
                            className={`${
                                bigSelect
                                    ? classes.regionPickerSelectBig
                                    : classes.regionPickerSelect
                            } ${
                                selectedOption &&
                                classes.regionPickerSelectWithFlag
                            }`}
                            id="region-picker-select"
                            data-testid="region-picker-select"
                            onClick={() => setMenuOpen(!menuOpen)}
                            ref={regionPickerInputRef}
                            onKeyPress={e => {
                                if (e.key === Key.Enter || e.key === ' ') {
                                    setMenuOpen(!menuOpen);
                                }
                            }}
                            role="button"
                            aria-expanded={menuOpen}
                            aria-label={
                                selectedOption || t('regionPicker.selectRegion')
                            }
                        >
                            <span>
                                {selectedOption ||
                                    t('regionPicker.selectRegion')}
                            </span>

                            {selectedOption &&
                                getFlagFromRegionName(selectedOption, true)}

                            <TriangleDown
                                className={classes.regionPickerIcon}
                            />
                        </div>
                        <Popper
                            open={menuOpen}
                            disablePortal
                            anchorEl={regionPickerInputRef.current}
                            className={classes.regionPickerMenuContainer}
                            aria-activedescendant={selectedOption}
                            role="listbox"
                        >
                            <>
                                {auth0Regions.map(regionName => (
                                    <MenuItem
                                        className={classes.regionPickerMenuItem}
                                        tabIndex={menuOpen ? 0 : -1}
                                        key={regionName}
                                        value={regionName}
                                        button
                                        disableGutters={false}
                                        data-testid={`region-picker-select-${regionName}`}
                                        onClick={() => {
                                            setSelectedOption(
                                                t(
                                                    `regionPicker.regions.${regionName}`,
                                                ),
                                            );
                                            setAuth0RegionName(regionName);
                                            setMenuOpen(false);
                                        }}
                                        role="option"
                                        id={`regionPicker.regions.${regionName}`}
                                    >
                                        <div
                                            className={
                                                classes.regionPickerMenuItemContent
                                            }
                                        >
                                            <span>
                                                {t(
                                                    `regionPicker.regions.${regionName}`,
                                                )}
                                            </span>
                                            {getFlagFromRegionName(regionName)}
                                        </div>
                                    </MenuItem>
                                ))}
                            </>
                        </Popper>
                    </div>
                </ClickAwayListener>
                {selectedOption && (
                    <div className={classes.regionPickerButtonContainer}>
                        <BbThemeProvider theme="dark">
                            <OutlineButton
                                className={classes.regionPickerButton}
                                onClick={onClickWrapper}
                                data-testid="signin-incr-auth-account-button-continue"
                            >
                                {t('regionPicker.continue')}
                            </OutlineButton>
                        </BbThemeProvider>
                    </div>
                )}
            </div>
        </BbThemeProvider>
    );
};

export const SignInModal: React.FunctionComponent = () => {
    const classes = useStyles();
    const [state, setState] = React.useState<SignInModalState>(
        SignInModalState.loginTypePicker,
    );
    const { signInModalIsOpen, closeSignInModal } = useAppNavigationContext();
    const { initiateLoginWithRedirect } = useAuth0Context();

    const { t } = useTranslation();
    const { uas, auth0 } = useAppConfigContext();
    const loginWithUAS = () => {
        localStorage.removeItem(localStorageUASTenantKey);
        const returnUrl = encodeURIComponent(
            `${window.location.origin}/sign-in-callback`,
        );
        window.location.href = `${uas?.tenantPicker}/?returnUrl=${returnUrl}`;
    };

    const switchToRegionPicker = () => {
        setState(SignInModalState.incrAuthRegionPicker);
    };

    const loginWithIncrementalAuth = async () => {
        if (auth0) {
            // if this is set it will attempt to login to the main/first region
            // making users unable to log in
            if (initiateLoginWithRedirect) {
                localStorage.removeItem(localStorageUASTenantKey);

                await initiateLoginWithRedirect({
                    audience: auth0.audience!,
                    client_id: auth0.client_id!,
                    domain: auth0.domain!,
                });
            } else {
                throw new Error(
                    'Attempted to log before auth context was loaded.',
                );
            }
        } else {
            throw Error('Attempted to login without selected auth0 region!');
        }
    };

    let content;
    switch (state) {
        case SignInModalState.incrAuthRegionPicker:
            content = (
                <IncrementalAuthRegionPicker
                    onRegionSelectedAction={loginWithIncrementalAuth}
                />
            );
            break;
        case SignInModalState.loginTypePicker:
            content = (
                <LoginTypePicker
                    uasLoginAction={loginWithUAS}
                    incrAuthLoginAction={switchToRegionPicker}
                />
            );
            break;
    }

    return (
        <BbThemeProvider theme="dark">
            <Dialog
                open={signInModalIsOpen}
                maxWidth="lg"
                onClose={closeSignInModal}
                aria-labelledby="sign-in-modal-title"
                data-testid="sign-in-modal"
                TransitionProps={{
                    onEnter: () => setState(SignInModalState.loginTypePicker),
                }}
                PaperProps={{
                    className: classes.dialogPaper,
                }}
            >
                <DialogTitle // remove the bottom border, make the text invisible??? Too hacky?? Or put title logo back in here and mess with position of close button
                    id="sign-in-modal-title"
                    onClose={closeSignInModal}
                    closeLabel={t('auth.closeSignInModal')}
                    className={classes.dialogTitle}
                    aria-label={t('auth.login')}
                >
                    {/* The text "Illuminate" is hidden, but must be included here for the focus trap to function correctly */}
                    Illuminate
                    <AnthologyIlluminateLogo />
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {content}
                </DialogContent>
            </Dialog>
        </BbThemeProvider>
    );
};
