import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppBar } from '@bb-ui/react-library/dist/components/AppBar';
import { Toolbar } from '@bb-ui/react-library/dist/components/Toolbar';
import { ButtonBase } from '@bb-ui/react-library/dist/components/ButtonBase';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Announcements } from '@bb-ui/icons/dist/small/Announcements';
import { JoinSession } from '@bb-ui/icons/dist/small/JoinSession';
import { LeaveSession } from '@bb-ui/icons/dist/small/LeaveSession';
import { useAuth0Context } from '../../auth/auth0/Auth0Context';

import { useStyles } from './PageHeader.styles';

import { AnthologyIlluminateLogo } from '../../../app/assets/images/SVGs/AnthologyIlluminateLogo';
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';

export interface IPageHeaderProps {
    mainMenuButton: JSX.Element;
    showMainMenuButton?: boolean;
    logout: () => void;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
    mainMenuButton,
    showMainMenuButton,
    logout,
}) => {
    const homepageLinkRef = React.createRef<HTMLAnchorElement>();
    const { isAuthenticated } = useAuth0Context();
    const { t } = useTranslation();
    const classes = useStyles();
    const { closeSecondaryNavDrawer, openSignInModal } =
        useAppNavigationContext();

    const signInButton = (
        <ButtonBase
            data-testid="sign-in-button-in-page-header"
            onClick={openSignInModal}
            aria-label={t('auth.login')}
        >
            <Typography
                variant="subtitle2"
                component="span"
                className={classes.buttonText}
            >
                {t('auth.login')}
            </Typography>
            <JoinSession />
        </ButtonBase>
    );

    const logoutButton = (
        <ButtonBase
            data-testid="sign-out-link-in-page-header"
            onClick={logout}
            aria-label={t('auth.logout')}
        >
            <Typography
                variant="subtitle2"
                component="span"
                className={classes.buttonText}
            >
                {t('auth.logout')}
            </Typography>
            <LeaveSession />
        </ButtonBase>
    );

    return (
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar disableGutters className={classes.toolbar}>
                <div
                    className={classes.section}
                    style={!showMainMenuButton ? { visibility: 'hidden' } : {}}
                    data-testid="page-header-logo-link"
                >
                    {mainMenuButton}
                    <Link
                        to="/"
                        className={classes.titleLink}
                        aria-label={t('general.homePageLinkAriaLabel')}
                        ref={homepageLinkRef}
                        onClick={() => closeSecondaryNavDrawer()}
                    >
                        <div
                            data-testid="page-header-application-logo"
                            aria-hidden="true"
                        >
                            <AnthologyIlluminateLogo />
                        </div>
                    </Link>
                </div>
                <div
                    className={classnames(
                        classes.section,
                        classes.expandableSection,
                    )}
                >
                    <ul
                        className={classnames(
                            classes.itemList,
                            classes.listArea,
                        )}
                        data-testid="page-header-nav-item-list-right"
                    >
                        <li className={classes.languagePickerArea}>
                            <LanguagePicker />
                        </li>
                        {isAuthenticated && (
                            <li>
                                <ButtonBase
                                    id="beamer-whats-new"
                                    aria-label={t('header.whatsNew')}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                        className={classes.buttonText}
                                    >
                                        {t('header.whatsNew')}
                                    </Typography>
                                    <Announcements
                                        className={classes.whatsNewButtonIcon}
                                    />
                                </ButtonBase>
                            </li>
                        )}
                        <li className={classes.authButtonListItem}>
                            {isAuthenticated ? logoutButton : signInButton}
                        </li>
                    </ul>
                </div>
            </Toolbar>
        </AppBar>
    );
};
