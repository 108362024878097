import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ButtonBase, Link } from '@bb-ui/react-library';
import { OutlineLink } from '../StyledLinks/OutlineLink';
import { CustomCardSecondary } from '../CustomCards/CustomCardSecondary';
import { SnowflakeLauncher } from '../SnowflakeLauncher/SnowflakeLauncher';
import { useStyles } from './HomePage.styles';
import { useAccessControl } from '../../hooks/useAccessControl';

export const HomePageEnhanced: React.FC = () => {
    const { t } = useTranslation();
    const classes: any = useStyles();
    const access = useAccessControl();

    return (
        <>
            {access.reporting.ok && (
                <CustomCardSecondary
                    id="reporting"
                    title={t('reporting.title')}
                    imgSrc="/images/HomePage/reportingIllustration.svg"
                    className={classes.singleRow}
                    cardActions={
                        <div className={classes.link}>
                            <OutlineLink
                                to="/reporting"
                                data-testid="go-to-reporting-card-button"
                            >
                                {t('homePage.cards.buttons.goToReporting')}
                            </OutlineLink>
                        </div>
                    }
                >
                    {t('homePage.cards.reportingEnhanced.text')}
                    <ul>
                        {access['reporting/dataQA'].ok && (
                            <li>
                                <Link
                                    href="/reporting/data-q-and-a"
                                    data-testid="home-page-data-q-and-a-link"
                                    color="secondary"
                                    variant="h3"
                                >
                                    <span lang="en">Data Q&A</span>
                                </Link>
                            </li>
                        )}
                        {access['reporting/custom-reports'].ok && (
                            <li>
                                <Link
                                    href="/reporting/custom-reports"
                                    data-testid="home-page-custom-reports-link"
                                    color="secondary"
                                    variant="h3"
                                >
                                    {t('authoring.title')}
                                </Link>
                            </li>
                        )}
                    </ul>
                </CustomCardSecondary>
            )}

            {access.developer.ok && (
                <CustomCardSecondary
                    id="developer"
                    title={t('developer.title')}
                    imgSrc="/images/HomePage/developerIllustration.svg"
                    className={classes.singleRow}
                    cardActions={
                        <div className={classes.link}>
                            <OutlineLink
                                to="/developer"
                                data-testid="go-to-developer-card-button"
                            >
                                {t('homePage.cards.buttons.goToDeveloper')}
                            </OutlineLink>
                        </div>
                    }
                >
                    <Trans i18nKey="homePage.cards.developerEnhanced.text">
                        <span lang="en">Illuminate Enhanced</span> capabilities
                        empower BI analysts and data developers to create
                        customized datasets and reports to meet their specific
                        institutional needs.
                    </Trans>
                    <ul>
                        {access['developer/launch-snowflake'].ok && (
                            <li>
                                <SnowflakeLauncher
                                    ButtonComponent={ButtonBase}
                                    className={classes.launchSnowflake}
                                />
                            </li>
                        )}
                        {access['developer/data-dictionary'].ok && (
                            <li>
                                <Link
                                    href="/dictionary"
                                    color="secondary"
                                    variant="h3"
                                    data-testid="home-page-data-dictionary-link"
                                >
                                    {t('dataDictionary.title')}
                                </Link>
                            </li>
                        )}
                        {access['developer/settings'].ok && (
                            <li>
                                <Link
                                    href="/settings"
                                    color="secondary"
                                    variant="h3"
                                    data-testid="home-page-settings-link"
                                >
                                    {t('settings.title')}
                                </Link>
                            </li>
                        )}
                    </ul>
                </CustomCardSecondary>
            )}
        </>
    );
};
