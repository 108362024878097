import React from 'react';
import { useLocalStorage } from 'react-use';
import { useAuth0Context } from '../auth/auth0/Auth0Context';
import { useAppConfigContext } from '../contexts/AppConfigProvider';
import { localStorageUASTenantKey } from '../utilities/constants';

abstract class BaseUser {
    /**
     * contains tenant ID or RA locator
     */
    public abstract readonly tenantIdentifier: string;
    /**
     * contains person ID or email
     */
    public abstract readonly personIdentifier: string;

    abstract readonly region: string;
    abstract readonly snowflakeRegion: string;

    readonly userId: string;

    protected userData: any;
    protected baseUrl: string;

    public name: string;

    protected getTokenInfo(variable: string) {
        return this.userData[`${this.baseUrl}/${variable}`];
    }

    // eslint-disable-next-line class-methods-use-this
    public isUniversalUser(): this is UniversalAuthUser {
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    public isIncrementalUser(): this is IncrementalAuthUser {
        return false;
    }

    public abstract isSupport(): boolean;
    public abstract isInstitutionAdmin(): boolean;
    public abstract isReportViewer(): boolean;
    public abstract isReaderAccountAdmin(): boolean;
    public abstract isReportingAuthor(): boolean;

    public hasSnowflakeAccess(): boolean {
        return (
            this.isSupport() ||
            this.isInstitutionAdmin() ||
            this.isReaderAccountAdmin()
        );
    }

    public hasDataQAndA(): boolean {
        if (this.isUniversalUser()) {
            return !this.isRestrictedViewer();
        }
        return false;
    }

    public hasAdminAccess(): boolean {
        return this.isSupport() || this.isInstitutionAdmin();
    }

    public toString() {
        return `${this.constructor.name}<Tenant[${this.tenantIdentifier}] | Person[${this.personIdentifier}]>`;
    }

    // eslint-disable-next-line class-methods-use-this
    public hasReportAccess(): boolean {
        return (
            this.isReportViewer() ||
            this.isSupport() ||
            this.isInstitutionAdmin()
        );
    }

    public get firstName() {
        /*
         * This is a weird logic for backwards compatibility - if there's only one word in the name, it can be considered
         * as first name, otherwise take all except the last word as the first name
         */
        const splitName = this.name.split(' ');
        if (splitName.length === 1) {
            return splitName[0];
        }
        return this.name.split(' ').slice(0, -1).join(' ') || this.name;
    }

    public get lastName() {
        /*
         * This is a weird logic for backwards compatibility - if there's only one word in the name, it is considered the first name
         * otherwise return the last word of the name
         */
        const splitName = this.name.split(' ');
        if (splitName.length === 1) {
            return '';
        }
        return this.name.split(' ').slice(-1)[0] || '';
    }

    constructor(
        userData: { [key: string]: boolean | string | string[] },
        baseUrl: string,
    ) {
        this.userData = userData;
        this.baseUrl = baseUrl;
        this.userId = this.userData.sub;
        this.name = userData.name as string;
    }
}

const incrementalBaseUrl = 'https://bb-foundations.com';

export class IncrementalAuthUser extends BaseUser {
    readonly locator: string;
    readonly snowflakeRegion: string;
    readonly email: string;

    /**
     * @param userData the user data generated by auth0 and converted by the bb-ui hook
     * @example
     *   {
     *       "https://bb-foundations.com/tenantId": "NAA99994",
     *       "https://bb-foundations.com/snowflakeRegion": "us-west-2",
     *       "https://bb-foundations.com/tenantScope": "NAA99994",
     *       "nickname": "bbdata_naa99994_ia_01",
     *       "name": "bbdata_naa99994_ia_01@example.com",
     *       "picture": "https://s.gravatar.com/avatar/633c94f85461fff3fd8083ac5dbe0863?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fbb.png",
     *       "updated_at": "2022-05-23T14:52:10.505Z",
     *       "email": "bbdata_naa99994_ia_01@example.com",
     *       "email_verified": true,
     *       "sub": "auth0|6055eba6a465f7006f0998ca"
     *   }
     * @param baseUrl the base url used to identify params in the JWT token
     * @example "https://bb-foundations.com"
     */
    constructor(
        userData: { [key: string]: boolean | string | string[] },
        baseUrl: string = incrementalBaseUrl,
    ) {
        super(userData, baseUrl);
        this.locator = this.getTokenInfo('tenantScope');
        this.snowflakeRegion = this.getTokenInfo('snowflakeRegion');
        this.email = userData.email as string;
    }

    get region(): string {
        return this.snowflakeRegion === 'us-west-2'
            ? 'us-east-1'
            : this.snowflakeRegion;
    }

    // eslint-disable-next-line class-methods-use-this
    public isInstitutionAdmin(): boolean {
        // this is always the case as the users are provisioned manually
        // -> they can always be expected to be admins
        return true;
    }

    // eslint-disable-next-line class-methods-use-this
    public isSupport(): boolean {
        // support users should use UAS -> this is always false
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    public isReaderAccountAdmin(): boolean {
        // reader account admin users use UAS -> this is always false
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    public isIncrementalUser(): this is IncrementalAuthUser {
        return true;
    }

    // eslint-disable-next-line class-methods-use-this
    public isReportViewer(): boolean {
        // this is always the case as the users are provisioned manually
        // -> they can always be expected to be admins
        return true;
    }

    // eslint-disable-next-line class-methods-use-this
    public isReportingAuthor(): boolean {
        return false;
    }

    public get tenantIdentifier(): string {
        return this.locator;
    }

    public get personIdentifier(): string {
        return this.email;
    }
}

export class UniversalAuthUser extends BaseUser {
    readonly region: string;
    readonly personId: string;
    readonly groups: string[];
    readonly tenantId: string;

    /**
     * @param userData the user data generated by auth0 and converted by the bb-ui hook (keys differ per region based on baseUrl)
     * @example
     *   {
     *       "https://bb-foundations.com/tenantId": "6c51a13b-646a-4b82-8b5d-eb5938ee258a",
     *       "https://bb-foundations.com/region": "us-east-1",
     *       "https://bb-foundations.com/personId": "3039d7ca-ddc9-11eb-b43c-8fa0eb93bb24",
     *       "https://bb-foundations.com/fndsGroups": [
     *           "InstitutionAdmin",
     *           "InstitutionAdmin#User"
     *       ],
     *       "https://bb-foundations.com/tenantScope": "6c51a13b-646a-4b82-8b5d-eb5938ee258a",
     *       "nickname": "",
     *       "name": "",
     *       "picture": "https://cdn.auth0.com/avatars/default.png",
     *       "updated_at": "2022-05-23T14:48:17.652Z",
     *       "sub": "auth0|6c51a13b-646a-4b82-8b5d-eb5938ee258a-LearnConnector|bbdata_naa99994_uas_01"
     *   }
     * @param baseUrl the base url used to identify params in the JWT token
     * @example "https://bb-foundations.com"
     */
    constructor(
        userData: { [key: string]: string | string[] },
        baseUrl: string,
    ) {
        super(userData, baseUrl);

        this.region = this.getTokenInfo('region');
        this.personId = this.getTokenInfo('personId');
        this.groups = this.getTokenInfo('fndsGroups') || [];
        this.tenantId = this.getTokenInfo('tenantScope');
    }

    public isInGroup(groupName: string): boolean {
        return (
            this.groups.includes(groupName) ||
            this.groups.includes(`${groupName}#User`) ||
            this.groups.includes(`${groupName}#user`)
        );
    }

    public isInstitutionAdmin(): boolean {
        return this.isInGroup('InstitutionAdmin');
    }

    public isReportViewer(): boolean {
        return (
            this.isInGroup('BbDataReportViewer') ||
            this.isInGroup('BbDataRestrictedViewer') ||
            this.isInGroup('BbDataAuthor')
        );
    }

    public isNonRestrictedReportViewer(): boolean {
        return (
            this.isInGroup('BbDataReportViewer') ||
            this.isInGroup('BbDataAuthor')
        );
    }

    public isSupport(): boolean {
        // internal only, via okta group bb-fnds-client-support
        return this.isInGroup('ClientSupport');
    }

    public isReaderAccountAdmin(): boolean {
        // internal only, via okta group bb-data-snowflake-reader-account-admin(-escalated)
        return this.isInGroup('SnowflakeReaderAccountAdmin');
    }

    public isReportingAuthor(): boolean {
        return this.isInGroup('BbDataAuthor');
    }

    public isRestrictedViewer(): boolean {
        return this.isInGroup('BbDataRestrictedViewer');
    }

    public get snowflakeRegion(): string {
        return this.region === 'us-east-1' ? 'us-west-2' : this.region;
    }

    public get tenantIdentifier(): string {
        return this.tenantId;
    }

    public get personIdentifier(): string {
        return this.personId;
    }

    // eslint-disable-next-line class-methods-use-this
    public isUniversalUser(): this is UniversalAuthUser {
        return true;
    }
}

export type UserType = IncrementalAuthUser | UniversalAuthUser | null;

export const useUser = (): IncrementalAuthUser | UniversalAuthUser | null => {
    const { user: userData, isAuthenticated } = useAuth0Context();
    const { uas } = useAppConfigContext();

    const [uasTenant] = useLocalStorage<string>(localStorageUASTenantKey, '', {
        raw: true,
    });

    const getUser = React.useCallback(() => {
        if (!userData || !isAuthenticated) {
            return null;
        }

        // if the uas tenant parameter is set in local storage, user should be logged in via uas
        if (!uasTenant) {
            return new IncrementalAuthUser(userData);
        }

        if (!uas) {
            return null;
        }

        return new UniversalAuthUser(userData, uas.bbNamespace);
    }, [isAuthenticated, uas, uasTenant, userData]);

    const [user, setUser] = React.useState(getUser());

    const previousUserData = React.useRef<string>(
        JSON.stringify({
            userData,
            uas,
            uasTenant,
        }),
    );

    React.useEffect(() => {
        const currentUserData = JSON.stringify({
            userData,
            uas,
            uasTenant,
        });

        if (previousUserData.current !== currentUserData) {
            previousUserData.current = currentUserData;
            setUser(getUser());
        }
    }, [getUser, uas, uasTenant, userData]);

    return user;
};
