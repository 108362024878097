import React, { memo } from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Trans } from 'react-i18next';
import { useAuth0Context } from '../../auth/auth0/Auth0Context';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { JoinTheCommunityBanner } from './JoinTheCommunityBanner';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useStyles } from './HomePage.styles';
import { HomePageEnhanced } from './HomePageEnhanced';
import { HomePageNotEnhanced } from './HomePageNotEnhanced';
import { HomePageEnhancedAd } from './HomePageEnhancedAd';
import { HomePageNotAuthenticated } from './HomePageNotAuthenticated';
import { useAccessControl } from '../../hooks/useAccessControl';

export interface IHomePageProps {
    userHasSettingsAccess?: boolean;
    userHasDataQAndA?: boolean;
}

export const HomePage: React.FunctionComponent<IHomePageProps> = () => {
    const classes: any = useStyles();
    const getHelpLink = useHelpLinks();
    const { isAuthenticated } = useAuth0Context();

    const access = useAccessControl();

    useApplicationTitle('Home');

    return (
        <ResponsiveBox className={classes.homePageContainer}>
            <div
                className={classes.gridContainer}
                data-testid="home-page-container"
            >
                {isAuthenticated ? (
                    <>
                        <Typography
                            variant="h1"
                            className={classes.subtitle}
                            data-testid="home-page-welcome-title"
                        >
                            { /* prettier-ignore */}
                            <Trans i18nKey="homePage.subtitles.welcome" >
                                Welcome to <span style={{ fontWeight: 'bold' }}
                                            lang="en"
                                            >
                                                Anthology Illuminate
                                </span>
                            </Trans>
                        </Typography>
                        {access['reporting/custom-reports/enabled'].ok ? (
                            <HomePageEnhanced />
                        ) : (
                            <>
                                <HomePageNotEnhanced />
                                {access.reporting.ok && <HomePageEnhancedAd />}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <HomePageNotAuthenticated />
                        <HomePageEnhancedAd />
                    </>
                )}

                <div className={classes.singleRow}>
                    <JoinTheCommunityBanner />
                </div>
            </div>
            <ActionAreaButtons helpLink={getHelpLink('main')} />
        </ResponsiveBox>
    );
};

export default memo(HomePage);
