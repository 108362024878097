import { AxiosError } from 'axios';
import React from 'react';
import { usePrevious } from 'react-use';
import { useAppConfigContext } from '../contexts/AppConfigProvider';
import { useApi } from './useApi';
import { useUser } from './useUser';

export enum SnowflakeInfoState {
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    NOT_FETCHED = 'NOT_FETCHED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    LOADING = 'LOADING',
}

interface SnowflakeInfoNotSupported {
    state: SnowflakeInfoState.NOT_SUPPORTED;
}

interface SnowflakeInfoPreparation {
    state: SnowflakeInfoState.NOT_FETCHED;
    fetch: () => void;
}

interface SnowflakeInfoLoading {
    state: SnowflakeInfoState.LOADING;
}

interface SnowflakeInfoFailed {
    state: SnowflakeInfoState.FAILED;
    error: AxiosError<any>;
    restart: () => void;
}

interface SnowflakeInfoSuccess {
    state: SnowflakeInfoState.SUCCESS;
    locator?: string;
    fullLocator?: string;
    snowflakeUrl?: string;
}

type SnowflakeInfoResult =
    | SnowflakeInfoPreparation
    | SnowflakeInfoLoading
    | SnowflakeInfoFailed
    | SnowflakeInfoSuccess
    | SnowflakeInfoNotSupported;

const snowflakeDomain = 'snowflakecomputing.com';

const getFullLocatorFromSnowflakeUrl = (snowflakeUrl: string) =>
    snowflakeUrl.replace('https://', '').replace(`.${snowflakeDomain}`, '');

const getLocatorFromSnowflakeUrl = (snowflakeUrl: string) =>
    getFullLocatorFromSnowflakeUrl(snowflakeUrl).split('.')[0];

/**
 * @returns null if no tenant/tenant is not authorized, otherwise SnowflakeInfo
 */
export const useTenantSnowflakeInfo = (): SnowflakeInfoResult => {
    const user = useUser();
    const { api } = useAppConfigContext();

    const {
        data: snowflakeUrlFromRequest,
        loading: snowflakeUrlFromRequestLoading,
        error: snowflakeUrlFromRequestError,
        execute,
        reset,
    } = useApi<{ url: string }>({
        useAuth: true,
        method: 'GET',
        executeType: 'manual',
    });

    const previousSnowflakeUrlFromRequestError = usePrevious(
        snowflakeUrlFromRequestError,
    );

    React.useEffect(() => {
        if (
            previousSnowflakeUrlFromRequestError !==
                snowflakeUrlFromRequestError &&
            snowflakeUrlFromRequestError
        ) {
            console.error(
                `Fetching Snowflake URL for ${user} failed! (${snowflakeUrlFromRequestError})`,
            );
        }
    }, [
        previousSnowflakeUrlFromRequestError,
        snowflakeUrlFromRequestError,
        user,
    ]);

    const fetchSnowflakeLocator = React.useCallback(() => {
        if (!user) {
            console.error(
                'fetchSnowflakeUrl failed - no user is currently logged in',
            );
        } else {
            execute({
                url: `${
                    api!.serviceAccountManagement
                }/v1/data/tenants/${user.tenantIdentifier}/snowflakeUrl`,
            });
        }
    }, [api, execute, user]);

    if (!user || !user.hasSnowflakeAccess()) {
        return {
            state: SnowflakeInfoState.NOT_SUPPORTED,
        };
    }

    if (snowflakeUrlFromRequestError) {
        return {
            state: SnowflakeInfoState.FAILED,
            error: snowflakeUrlFromRequestError,
            restart: reset,
        };
    }

    if (snowflakeUrlFromRequestLoading) {
        return {
            state: SnowflakeInfoState.LOADING,
        };
    }

    if (snowflakeUrlFromRequest) {
        return {
            state: SnowflakeInfoState.SUCCESS,
            fullLocator: getFullLocatorFromSnowflakeUrl(
                snowflakeUrlFromRequest.url,
            ),
            locator: getLocatorFromSnowflakeUrl(snowflakeUrlFromRequest.url),
            snowflakeUrl: snowflakeUrlFromRequest.url,
        };
    }

    return {
        state: SnowflakeInfoState.NOT_FETCHED,
        fetch: fetchSnowflakeLocator,
    };
};
