import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tabs } from '@bb-ui/react-library/dist/components/Tabs';
import { Tab } from '@bb-ui/react-library/dist/components/Tab';
import { TabPanel } from '@bb-ui/react-library/dist/components/TabPanel';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Prompt, useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ITabProps } from '@bb-ui/react-library/dist/components/Tab/Tab.types';
import { useStyles } from './Settings.styles';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { MainHeader } from '../MainHeader/MainHeader';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { SnowflakeAccountSettings } from './SnowflakeAccountSettings/SnowflakeAccountSettings';
import { LicenseManagement } from './LicenseManagement/LicenseManagement';
import { GlobalSettings } from './GlobalSettings/GlobalSettings';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useUser } from '../../hooks/useUser';
import { useFeatureContext } from '../../contexts/FeatureContext';
import { GlobalSettingsDialog } from './GlobalSettings/GlobalSettingsDialog/GlobalSettingsDialog';
import { Frame } from '../Frame/Frame';
import { UnavailableTooltip } from '../RequiresApi/UnavailableTooltip';
import { Access, useAccessControl } from '../../hooks/useAccessControl';

const useTabStyles = makeStyles((theme: Theme) =>
    createStyles({
        alwaysHover: {
            pointerEvents: 'visible !important' as any,
        },
    }),
);

const TabRequiringApi = (
    props: ITabProps & {
        tabNum: number;
        setInitialTab: (tabNum: number) => void;
        access: Access[];
    },
) => {
    const { access, tabNum, setInitialTab, ...tabProps } = props;
    const styles = useTabStyles();

    const noAccess = access.filter(acc => acc.ok).length === 0;

    const unavailable =
        access.filter(acc => !acc.ok && acc.reason === 'REGION_UNAVAILABLE')
            .length === access.length;

    React.useEffect(() => {
        if (access.filter(acc => acc.ok).length) {
            setInitialTab(tabNum);
        }
    }, [setInitialTab, access, tabNum]);

    if (noAccess && !unavailable) {
        // the tabs are auto-indexed even when tabIndex is set
        // so it's not possible to set the intial position without a placeholder tab
        return <Tab style={{ display: 'none' }} />;
    }

    return (
        <UnavailableTooltip placement="bottom" show={unavailable}>
            <span style={{ display: 'inline-grid' }}>
                <Tab
                    {...tabProps}
                    id={`tab-${tabNum}`}
                    aria-controls={`tab-${tabNum}`}
                    label={tabProps.label}
                    className={styles.alwaysHover}
                    disabled={unavailable}
                >
                    {tabProps.children}
                </Tab>
            </span>
        </UnavailableTooltip>
    );
};

export function Settings() {
    const classes = useStyles();
    const { t } = useTranslation();
    const bbUser = useUser();
    const access = useAccessControl();

    const { api } = useAppConfigContext();
    const { globalSettingsEnabled } = useFeatureContext();
    const history = useHistory();

    useApplicationTitle(t('settings.title'));

    const getHelpLink = useHelpLinks();
    const initialTabRef = React.useRef<number>();
    const [tabIndex, setTabIndex] = React.useState<number>();

    const setInitialTab = React.useCallback((newVal: number) => {
        if (initialTabRef.current === undefined) {
            initialTabRef.current = newVal;
            setTabIndex(newVal);
        }
    }, []);

    const [nextTabIndex, setNextTabIndex] = React.useState(0);
    const [nextPath, setNextPath] = useState('');
    const [modifiedStateChanged, setModifiedStateChanged] = useState(false);
    const [modifiedStateChangedTabChange, setModifiedStateChangedTabChange] =
        useState(false);
    const [
        modifiedStateChangedLeavingPage,
        setModifiedStateChangedLeavingPage,
    ] = useState(false);

    const handleTabChange = (
        event: ChangeEvent<unknown>,
        index: React.SetStateAction<number>,
    ) => {
        if (modifiedStateChanged && !modifiedStateChangedTabChange) {
            // To stay on the same tab before user decides to cancel or save changes
            setNextTabIndex(index);
            setModifiedStateChangedTabChange(true);
        } else {
            setTabIndex(index as number);
        }
    };

    const pageContent: {
        tabs?: React.JSX.Element;
        content?: React.JSX.Element;
    } = {};

    if (bbUser?.isUniversalUser()) {
        pageContent.tabs = (
            <Tabs
                value={tabIndex ?? initialTabRef.current ?? false}
                onChange={handleTabChange}
                aria-orientation="horizontal"
                className={classes.tabs}
                tabIndex="tabIndex"
            >
                <TabRequiringApi
                    tabNum={0}
                    label={t('settings.licenseManagement.title')}
                    data-testid="licenseManagementTab"
                    access={[access['developer/settings/user-management']]}
                    setInitialTab={setInitialTab}
                />
                <TabRequiringApi
                    tabNum={1}
                    label={
                        /* prettier-ignore */
                        <p>
                            <Trans i18nKey="settings.userManagement.title">
                                <span lang="en">Snowflake</span> Account Settings
                            </Trans>
                        </p>
                    }
                    data-testid="snowflakeAccountSettingsTab"
                    access={[
                        access['developer/settings/service-users'],
                        access['developer/settings/credits'],
                    ]}
                    setInitialTab={setInitialTab}
                />
                <TabRequiringApi
                    tabNum={2}
                    label={t('settings.globalSettings.title')}
                    data-testid="globalSettingsTab"
                    access={[access['developer/settings/tenant-settings']]}
                    setInitialTab={setInitialTab}
                />
            </Tabs>
        );
        // tabIndex state's set in 2nd cycle; 1st cycle takes the ref value populated from the tab creation
        const activeTabIndex = tabIndex ?? initialTabRef.current;

        pageContent.content = (
            <TabPanel
                panelId="tabpanel-1"
                ariaLabelledby={`tab-${activeTabIndex}`}
            >
                {activeTabIndex === 0 && (
                    <LicenseManagement key="licenseManagement" />
                )}
                {activeTabIndex === 1 && (
                    <SnowflakeAccountSettings key="snowflakeSettings" />
                )}
                {activeTabIndex === 2 && (
                    <GlobalSettings
                        key="globalSettings"
                        onModifiedStateChanged={setModifiedStateChanged}
                    />
                )}
            </TabPanel>
        );
    } else {
        pageContent.content = (
            <>
                <Typography
                    variant="h2"
                    component="h2"
                    className={classes.title}
                    data-testid="snowflakeAccountSettings"
                >
                    {/* prettier-ignore */}
                    <Trans i18nKey="settings.userManagement.title">
                        <span lang="en">Snowflake</span> Account Settings
                    </Trans>
                </Typography>
                <SnowflakeAccountSettings key="snowflakeSettings" />
            </>
        );
    }

    return (
        <>
            <MainHeader title={t('settings.title')} />
            <ResponsiveBox>
                <Frame className={classes.paper}>
                    {pageContent.tabs}
                    {pageContent.content}
                </Frame>
                <ActionAreaButtons helpLink={getHelpLink('settings')} />
            </ResponsiveBox>

            <Prompt
                when={modifiedStateChanged && !modifiedStateChangedLeavingPage}
                message={location => {
                    setModifiedStateChangedLeavingPage(true);
                    setNextPath(location.pathname);
                    return false;
                }}
            />
            {/* Navigation to another page */}
            {modifiedStateChangedLeavingPage && tabIndex === 2 && (
                <GlobalSettingsDialog
                    onClose={() => {
                        setModifiedStateChanged(false);
                        setModifiedStateChangedLeavingPage(false);
                        history.push(nextPath);
                    }}
                    onStay={() => {
                        setModifiedStateChangedLeavingPage(false);
                    }}
                />
            )}
            {/* Navigation to another tab within the page */}
            {modifiedStateChangedTabChange && tabIndex === 2 && (
                <GlobalSettingsDialog
                    onClose={() => {
                        setModifiedStateChanged(false);
                        setModifiedStateChangedTabChange(false);
                        setTabIndex(nextTabIndex);
                    }}
                    onStay={() => {
                        setModifiedStateChangedTabChange(false);
                    }}
                />
            )}
        </>
    );
}
