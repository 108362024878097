import React from 'react';
import { useAppConfigContext } from '../contexts/AppConfigProvider';
import { useUser } from './useUser';
import { useTenantSettingsContext } from '../contexts/TenantSettingsContext';
import { useFeatureContext } from '../contexts/FeatureContext';
import { FeatureName } from '../featureFlags/Feature.types';

export class Access {
    public readonly reason:
        | 'REGION_UNAVAILABLE'
        | 'USER_HAS_NO_ACCESS'
        | 'OK'
        | 'FEATURE_MISSING';

    public readonly ok: boolean;

    constructor(
        ok: boolean,
        reason:
            | 'REGION_UNAVAILABLE'
            | 'USER_HAS_NO_ACCESS'
            | 'OK'
            | 'FEATURE_MISSING',
    ) {
        this.reason = reason;
        this.ok = ok;
    }

    toBoolean() {
        return this.ok;
    }
}

export type AccessContextProps = {
    developer: Access;
    'developer/data-dictionary': Access;
    'developer/settings': Access;
    reporting: Access;
    'reporting/reports': Access;
    'reporting/dataQA': Access;
    'reporting/custom-reports': Access;
    'reporting/custom-reports/authoring': Access;
    'reporting/custom-reports/enabled': Access;
    'developer/settings/credits': Access;
    'developer/settings/service-users': Access;
    'developer/settings/tenant-settings': Access;
    'developer/settings/user-management': Access;
    'developer/launch-snowflake': Access;
    'developer/settings/uas-migration': Access;
    authenticated: Access;
};

const REGION_UNAVAILABLE = new Access(false, 'REGION_UNAVAILABLE');
const USER_HAS_NO_ACCESS = new Access(false, 'USER_HAS_NO_ACCESS');
const FEATURE_MISSING = new Access(false, 'FEATURE_MISSING');
export const ACCESS_OK = new Access(true, 'OK');

export const useAccessControl: () => AccessContextProps = () => {
    const user = useUser();
    const { api } = useAppConfigContext();
    const { data: { aiOptIn = false } = {} } = useTenantSettingsContext();
    const features = useFeatureContext();

    const available = React.useMemo(
        () =>
            (props: {
                api: undefined | string | boolean;
                user: boolean | null | undefined;
                features: boolean | undefined;
            }) => {
                if (props.api === undefined) {
                    return REGION_UNAVAILABLE;
                }
                if (!props.user) {
                    return USER_HAS_NO_ACCESS;
                }
                if (!props.features) {
                    return FEATURE_MISSING;
                }
                return ACCESS_OK;
            },
        [],
    );

    const result = React.useMemo(
        () => ({
            authenticated: available({
                api: true,
                user: user !== null,
                features: true,
            }),
            developer: available({
                api:
                    api?.dataDictionary ||
                    api?.serviceAccountManagement ||
                    api?.tenantSettings,
                user: true,
                features: true,
            }),
            'developer/data-dictionary': available({
                api: api?.dataDictionary,
                user: true,
                features: true,
            }),
            reporting: available({
                api: api?.quicksightDashboards,
                user: true,
                features: true,
            }),
            'reporting/reports': available({
                api: api?.quicksightDashboards,
                user: user?.hasReportAccess(),
                features: features?.reportsEnabled,
            }),
            'reporting/dataQA': available({
                api: api?.quicksightDashboards,
                user:
                    user?.hasReportAccess() && user?.hasDataQAndA() && aiOptIn,
                features: true,
            }),
            'reporting/custom-reports': available({
                api: api?.quicksightDashboards,
                user:
                    user?.isUniversalUser() &&
                    (user.isNonRestrictedReportViewer() ||
                        user.isInstitutionAdmin() ||
                        user.isSupport()),
                features: features['bbdata.authoring.preview' as FeatureName],
            }),
            'reporting/custom-reports/authoring': available({
                api: api?.quicksightDashboards,
                user: user?.isReportingAuthor(),
                features: features['bbdata.authoring.preview' as FeatureName],
            }),
            'reporting/custom-reports/enabled': available({
                api: api?.quicksightDashboards,
                user: user !== null,
                features: features['bbdata.authoring.preview' as FeatureName],
            }),
            'developer/settings': available({
                api:
                    api?.creditManagement ||
                    api?.serviceAccountManagement ||
                    api?.userManagement ||
                    api?.tenantSettings,
                user:
                    user?.isInstitutionAdmin() ||
                    user?.isSupport() ||
                    user?.isReaderAccountAdmin(),
                features: true,
            }),
            'developer/settings/credits': available({
                api: api?.creditManagement,
                user:
                    user?.isInstitutionAdmin() ||
                    user?.isSupport() ||
                    user?.isReaderAccountAdmin(),
                features: true,
            }),
            'developer/settings/service-users': available({
                api: api?.serviceAccountManagement,
                user:
                    user?.isInstitutionAdmin() ||
                    user?.isSupport() ||
                    user?.isReaderAccountAdmin(),
                features: true,
            }),
            'developer/settings/user-management': available({
                api: api?.userManagement && api?.quicksightDashboards,
                user:
                    user?.isInstitutionAdmin() ||
                    user?.isSupport() ||
                    user?.isReaderAccountAdmin(),
                features: true,
            }),
            'developer/settings/tenant-settings': available({
                api: api?.tenantSettings,
                user:
                    user?.isInstitutionAdmin() ||
                    user?.isSupport() ||
                    user?.isReaderAccountAdmin(),
                features: features.globalSettingsEnabled,
            }),
            'developer/launch-snowflake': available({
                api: api?.authProv && api?.serviceAccountManagement,
                user: user?.hasSnowflakeAccess(),
                features: true,
            }),
            'developer/settings/uas-migration': available({
                api: api?.authProv,
                user: user?.isInstitutionAdmin() || user?.isSupport(),
                features: features.uasMigrationEnabled,
            }),
        }),
        [api, user, features, aiOptIn, available],
    );
    return result;
};
