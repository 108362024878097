import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Card } from '@bb-ui/react-library/dist/components/Card';
import { Flag } from '@bb-ui/icons/dist/small/Flag';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { useAuth0Context } from '../../../../auth/auth0/Auth0Context';
import { useAppConfigContext } from '../../../../contexts/AppConfigProvider';
import { useApi, IApiConfig } from '../../../../hooks/useApi';
import { getTenantId } from '../../../../utilities/utilities';
import { Message } from '../../../Message/Message';
import { SkeletonCard } from '../../../Skeleton/SkeletonCard';

interface CreditsResponse {
    [accountName: string]: {
        creditsRemaining: number;
        creditsQuota: number;
    };
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            paddingBottom: theme.spacing(1),
        },
        creditsCard: {
            marginBottom: theme.spacing(3),
            width: 'fit-content',
            minWidth: '200px',
        },
        creditLabel: {
            fontSize: theme.typography.fontSizeLarge,
            fontWeight: theme.typography.fontWeightSemiBold,
        },
        redLabel: {
            color: theme.palette.error.main,
        },
        creditsFlag: {
            marginInlineStart: theme.spacing(0.5),
            fontSize: theme.typography.fontSizeDefault,
        },
    }),
);

export const SnowflakeCreditsUsage: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { api, uas } = useAppConfigContext();
    const { user: auth0ContextUser } = useAuth0Context();

    const currentMonthDate = React.useMemo(
        () => new Date().toISOString().slice(0, 7),
        [],
    );

    const getCreditsUrl = `${api!.creditManagement}/v1/tenants/${getTenantId(
        auth0ContextUser,
        uas,
    )}/credits?monthDate=${currentMonthDate}`;

    const creditsConfig = React.useMemo<IApiConfig>(
        () => ({
            url: getCreditsUrl,
            useAuth: true,
            method: 'GET',
        }),
        [getCreditsUrl],
    );

    const {
        data: accountCreditsData,
        loading: accountCreditsLoading,
        error: accountCreditsError,
    } = useApi<{
        tenantId: string;
        accounts: CreditsResponse;
    }>(creditsConfig);

    const CREDITS_WARNING_THRESHOLD = 30;
    const accountData =
        accountCreditsData?.accounts &&
        Object.values(accountCreditsData.accounts)[0];
    const displayCreditsWarning =
        !accountData ||
        accountData.creditsQuota === 0 ||
        (accountData.creditsRemaining / accountData.creditsQuota) * 100 <=
            CREDITS_WARNING_THRESHOLD;

    return (
        <>
            {accountData && (
                <Card
                    className={classes.creditsCard}
                    data-testid="remaining-credits-card"
                >
                    <CardContent>
                        <>
                            <Typography
                                className={`${classes.creditLabel} ${
                                    displayCreditsWarning
                                        ? classes.redLabel
                                        : ''
                                }`}
                                data-testid="credits-label"
                            >
                                {t(
                                    'settings.userManagement.creditsUsage.credits',
                                    {
                                        remaining: parseFloat(
                                            accountData.creditsRemaining.toFixed(
                                                2,
                                            ),
                                        ),
                                        total: parseFloat(
                                            accountData.creditsQuota.toFixed(2),
                                        ),
                                    },
                                )}
                                {displayCreditsWarning && (
                                    <Flag
                                        data-testid="credits-flag"
                                        className={classes.creditsFlag}
                                    />
                                )}
                            </Typography>
                            <Typography data-testid="credits-month">
                                {t(
                                    'settings.userManagement.creditsUsage.month',
                                )}
                            </Typography>
                        </>
                    </CardContent>
                </Card>
            )}
            {accountCreditsLoading && (
                <div
                    data-testid="credits-loading-skeletons"
                    aria-label={t(
                        'settings.userManagement.creditsUsage.loading',
                    )}
                    className={classes.creditsCard}
                    role="status"
                >
                    <SkeletonCard />
                </div>
            )}
            {accountCreditsError && (
                <Message
                    variant="error"
                    message={t('reports.error.dataDidNotLoad')}
                />
            )}
        </>
    );
};
