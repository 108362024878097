/* eslint-disable no-plusplus */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FolderClosed } from '@bb-ui/icons/dist/medium/FolderClosed';
import axios from 'axios';
import {
    Grade,
    GraduateCap,
    HandRaise,
    Lightbulb,
    Report as ReportIcon,
} from '@bb-ui/icons/dist/medium';
import { useAuth0Context } from '../../auth/auth0/Auth0Context';
import {
    IQuickSightDashboard,
    IQuickSightReport,
    ReportingArea,
    TenantStatus,
    TenantStatusApiValue,
} from '../MainPage/MainPage.types';
import { useFeatureContext } from '../../contexts/FeatureContext';
import { useUser } from '../../hooks/useUser';
import { getReportApiLanguage } from '../../utilities/localization';
import { useI18nContext } from '../I18nProvider/I18nProvider';
import { FeatureName } from '../../featureFlags/Feature.types';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { NavigationRoutedLink } from '../NavigationRouting/NavigationRoutedLink';
import { NavigationRouted } from '../NavigationRouting/NavigationRouted';
import { DataQAndA } from '../DataQAndA/DataQAndA';
import { CustomReportsTeaser } from '../CustomReports/CustomReportsTeaser';
import { CustomReports } from '../CustomReports/CustomReports';
import { useAppLoadingContext } from '../../contexts/AppLoadingContext';
import { useAccessControl } from '../../hooks/useAccessControl';
import { CustomReportsUnauthorized } from '../CustomReports/CustomReportsUnauthorized';

const Report = React.lazy(
    () => import(/* webpackChunkName: 'report' */ '../Report/Report'),
);

const ReportingPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'reportingpage' */ '../ReportingPage/ReportingPage'
        ),
);

const ReportAreaPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'reportareapage' */ '../ReportingAreaPage/ReportingAreaPage'
        ),
);

const getReportingArea = (name: string): ReportingArea | undefined => {
    // Grab the report name without the tenant id prefixed to it
    const reportName = name.split('-')[1];

    if (reportName.startsWith('LEARNING')) {
        return ReportingArea.Learning;
    }
    if (reportName.startsWith('TEACHING')) {
        return ReportingArea.Teaching;
    }
    if (reportName.startsWith('LEADING')) {
        return ReportingArea.Leading;
    }
};

const filterByReportingArea = (
    area: ReportingArea,
    reports?: IQuickSightReport[],
) => reports?.filter(report => report.reportingArea === area) ?? [];

const mapTenantStatus = (apiResponse: string) => {
    if (
        apiResponse === TenantStatusApiValue.Asleep ||
        apiResponse === TenantStatusApiValue.DeprovisioningRequested ||
        apiResponse === TenantStatusApiValue.InDeprovisioning
    ) {
        return TenantStatus.Asleep;
    }
    if (
        apiResponse === TenantStatusApiValue.ProvisioningRequested ||
        apiResponse === TenantStatusApiValue.InProvisioning
    ) {
        return TenantStatus.Provisioning;
    }
    return TenantStatus.Awake;
};

export const ReportingContent: React.FC<{
    navigationIndex: number;
}> = props => {
    // QuickSight Dashboards loading state
    const [quickSightDashboardsLoading, setQuickSightDashboardsLoading] =
        React.useState<boolean>();
    const featureFlags = useFeatureContext();
    const user = useUser();
    const [qsReports, setQsReports] = React.useState<IQuickSightReport[]>();
    const { userSelectedLang } = useI18nContext();
    const { accessToken } = useAuth0Context();
    const [userHasQSSeat, setUserHasQSSeat] = React.useState<boolean>(true);
    const [tenantStatus, setTenantStatus] = React.useState<TenantStatus>();
    const apiAttemptCount = React.useRef<number>(0);

    const { api, featureFlags: configFeatureFlags } = useAppConfigContext();

    const access = useAccessControl();

    const filteredByFeatureFlagReports = React.useMemo(
        () =>
            qsReports?.filter(
                report =>
                    !report.featureToggleName ||
                    featureFlags[report.featureToggleName],
            ),
        [featureFlags, qsReports],
    );

    const { setLoadingFlag } = useAppLoadingContext();
    React.useEffect(() => {
        setLoadingFlag('reporting.init', true);
    }, [setLoadingFlag]);

    React.useEffect(() => {
        if (qsReports !== undefined) {
            setLoadingFlag('reporting.init', false);
        }
    }, [qsReports, setLoadingFlag]);

    React.useEffect(() => {
        // Authorization checks:
        // For new reporting areas feature, must have reporting access to proceed.
        if (
            quickSightDashboardsLoading ||
            qsReports?.length ||
            apiAttemptCount.current > 2
        ) {
            return;
        }

        if (!access['reporting/reports'].ok) {
            setQuickSightDashboardsLoading(false);
            if (qsReports === undefined) {
                // don't create new object as it re-triggers the hook
                setQsReports([]);
            }
            return;
        }
        setQuickSightDashboardsLoading(true);
        apiAttemptCount.current += 1;

        // Get the tenant status to set the TenantStatus state to the appropriate value
        if (
            configFeatureFlags?.useTenantStatus &&
            api?.quicksightTenantStatus
        ) {
            axios
                .get(
                    encodeURI(
                        `${
                            api!.quicksightTenantStatus
                        }/v1/data/tenants/${user?.tenantIdentifier}/quicksight/sleep-status`,
                    ),
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                )
                .then(response => {
                    const value = mapTenantStatus(response.data.status);
                    setTenantStatus(value);
                })
                .catch(() => {
                    setTenantStatus(TenantStatus.Awake);
                    console.error('Failed to get tenant status');
                });
        } else {
            setTenantStatus(TenantStatus.Awake);
        }

        // using axios to be able to conditionally make the /dashboards api call
        // when trying to use the usApi hook and execute the request manually, the useEffect falls into an infinate loop when execute is set as a dependency
        // hoping this approach will suffice for now
        axios
            .get(
                encodeURI(
                    `${
                        api!.quicksightDashboards
                    }/v1/data/dashboards/tenants/${user?.tenantIdentifier}/users/${user?.userId}/dashboards`,
                ),
                {
                    params: {
                        locale: getReportApiLanguage(userSelectedLang),
                    },
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            )
            .then(response => {
                if (response.data) {
                    const availableQuickSightReports: IQuickSightReport[] =
                        response.data.results
                            .map((qsDashboard: IQuickSightDashboard) => ({
                                ...qsDashboard,
                                helpLink: qsDashboard.helpLink,
                                featureToggleName:
                                    qsDashboard.featureFlag as FeatureName,
                            }))
                            .map((qsDashboard: IQuickSightDashboard) => ({
                                ...qsDashboard,
                                reportingArea: getReportingArea(
                                    qsDashboard.name,
                                ),
                            }))
                            .filter(
                                (report: IQuickSightReport) =>
                                    report.locale && report.reportingArea,
                            );

                    setQsReports(
                        availableQuickSightReports.filter(
                            dashboard =>
                                dashboard.reportingArea ===
                                    ReportingArea.Learning ||
                                dashboard.reportingArea ===
                                    ReportingArea.Teaching ||
                                dashboard.reportingArea ===
                                    ReportingArea.Leading,
                        ),
                    );

                    setQuickSightDashboardsLoading(false);
                }
            })
            .catch(reportError => {
                if (reportError?.response) {
                    if (
                        reportError.response.data?.details?.includes(
                            'reader_qs_seats_quota_exceeded',
                        )
                    ) {
                        setUserHasQSSeat(false);
                        console.error('User does not have a QS seat');
                    }
                }
                setQuickSightDashboardsLoading(false);
                setQsReports([]);
                console.error('Failed to fetch QuickSight Dashboards');
            });
    }, [
        accessToken,
        user,
        configFeatureFlags,
        api,
        userSelectedLang,
        userHasQSSeat,
        access,
        quickSightDashboardsLoading,
        qsReports,
    ]);

    const learningReports = React.useMemo(
        () =>
            filterByReportingArea(
                ReportingArea.Learning,
                filteredByFeatureFlagReports,
            ),
        [filteredByFeatureFlagReports],
    );
    const teachingReports = React.useMemo(
        () =>
            filterByReportingArea(
                ReportingArea.Teaching,
                filteredByFeatureFlagReports,
            ),
        [filteredByFeatureFlagReports],
    );
    const leadingReports = React.useMemo(
        () =>
            filterByReportingArea(
                ReportingArea.Leading,
                filteredByFeatureFlagReports,
            ),
        [filteredByFeatureFlagReports],
    );

    const dashboardToReportType = React.useMemo(
        () => ({
            [ReportingArea.Learning]: {
                reports: learningReports,
                navLabel: 'reporting.cards.learning.label',
                icon: <GraduateCap />,
            },
            [ReportingArea.Teaching]: {
                reports: teachingReports,
                navLabel: 'reporting.cards.teaching.label',
                icon: <Lightbulb />,
            },
            [ReportingArea.Leading]: {
                reports: leadingReports,
                navLabel: 'reporting.cards.leading.label',
                icon: <Grade />,
            },
        }),
        [learningReports, teachingReports, leadingReports],
    );

    const wakeUpReports = () => {
        // The tenant status API is configured to only process Wake Up requests when the tenant is asleep and
        // sleep-status parameter in the API call is set to "awake"
        if (configFeatureFlags?.useTenantStatus) {
            axios
                .put(
                    encodeURI(
                        `${
                            api!.quicksightTenantStatus
                        }/v1/data/tenants/${user?.tenantIdentifier}/quicksight/sleep-status/${
                            TenantStatusApiValue.Awake
                        }`,
                    ),
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                )
                .then(() => {
                    setTenantStatus(TenantStatus.Provisioning);
                })
                .catch(() => {
                    console.error('Failed to update tenant status');
                });
        }
    };

    const { t } = useTranslation();
    let secondaryLevelIndex = 0;

    return (
        <NavigationRoutedLink
            key="reporting"
            path="reporting"
            exact
            label={t('reporting.title')}
            icon={<ReportIcon />}
            index={props.navigationIndex}
            access={access.reporting}
        >
            <NavigationRouted>
                <ReportingPage
                    userHasReportingAccess={user?.hasReportAccess()}
                    tenantStatus={tenantStatus}
                    wakeUpReports={wakeUpReports}
                    userHasQSSeat={userHasQSSeat}
                    dashboardToReportType={dashboardToReportType}
                />
            </NavigationRouted>

            {Object.entries(dashboardToReportType).map(
                ([k, v]) =>
                    !!v.reports.length && (
                        <NavigationRoutedLink
                            key={k.toLowerCase()}
                            path={k.toLowerCase()}
                            exact={false}
                            label={t(v.navLabel)}
                            icon={v.icon}
                            index={++secondaryLevelIndex}
                            access={access['reporting/reports']}
                            hideSecondaryNavigation
                        >
                            <NavigationRouted key={k}>
                                {tenantStatus === TenantStatus.Asleep ||
                                tenantStatus === TenantStatus.Provisioning ? (
                                    <ReportAreaPage
                                        reportingArea={k}
                                        quickSightDashboards={v.reports}
                                        wakeUpReports={wakeUpReports}
                                        tenantStatus={tenantStatus}
                                    />
                                ) : (
                                    <ReportAreaPage
                                        reportingArea={k}
                                        quickSightDashboards={v.reports}
                                    />
                                )}
                            </NavigationRouted>

                            {v.reports.map((report, i) => (
                                <NavigationRoutedLink
                                    key={i}
                                    index={i + 1}
                                    label={report.locale.reportTitle}
                                    testId={`secondary-nav-${report.urlSlug}-link`}
                                    path={report.urlSlug}
                                >
                                    <NavigationRouted>
                                        <Report
                                            quickSightReport={report}
                                            loadingQuickSightDashboards={
                                                quickSightDashboardsLoading
                                            }
                                        />
                                    </NavigationRouted>
                                </NavigationRoutedLink>
                            ))}
                        </NavigationRoutedLink>
                    ),
            )}

            <NavigationRoutedLink
                key="data-q-and-a"
                path="data-q-and-a"
                label="Data Q&A"
                icon={<HandRaise />}
                keepEnglishLabel
                index={++secondaryLevelIndex}
                access={access['reporting/dataQA']}
            >
                <NavigationRouted>
                    <DataQAndA />
                </NavigationRouted>
            </NavigationRoutedLink>

            <NavigationRoutedLink
                key="custom-reports"
                path="custom-reports"
                label={t('authoring.title')}
                icon={<FolderClosed />}
                index={++secondaryLevelIndex}
                access={access.authenticated}
            >
                <NavigationRouted>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {access['reporting/custom-reports'].ok ? (
                        <CustomReports />
                    ) : access['reporting/custom-reports/enabled'].ok ? (
                        <CustomReportsUnauthorized />
                    ) : (
                        <CustomReportsTeaser />
                    )}
                </NavigationRouted>
            </NavigationRoutedLink>
        </NavigationRoutedLink>
    );
};
