import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { ListItemIcon } from '@bb-ui/react-library/dist/components/ListItemIcon';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';

import { useStyles as useListNavItemStyles } from './ListNavItem.styles';
import { IListItemRouteLinkProps } from './ListItemRouteLink.types';
import { ListNavBadge } from './ListNavBadge';
import { UnavailableTooltip } from '../RequiresApi/UnavailableTooltip';

// would like this to not be an any type but @bb-ui currently is not exporting ListItemProps
const NavListItem = ListItem as any;

// eslint-disable-next-line
const RenderLink = React.forwardRef(
    (itemProps: NavLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
        <NavLink
            activeClassName="active"
            onKeyPress={e => {
                // Simulates button behavior (being pressable with spacebar)
                if (e.key === ' ') {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.click();
                }
            }}
            {...itemProps}
            ref={ref}
        />
    ),
);

// This component is designed to be used within a `List` component used for navigation
// as used in the `ListNav` component
export const ListItemRouteLink: React.FC<IListItemRouteLinkProps> = props => {
    const {
        path,
        exact,
        label,
        icon,
        onClick,
        disableListItem,
        subtitleStyle,
        testId,
        keepEnglishLabel,
        badgeType,
        isAvailableInRegion,
    } = props;

    const clickDisabledHoverActive = !isAvailableInRegion;
    const listNavItemClasses = useListNavItemStyles({
        listNavVariant: props.listNavVariant,
        forceEnableHover: clickDisabledHoverActive,
    });

    return (
        <UnavailableTooltip placement="right" show={!isAvailableInRegion}>
            <NavListItem
                classes={listNavItemClasses}
                button
                // This removes the button role
                role={undefined}
                component={RenderLink}
                disabled={disableListItem || !isAvailableInRegion}
                to={path}
                exact={exact}
                onClick={
                    clickDisabledHoverActive
                        ? (e: any) => {
                              e.preventDefault();
                          }
                        : onClick
                }
                data-testid={testId}
            >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="subtitle2"
                            component="span"
                            style={subtitleStyle}
                            lang={keepEnglishLabel ? 'en' : undefined}
                        >
                            {label}
                        </Typography>
                    }
                />

                {badgeType && <ListNavBadge />}
            </NavListItem>
        </UnavailableTooltip>
    );
};
