// note that this file is mostly copied over from bb-ui
// with slight changes to be able to tell which UAS/incr auth region auth's coming from

// This manages whether the user is logged in via Auth0, and the means through
// which this can happen. Right now only logging in with a full browser is
// redirect is supported, not a popup on the page.
//
// See https://auth0.com/docs/quickstart/spa/react for the rough outlines of the
// Auth0-related parts of this implementation.

import * as React from 'react';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import createAuth0Client, {
    Auth0ClientOptions,
    getIdTokenClaimsOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    LogoutOptions,
    RedirectLoginOptions,
    User,
} from '@auth0/auth0-spa-js';
import { useLocalStorage } from 'react-use';
import {
    AuthProviderCacheProps,
    useAuthProviderCache,
} from './useAuthProviderCache';
import { localStorageUASTenantKey } from '../../utilities/constants';

// Props that the context provider needs. Most of these come directly from the
// Auth0 tenant's web admin, and are specific to an Auth0 application.
//
// Some of these properties are snake-cased to match the Auth0 API.

export interface Auth0ProviderProps extends Auth0ClientOptions {
    /**
     * A namespaced replacement for how login_hint was used to indicate
     * the Foundations tenant.
     */
    bb_login_params?: string;

    initiateLoginWithRedirect?: (
        options: AuthProviderCacheProps,
    ) => Promise<void>;
}

export interface Auth0ConsumerProps {
    /**
     * Result of `Auth0Client.getAccessToken()`.
     */
    accessToken?: string;
    /**
     * Saved state passed in a login call, returned after the user successfully
     * logs in.
     */
    appState?: any;
    /**
     * Is the user current logged in?
     */
    isAuthenticated: boolean;
    /**
     * Are we currently checking the login state?
     */
    loading: boolean;
    /**
     * Result of `Auth0Client.getUser()`.
     */
    user?: any;
    /**
     * Any error that might have resulted from checking login status.
     */
    error?: Error;

    // Auth0-related methods. Most consumers will only need the login and logout
    // functions.

    getIdTokenClaims?: (
        options?: getIdTokenClaimsOptions,
    ) => ReturnType<Auth0Client['getIdTokenClaims']>;
    getTokenSilently?: (
        options?: GetTokenSilentlyOptions,
    ) => ReturnType<Auth0Client['getTokenSilently']>;
    getTokenWithPopup?: (
        options?: GetTokenWithPopupOptions,
    ) => ReturnType<Auth0Client['getTokenWithPopup']>;
    handleRedirectCallback?: () => ReturnType<
        Auth0Client['handleRedirectCallback']
    >;
    login?: (
        options?: RedirectLoginOptions,
    ) => ReturnType<Auth0Client['loginWithRedirect']>;
    logout?: (options?: LogoutOptions) => ReturnType<Auth0Client['logout']>;

    initiateLoginWithRedirect?: (
        options: AuthProviderCacheProps,
    ) => Promise<void>;
}

// Default context values for testing.

export const Auth0Context = React.createContext<Auth0ConsumerProps>({
    isAuthenticated: false,
    loading: true,
});

/**
 * A React hook to access Auth0-related information. You should use this instead
 * of a `<Auth0Consumer>` component if you are ready to use hooks in your
 * application.
 */
export const useAuth0Context = () => React.useContext(Auth0Context);
export const Auth0Consumer = Auth0Context.Consumer;

export const Auth0Provider: React.FunctionComponent<
    Auth0ProviderProps
> = props => {
    const { children, initiateLoginWithRedirect, ...auth0ConsumerProps } =
        props;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
        audience,
        bb_login_params: bbLoginParams,
        client_id: clientId,
        domain,
        login_hint: loginHint,
        redirect_uri: redirectUri,
    }: Auth0ClientOptions & { bb_login_params?: string } = auth0ConsumerProps;

    const { setCache } = useAuthProviderCache();
    // backwards compatibility
    const [_, setUasTenant] = useLocalStorage<string>(
        localStorageUASTenantKey,
        '',
        {
            raw: true,
        },
    );

    const [appState, setAppState] = React.useState<any>();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [auth0Client, setAuth0Client] = React.useState<Auth0Client>();
    const [accessToken, setAccessToken] = React.useState<string>();
    const [user, setUser] = React.useState<User | undefined>();
    const [error, setError] = React.useState<Error>();

    React.useEffect(() => {
        async function init() {
            // Prevent multiple invocations.

            if (auth0Client) {
                return;
            }

            setLoading(true);

            // Create our Auth0 client. This is saved as a local variable for later
            // use in this function.
            const newAuth0Client = await createAuth0Client({
                ...auth0ConsumerProps,
            });

            setAuth0Client(newAuth0Client);

            // If we just returned from a successful Auth0 login, we will have code
            // and state query params that need to be parsed. See
            // https://auth0.com/docs/quickstart/spa/vanillajs#log-in-to-the-application
            if (window.location.search.includes('code=')) {
                const { appState } =
                    await newAuth0Client.handleRedirectCallback();

                // Remove the query params from view.

                window.history.replaceState(
                    {},
                    document.title,
                    window.location.pathname,
                );

                // It is up to consumers to do something with this appState -- it is
                // for application-specific use. See Auth0CallbackHandler.
                setAppState(appState);
            }

            const isAuthenticated = await newAuth0Client!.isAuthenticated();
            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await newAuth0Client!.getUser();
                const token = await newAuth0Client!.getTokenSilently();

                setUser(user);
                setAccessToken(token);
            }

            // We're done.

            setLoading(false);
        }

        // Kick off init but don't wait for it to finish.

        if (audience && clientId && domain && redirectUri) {
            init().catch(error => {
                setLoading(false);
                setError(error);
            });
        }
    }, [
        audience,
        auth0Client,
        clientId,
        domain,
        redirectUri,
        auth0ConsumerProps,
    ]);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const context = React.useMemo(() => {
        const context: Auth0ConsumerProps & { bb_login_params?: string } = {
            appState,
            accessToken,
            isAuthenticated,
            loading,
            user,
            error,
            initiateLoginWithRedirect,
        };

        if (auth0Client) {
            // Passthroughs to the Auth0 client.
            context.getIdTokenClaims = (...args) =>
                auth0Client.getIdTokenClaims(...args);
            context.getTokenSilently = (...args) =>
                auth0Client.getTokenSilently(...args);
            context.getTokenWithPopup = (...args) =>
                auth0Client.getTokenWithPopup(...args);
            context.handleRedirectCallback = (...args) =>
                auth0Client.handleRedirectCallback(...args);
            context.logout = (options?: LogoutOptions) => {
                setCache(undefined);
                setUasTenant('');
                return auth0Client.logout({
                    returnTo: window.location.href,
                    ...options,
                });
            };
            context.login = (options?: RedirectLoginOptions) => {
                if (audience === undefined) {
                    throw new Error('Attempting to log in without audience.');
                }
                setCache({
                    audience,
                    bb_login_params: bbLoginParams,
                    client_id: clientId,
                    domain,
                });
                setUasTenant('1');
                return auth0Client.loginWithRedirect({
                    audience,
                    bb_login_params: bbLoginParams,
                    login_hint: loginHint,
                    appState: { returnPath: window.location.pathname },
                    ...options,
                });
            };
        }
        return context;
    }, [
        accessToken,
        appState,
        audience,
        auth0Client,
        bbLoginParams,
        clientId,
        domain,
        error,
        initiateLoginWithRedirect,
        isAuthenticated,
        loading,
        loginHint,
        setCache,
        setUasTenant,
        user,
    ]);

    return (
        <Auth0Context.Provider value={context}>
            {children}
        </Auth0Context.Provider>
    );
};
