import { ArrowRight } from '@bb-ui/icons/dist/small/ArrowRight';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppNavigationContext } from '../../contexts/AppNavigationContext';
import { useStyles } from './DeveloperPage.styles';
import { DeveloperPageCard } from './DeveloperPageCard';

import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { useUser } from '../../hooks/useUser';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { SnowflakeLauncher } from '../SnowflakeLauncher/SnowflakeLauncher';
import { DeveloperPageHero } from './DeveloperPageHero';
import { useAccessControl } from '../../hooks/useAccessControl';

export const DeveloperPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openSignInModal } = useAppNavigationContext();
    const getHelpLink = useHelpLinks();
    const user = useUser();
    const isAuthenticated = user !== null;
    const access = useAccessControl();
    // This does not get localized
    useApplicationTitle('Developer');

    const theme = useTheme();

    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdBreakpoint = useMediaQuery(theme.breakpoints.only('md'));

    const { openNavDrawer } = useAppNavigationContext();

    const bannerOrientation =
        smDown || (mdBreakpoint && openNavDrawer === 'global')
            ? 'vertical'
            : 'horizontal';

    const dataDictionaryCardId = 'data-dictionary';
    const manageSnowflakeCardId = 'manage-snowflake';
    const dataExplorersCardId = 'data-explorers';
    const directDataAccessCardId = 'direct-data-access';

    const dataDictionaryCard = (
        <DeveloperPageCard
            id={dataDictionaryCardId}
            title={t('developer.cards.dataDictionary.title')}
            link={{
                text: t('developer.cards.dataDictionary.linkText'),
                to: '/dictionary',
            }}
        >
            <Typography
                variant="body1"
                data-testid={`developer-page-${dataDictionaryCardId}-card-text`}
            >
                { /* prettier-ignore */ }
                <Trans i18nKey='developer.cards.dataDictionary.text'>
                    Explore the data dictionary to learn about the structure and content of the <span lang="en">Anthology Illuminate</span> model.
                </Trans>
            </Typography>
        </DeveloperPageCard>
    );

    const manageSnowflakeCard = (
        <DeveloperPageCard
            id={manageSnowflakeCardId}
            title={
                /* prettier-ignore */
                <Trans i18nKey="developer.cards.manageSnowflake.title">
                    Manage your <span lang="en">Snowflake</span> service accounts
                </Trans>
            }
            linkReplacement={
                !access['developer/settings'].ok ? (
                    <IconButton
                        className={classes.linkButton}
                        onClick={openSignInModal}
                    >
                        <Typography
                            component="span"
                            variant="subtitle2"
                            className={classes.linkButtonText}
                        >
                            {t(
                                'developer.cards.manageSnowflake.linkTextUnauthenticated',
                            )}
                        </Typography>
                        <ArrowRight role="presentation" />
                    </IconButton>
                ) : undefined
            }
            link={
                isAuthenticated
                    ? {
                          text: t(
                              'developer.cards.manageSnowflake.linkTextAuthenticated',
                          ),
                          to: '/settings',
                      }
                    : undefined
            }
        >
            <ul className={classes.unorderedList}>
                <Typography component="li" variant="body1">
                    {t('developer.cards.manageSnowflake.text1')}
                </Typography>
                <Typography component="li" variant="body1">
                    {t('developer.cards.manageSnowflake.text2')}
                </Typography>
                <Typography component="li" variant="body1">
                    {t('developer.cards.manageSnowflake.text3')}
                </Typography>
            </ul>
        </DeveloperPageCard>
    );

    const dataExplorersCard = (
        <DeveloperPageCard
            id={dataExplorersCardId}
            title={
                /* prettier-ignore */
                <Trans i18nKey="developer.cards.dataExplorers.title">
                    Grow your <span lang="en">SQL</span> skills and more
                </Trans>
            }
            link={{
                text:
                    /* prettier-ignore */
                    <Trans i18nKey="developer.cards.dataExplorers.linkText">
                        Go to <span lang="en">Blackboard Data Explorers</span>
                    </Trans>,
                to: 'https://data-explorers.blackboard.com',
                isExternal: true,
            }}
            image={{
                url: '/images/DeveloperPage/dataExplorersIllustration.svg',
                position: 'left',
                objectPosition: 'left bottom',
            }}
        >
            <Typography data-testid="developer-cards-data-explorers-text-heading">
                {/* prettier-ignore */}
                <Trans i18nKey="developer.cards.dataExplorers.text1">
                    <span lang="en" className={classes.explorersName}>Blackboard Data Explorers</span> is an interactive course designed to:
                </Trans>
            </Typography>
            <ol className={classes.orderedList}>
                <Typography
                    component="li"
                    data-testid={`developer-page-${dataExplorersCardId}-card-text1`}
                >
                    {/* prettier-ignore */}
                    <Trans i18nKey="developer.cards.dataExplorers.text2">
                        Get those without any <span lang="en">SQL</span> skill to make use of <span lang="en">Anthology Illuminate</span> Developer.
                    </Trans>
                </Typography>
                <Typography
                    component="li"
                    data-testid={`developer-page-${dataExplorersCardId}-card-text2`}
                >
                    {/* prettier-ignore */}
                    <Trans i18nKey="developer.cards.dataExplorers.text3">
                        Enable <span lang="en">SQL</span>-skilled developers to apply institutional context to the data.
                    </Trans>
                </Typography>
            </ol>
        </DeveloperPageCard>
    );

    const directDataAccessCard = (
        <DeveloperPageCard
            id={directDataAccessCardId}
            title={
                /* prettier-ignore */
                <Trans i18nKey="developer.cards.directDataAccess.title">
                    What about <span lang="en">Direct Data Access (DDA)</span>?
                </Trans>
            }
            link={{
                text:
                    /* prettier-ignore */
                    <Trans i18nKey="developer.cards.directDataAccess.linkText">
                        Learn more about <span lang="en">DDA</span>
                    </Trans>,
                to: getHelpLink('directDataAccess'),
                isExternal: true,
            }}
            image={{
                url: '/images/DeveloperPage/DDAIllustration.svg',
                position: 'right',
                objectPosition: 'right bottom',
            }}
        >
            <Typography
                data-testid={`developer-page-${directDataAccessCardId}-card-text1`}
            >
                {/* prettier-ignore */}
                <Trans i18nKey="developer.cards.directDataAccess.text1">
                    <span lang="en">DDA</span> is a copy of your institution's <span lang="en">Blackboard Learn</span> transactional database. It's included with some packages of <span lang="en">Learn SaaS</span> and available as an additional purchase for others.
                </Trans>
            </Typography>
            <Typography
                data-testid={`developer-page-${directDataAccessCardId}-card-text2`}
            >
                {/* prettier-ignore */}
                <Trans i18nKey="developer.cards.directDataAccess.text2">
                    <span lang="en">DDA</span> and <span lang="en">Anthology Illuminate</span> Developer differ in their source, structure and freshness.
                </Trans>
            </Typography>
        </DeveloperPageCard>
    );

    const accessInstitutionalDataCardWhenNotAuthorized = (
        <DeveloperPageCard
            id="access-institutional-data"
            title={t('developer.cards.accessInstitutionalData.title')}
            link={{
                text: t('developer.getDeveloper'),
                to: getHelpLink('getReportingHelp'),
                isExternal: true,
            }}
        >
            <Typography data-testid="developer-page-access-institution-data-text-when-not-authorized">
                { /* prettier-ignore */ }
                <Trans i18nKey="developer.cards.accessInstitutionalData.textWhenNotAuthorized">
                    <span lang="en">Anthology Illuminate</span> Developer gives you access to your institutional data through <span lang="en">Snowflake</span>. With <span lang="en">Snowflake</span> you can run <span lang="en">SQL</span> queries and analyses based on your institutional needs.
                </Trans>
            </Typography>
        </DeveloperPageCard>
    );

    const accessInstitutionalDataCardWhenAuthorized = (
        <DeveloperPageCard
            id="access-institutional-data"
            title={t('developer.cards.accessInstitutionalData.title')}
            linkReplacement={
                <Link
                    href={getHelpLink('getReportingHelp')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('developer.cards.accessInstitutionalData.linkText')}
                </Link>
            }
        >
            <SnowflakeLauncher />
        </DeveloperPageCard>
    );

    const getCards = () => {
        const cards = [dataDictionaryCard];

        if (isAuthenticated) {
            if (access['developer/launch-snowflake'].ok) {
                // Put this card at the beginning
                cards.unshift(accessInstitutionalDataCardWhenAuthorized);
            } else {
                // Put this card at the beginning
                cards.unshift(accessInstitutionalDataCardWhenNotAuthorized);
            }
            if (access['developer/settings'].ok) {
                cards.push(manageSnowflakeCard);
            }
        } else {
            cards.push(manageSnowflakeCard);
        }

        if (cards.length === 2) {
            return <div className={classes.twoCards}>{...cards}</div>;
        }

        if (cards.length === 3) {
            return <div className={classes.threeCards}>{...cards}</div>;
        }
    };

    return (
        <ResponsiveBox
            className={classes.developerPageContainer}
            data-testid="developer-page-container"
        >
            <div className={classes.gridContainer}>
                <DeveloperPageHero orientation={bannerOrientation} />
                {getCards()}
                {dataExplorersCard}
                {directDataAccessCard}
            </div>
            <ActionAreaButtons helpLink={getHelpLink('developer')} />
        </ResponsiveBox>
    );
};
