import {
    Box,
    Breadcrumbs,
    BreadcrumbsItem,
    DefaultButton,
    Divider,
    PrimaryButton,
} from '@bb-ui/react-library';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useAuth0Context } from '../../../auth/auth0/Auth0Context';
import { useAppConfigContext } from '../../../contexts/AppConfigProvider';
import { useUser } from '../../../hooks/useUser';
import { getTenantId } from '../../../utilities/utilities';
import { useApplicationTitle } from '../../../hooks/useApplicationTitle';
import { useHelpLinks } from '../../../hooks/useHelpLinks';
import { useApi } from '../../../hooks/useApi';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';
import { ResponsiveBox } from '../../ResponsiveBox/ResponsiveBox';
import { MainHeader } from '../../MainHeader/MainHeader';
import { Frame } from '../../Frame/Frame';
import { UserInfo } from './UserInfoContainer/UserInfoContainer';
import { UserRoles } from './UserRoles/UserRoles';
import { ActionAreaButtons } from '../../ActionAreaButtons/ActionAreaButtons';
import { useStyles } from './EditUserPage.styles';
import { IHNodes } from './IHNodes/IHNodes';
import { EditUserError } from './EditUserError/EditUserError';
import { QSrole } from '../QSSeats.types';

export interface IUser {
    username: string;
    userId: string;
    role: QSrole;
    isActive: boolean;
}

export interface IUserResult {
    results: IUser[];
}

export const EditUserPage: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const {
        uas,
        api,
        featureFlags: configFeatureFlags,
    } = useAppConfigContext();

    const bbUser = useUser();
    const { user: auth0ContextUser } = useAuth0Context();
    const tenantId = getTenantId(auth0ContextUser, uas);

    const uasUserIsAdministrator = bbUser?.hasAdminAccess();

    const userIsSignedInWithIncAuth = bbUser?.isIncrementalUser();
    const userHasEditUserPageAccess =
        configFeatureFlags?.settingsEnabled &&
        (uasUserIsAdministrator || userIsSignedInWithIncAuth) &&
        !!api?.quicksightDashboards;

    if (!userHasEditUserPageAccess) {
        history.push('/settings/');
    }

    useApplicationTitle(t('settings.editUser.title'));

    const getHelpLink = useHelpLinks();

    const { id: userId } = useParams() as {
        id: string;
    };

    const [saveClicked, setSaveClicked] = useState<boolean>(false);
    const [isChildLoading, setIsChildLoading] = useState<boolean>(false);
    const [childHasError, setChildHasError] = useState<boolean>(false);
    const [userNotFound, setUserNotFound] = useState<boolean>(false);

    // TODO: create a more generalized way to handle n-number of child components
    const [isIHNodesLoading, setIsIHNodesLoading] = useState<boolean>(false);

    const [selectedUser, setSelectedUser] = useState<IUser | undefined>(
        undefined,
    );

    // TODO: create a new endpoint that returns just the one user, instead of all users
    const getUsersUrl = useMemo(
        () =>
            encodeURI(
                `${api!.quicksightDashboards}/v1/data/dashboards/tenants/${tenantId}/users`,
            ),
        [api, tenantId],
    );

    const {
        data: usersData,
        error: usersDataError,
        loading: usersDataLoading,
    } = useApi<IUserResult>({
        url: getUsersUrl,
        useAuth: true,
        method: 'GET',
    });

    useEffect(() => {
        if (usersData) {
            const user = usersData.results.find(
                user => user.username === userId,
            );
            if (user) {
                setSelectedUser(user);
            } else {
                setUserNotFound(true);
            }
        }
    }, [userId, usersData]);

    const handleChildError = () => {
        setChildHasError(true);
    };

    const handleIHNodesLoading = (loading: boolean) => {
        setIsIHNodesLoading(loading);
    };

    useEffect(() => {
        setIsChildLoading(isIHNodesLoading);
    }, [isIHNodesLoading]);

    useEffect(() => {
        if (usersDataError) {
            setUserNotFound(true);
        }
    }, [usersDataError]);

    const handleSave = () => {
        setSaveClicked(prev => !prev);
    };

    const handleExitClick = (): undefined => {
        history.push(`/settings/`);
        return undefined;
    };

    const initialLoader = (
        <div className={classes.loadingIndicator}>
            <LoadingIndicator
                label={t('settings.editUser.loadingUser')}
                id="user-data-loading-indicator"
            />
        </div>
    );

    const renderError = () => (
        <EditUserError error="An error occurred while loading the user data." />
    );

    return (
        <>
            {userHasEditUserPageAccess && (
                <>
                    <ResponsiveBox className={classes.mainBox}>
                        <Breadcrumbs
                            menuButtonLabel={t(
                                'settings.editUser.breadCrumbs.breadCrumbMenuButtonAriaLabel',
                            )}
                            menuId="menu-id-1"
                            navigationLabel={t(
                                'settings.editUser.breadCrumbs.breadCrumbAriaLabel',
                            )}
                            isCurrentLinkActive={false}
                            data-testid="edit-users-breadcrumbs"
                        >
                            <BreadcrumbsItem label="" isCurrentPage={false}>
                                <a
                                    data-testid="edit-users-breadcrumbs-back-link"
                                    onClick={handleExitClick}
                                    className={classes.breadcrumbLink}
                                >
                                    {t(
                                        'settings.editUser.breadCrumbs.userManagementBreadcrumb',
                                    )}
                                </a>
                            </BreadcrumbsItem>
                            <BreadcrumbsItem label="" isCurrentPage={true}>
                                <a href="#">{t('settings.editUser.title')}</a>
                            </BreadcrumbsItem>
                        </Breadcrumbs>
                    </ResponsiveBox>
                    <MainHeader title={t('settings.editUser.title')} />
                    <ResponsiveBox
                        className={classes.mainBox}
                        dataTestId="edit-user-page-main-box"
                    >
                        <Frame className={classes.frameReset}>
                            {usersDataLoading
                                ? initialLoader
                                : !usersDataError &&
                                  selectedUser && (
                                      <>
                                          <UserInfo
                                              userName={selectedUser.username}
                                              role={selectedUser.role}
                                          />
                                          <Divider />
                                          {/* TODO: integrate save, loading, error functionality in UserRoles component */}
                                          <UserRoles
                                              userId={selectedUser.userId}
                                          />
                                          <Divider />
                                          <IHNodes
                                              userId={selectedUser.userId}
                                              onSave={saveClicked}
                                              onLoading={handleIHNodesLoading}
                                              onError={handleChildError}
                                          />
                                          <Box
                                              display="flex"
                                              flexDirection="row"
                                              className={
                                                  classes.buttonContainer
                                              }
                                          >
                                              <DefaultButton
                                                  className={
                                                      classes.cancelButton
                                                  }
                                                  onClick={handleExitClick}
                                                  data-testid="edit-user-cancel-button"
                                              >
                                                  {t(
                                                      'settings.editUser.cancelButtonText',
                                                  )}
                                              </DefaultButton>
                                              <PrimaryButton
                                                  className={classes.saveButton}
                                                  onClick={handleSave}
                                                  disabled={
                                                      isChildLoading ||
                                                      childHasError
                                                  }
                                                  data-testid="edit-user-save-button"
                                              >
                                                  {t(
                                                      'settings.editUser.saveButtonText',
                                                  )}
                                              </PrimaryButton>
                                          </Box>
                                      </>
                                  )}
                            {userNotFound && renderError()}
                        </Frame>
                        <ActionAreaButtons helpLink={getHelpLink('roles')} />
                    </ResponsiveBox>
                </>
            )}
        </>
    );
};
