import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0Context } from './Auth0Context';

export const Auth0CallbackHandler = () => {
    const { push } = useHistory();
    const { loading, appState } = useAuth0Context();
    const redirected = React.useRef(false);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        if (!appState && !loading) {
            setError(true);
        }
        if (appState && !loading && !redirected.current) {
            push('/');
            redirected.current = true;
        }
    }, [loading, appState, push]);

    if (error) {
        push('/');
        throw new Error('Invalid login attempt - no appState found');
    }

    return <></>;
};
