import React from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useTranslation, Trans } from 'react-i18next';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { CustomCardPrimary } from '../CustomCards/CustomCardPrimary';
import { illuminateColors } from '../../utilities/sharedStyles';
import { OutlineLink } from '../StyledLinks/OutlineLink';
import { PrimaryLink } from '../StyledLinks/PrimaryLink';
import { StyledItem } from '../CustomCards/StyledItem';
import { CustomCardSecondary } from '../CustomCards/CustomCardSecondary';
import { useStyles } from './HomePage.styles';

export const HomePageNotAuthenticated = () => {
    const { t } = useTranslation();
    const classes: any = useStyles();
    const getHelpLink = useHelpLinks();

    return (
        <>
            <CustomCardPrimary
                id="what-is-illuminate"
                title={
                    <Typography variant="display2" component="h1">
                        <Trans i18nKey="homePage.cards.unauthenticated.title">
                            See campus-wide data clearly with
                            <span lang="en">Anthology Illuminate</span>
                        </Trans>
                    </Typography>
                }
                imgSrc="/images/HomePage/whatIsIlluminate.svg"
                className={classes.singleRow}
                loadingImageColor={illuminateColors.brandYellowLight}
                cardActions={
                    <div className={classes.links}>
                        <OutlineLink
                            to={getHelpLink('getReportingHelp')}
                            data-testid="home-page-get-access-button"
                            external
                        >
                            {t('homePage.cards.unauthenticated.getAccess')}
                        </OutlineLink>
                        <PrimaryLink
                            to={getHelpLink('aboutIlluminate')}
                            data-testid="more-about-illuminate-button"
                        >
                            { /* prettier-ignore */ }
                            <Trans i18nKey="homePage.cards.unauthenticated.findOutMore">
                                    Find out more about <span lang="en">Illuminate</span>
                                </Trans>
                        </PrimaryLink>
                    </div>
                }
            >
                <Typography
                    variant="subtitle1"
                    component="span"
                    data-testid="home-page-unauthenticated-card-text"
                >
                    <Trans i18nKey="homePage.cards.unauthenticated.text">
                        <span lang="en">Illuminate</span> connects your campus
                        data and transforms it into key insights that help you
                        make the best decisions at the right time.
                    </Trans>
                </Typography>
            </CustomCardPrimary>

            <Typography
                component="h2"
                variant="h1"
                className={classes.subtitle}
            >
                {t('homePage.subtitles.standardFeatures')}
            </Typography>
            <CustomCardSecondary
                id="reporting"
                title={
                    <Trans i18nKey="homePage.cards.reportingUnauthenticated.title">
                        <span lang="en">Illuminate</span> Reporting
                    </Trans>
                }
                imgSrc="/images/HomePage/reportingIllustration.svg"
                className={classes.singleRow}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/reporting"
                            data-testid="go-to-reporting-card-button"
                        >
                            {t('homePage.cards.buttons.goToReporting')}
                        </OutlineLink>
                    </div>
                }
            >
                {t('homePage.cards.reportingUnauthenticated.text')}
                <div className={classes.items}>
                    <StyledItem
                        id="institution-wide-data"
                        title={t(
                            'homePage.cards.reportingUnauthenticated.item1.title',
                        )}
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="homePage.cards.reportingUnauthenticated.item1.text">
                                Take advantage of the convergence of rich data from <span lang="en">Anthology SaaS</span> solutions in a single place.
                            </Trans>
                    </StyledItem>
                    <StyledItem
                        id="role-based-access"
                        title={t(
                            'homePage.cards.reportingUnauthenticated.item2.title',
                        )}
                    >
                        {t(
                            'homePage.cards.reportingUnauthenticated.item2.text',
                        )}
                    </StyledItem>
                </div>
            </CustomCardSecondary>
            <CustomCardSecondary
                id="developer"
                title={
                    <Trans i18nKey="homePage.cards.developerUnauthenticated.title">
                        <span lang="en">Illuminate</span> Developer
                    </Trans>
                }
                imgSrc="/images/HomePage/developerIllustration.svg"
                className={classes.singleRow}
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to="/developer"
                            data-testid="go-to-developer-card-button"
                        >
                            {t('homePage.cards.buttons.goToDeveloper')}
                        </OutlineLink>
                    </div>
                }
            >
                {t('homePage.cards.developerUnauthenticated.text')}
                <div className={classes.items}>
                    <StyledItem
                        id="unified-data"
                        title={t(
                            'homePage.cards.developerUnauthenticated.item1.title',
                        )}
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="homePage.cards.developerUnauthenticated.item1.text">
                            Provides access to your institution’s data across <span lang="en">Anthology SaaS</span> solutions.
                            </Trans>
                    </StyledItem>
                    <StyledItem
                        id="data-democratization"
                        title={t(
                            'homePage.cards.developerUnauthenticated.item2.title',
                        )}
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="homePage.cards.developerUnauthenticated.item2.text">
                                Each institution owns its data exploration with the use of <span lang="en">SQL</span> and <span lang="en">Snowflake</span> to consult and connect their BI tools to build their own reports.
                            </Trans>
                    </StyledItem>
                </div>
            </CustomCardSecondary>
        </>
    );
};
