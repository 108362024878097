import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider/BbThemeProvider';
import { Drawer } from '@bb-ui/react-library/dist/components/Drawer/Drawer';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton/IconButton';
import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import Typography from '@bb-ui/react-library/dist/components/Typography/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DoubleChevronLeft } from '@bb-ui/icons/dist/small/DoubleChevronLeft';
import { useLocation } from 'react-router-dom';
import { Outline } from '@bb-ui/icons/dist/small/Outline';
import { Key } from 'ts-key-enum';
import { usePrevious } from 'react-use';
import { ListNav } from '../ListNav/ListNav';
import {
    NavigationRegistrationProps,
    useNavigationRegistrationContext,
} from '../NavigationRouting/NavigationRegistration';
import { getWordBreakStyle } from '../../utilities/localization';
import { useStyles } from './SecondaryNavigation.styles';
import { useI18nContext } from '../I18nProvider/I18nProvider';
import { BadgeType } from '../ListNav/ListNav.types';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';

export const SecondaryNavigation: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const { navigationLinks } = useNavigationRegistrationContext();
    const location = useLocation();

    const { openNavDrawer, openSecondaryNavDrawer, closeSecondaryNavDrawer } =
        useAppNavigationContext();

    const isOpen = openNavDrawer === 'secondary';
    const globalNavOpen = openNavDrawer === 'global';

    const { userSelectedLang } = useI18nContext();

    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const openSecondaryNavDrawerButtonRef =
        React.useRef<HTMLButtonElement>(null);
    const closeSecondaryNavDrawerButtonRef =
        React.useRef<HTMLButtonElement>(null);

    const onSecondaryNavButtonKeyPress: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            setTimeout(() => {
                if (closeSecondaryNavDrawerButtonRef.current) {
                    closeSecondaryNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const onCloseSecondaryNavDrawerButtonKeyPress: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            setTimeout(() => {
                if (openSecondaryNavDrawerButtonRef.current) {
                    openSecondaryNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const closeSecondaryNavDrawerInSmDown = () => {
        if (smDown) {
            closeSecondaryNavDrawer();
            // setIsOpen(false);
        }
    };

    const routesShowingNavigationLvl3 = React.useMemo(() => {
        // level 2 link label = seondary navigation title
        const navigationTitleByKey = Object.fromEntries(
            Object.entries(navigationLinks)
                .filter(([, v]) => v.navigationItemLevel === 2)
                .map(([, v]) => [v.navigationItemKey, v.label]),
        );

        const navigationLevel3ByParent = Object.values(navigationLinks)
            .filter(l => l.navigationItemLevel === 3)
            .sort((v1, v2) =>
                v1.navigationItemKey.localeCompare(v2.navigationItemKey),
            )
            .reduce(
                (acc, v) => {
                    if (!(v.navigationParentKey in acc)) {
                        acc[v.navigationParentKey] = [];
                    }
                    acc[v.navigationParentKey].push(v);
                    return acc;
                },
                {} as { [key: string]: NavigationRegistrationProps[] },
            );

        // all routes of level 2 which have level 3 routes, and all level 3 routes to the list of link descriptions
        return Object.fromEntries(
            Object.entries(navigationLinks)
                .filter(
                    ([k, v]) =>
                        (v.navigationItemKey in navigationLevel3ByParent ||
                            v.navigationParentKey in
                                navigationLevel3ByParent) &&
                        !v.hideSecondaryNavigation,
                )
                .map(([k, v]) => {
                    const key =
                        v.navigationItemLevel === 2
                            ? v.navigationItemKey
                            : v.navigationParentKey;

                    const pathPattern =
                        v.exact === false
                            ? k
                                  .replace(/\/:[^/]+\//g, '/[^]+/')
                                  .replace(/\/?$/, '(/.*)?')
                            : k;
                    return [
                        pathPattern,
                        {
                            title: navigationTitleByKey[key],
                            links: navigationLevel3ByParent[key],
                        },
                    ];
                }),
        );
    }, [navigationLinks]);

    const secondaryNavContentMatches = Object.keys(
        routesShowingNavigationLvl3,
    ).filter(pattern => new RegExp(pattern).test(location.pathname));

    const pageHasSecondaryNav = !!secondaryNavContentMatches.length;
    // can match multiple times when non-exact paths are used, but unless there's some major
    // mess up in the routing, it should result in the same secondary navigation
    const { links, title } =
        routesShowingNavigationLvl3[secondaryNavContentMatches[0]] || {};

    const pageHadSecondaryNavBefore = usePrevious(pageHasSecondaryNav);

    React.useEffect(() => {
        if (!pageHadSecondaryNavBefore && pageHasSecondaryNav) {
            openSecondaryNavDrawer();
        }
    }, [
        pageHadSecondaryNavBefore,
        pageHasSecondaryNav,
        openSecondaryNavDrawer,
    ]);

    React.useEffect(() => {
        if (!pageHasSecondaryNav && isOpen) {
            closeSecondaryNavDrawer();
        }
    }, [pageHasSecondaryNav, isOpen, closeSecondaryNavDrawer]);

    return (
        <BbThemeProvider theme="dark">
            <Drawer
                variant={mdUp ? 'persistent' : 'temporary'}
                open={isOpen}
                onClose={closeSecondaryNavDrawer}
                data-testid="secondary-nav-drawer"
                PaperProps={{
                    className: classes.secondaryNavDrawerPaper,
                }}
            >
                <nav
                    aria-label={t('general.secondaryNavigation')}
                    className={classes.navFlexColumnWrapper}
                >
                    <div>
                        <div className={classes.secondaryNavTopSection}>
                            <Typography
                                variant="h4"
                                component="p"
                                className={classes.secondaryNavTitle}
                                style={getWordBreakStyle(userSelectedLang)}
                                data-testid="secondary-nav-title"
                            >
                                {title}
                            </Typography>
                            <IconButton
                                className={
                                    classes.closeSecondaryNavDrawerButton
                                }
                                onClick={closeSecondaryNavDrawer}
                                onKeyDown={
                                    onCloseSecondaryNavDrawerButtonKeyPress
                                }
                                aria-label={t('general.closeSecondaryMenu')}
                                aria-expanded={isOpen}
                                ref={closeSecondaryNavDrawerButtonRef}
                                data-testid="close-secondary-menu"
                            >
                                <DoubleChevronLeft />
                            </IconButton>
                        </div>
                        <div className={classes.navSection}>
                            <ListNav
                                variant="secondary"
                                listItems={(links || []).map(c => ({
                                    label: c.label,
                                    path: c.path,
                                    testId: c.testId,
                                    // todo: change this at some point
                                    badgeType: /learning-tools-adoption/.test(
                                        c.path,
                                    )
                                        ? BadgeType.NEW
                                        : undefined,
                                    onClick: closeSecondaryNavDrawerInSmDown,
                                    isAvaliableInRegion:
                                        c.access?.reason !==
                                        'REGION_UNAVAILABLE',
                                }))}
                            />
                        </div>
                    </div>
                </nav>
            </Drawer>
            {!globalNavOpen && !isOpen && pageHasSecondaryNav && (
                <IconButton
                    className={classes.openSecondaryNavDrawerButton}
                    onClick={openSecondaryNavDrawer}
                    onKeyDown={onSecondaryNavButtonKeyPress}
                    aria-label={t('general.secondaryMenu')}
                    aria-expanded={isOpen}
                    data-testid="open-secondary-menu"
                    ref={openSecondaryNavDrawerButtonRef}
                >
                    <Outline />
                </IconButton>
            )}
        </BbThemeProvider>
    );
};
