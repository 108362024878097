import * as React from 'react';

import { List } from '@bb-ui/react-library/dist/components/List';
import { ListItemRouteLink } from './ListItemRouteLink';
import { IListNavProps } from './ListNav.types';

export const ListNav: React.FC<IListNavProps> = ({
    listItems,
    variant,
    subtitleStyle,
}) => (
    <List>
        {listItems.map(item => (
            <ListItemRouteLink
                key={item.label}
                path={item.path}
                exact={item.exact}
                label={item.label}
                icon={item.icon}
                onClick={item.onClick}
                disableListItem={item.disableListItem}
                listNavVariant={variant}
                subtitleStyle={subtitleStyle}
                testId={item.testId}
                badgeType={item.badgeType}
                keepEnglishLabel={item.keepEnglishLabel}
                isAvailableInRegion={item.isAvaliableInRegion}
            />
        ))}
    </List>
);
