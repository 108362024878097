import React from 'react';

interface AppLoadingProps {
    loading: boolean;
    setLoadingFlag: (flagName: string, loading: boolean) => void;
}
export const AppLoadingContext = React.createContext<AppLoadingProps>({
    loading: true,
    setLoadingFlag: () => {},
});

export const useAppLoadingContext = () => React.useContext(AppLoadingContext);
