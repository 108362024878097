import useAxios from 'axios-hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface AudienceResponse {
    /**
     * JWT audience for this tenant.
     */
    audience: string;
    /**
     * AWS region of this tenant.
     */
    region: string;
    /**
     * UUID of this tenant.
     */
    tenantId: string;
}

export const useAudience = (props: {
    tenantUriComponent: string;
    ssoUrl: string;
}) => {
    const { tenantUriComponent, ssoUrl } = props;
    const history = useHistory();
    const [fetchedAudience, setFetchedAudience] = React.useState(false);

    // Retrieve tenant ID and region when we have a URI component.

    const [{ data: audienceData, error: audienceError }, fetchAudience] =
        useAxios<AudienceResponse>(
            {
                url: `${ssoUrl}/audience?uriComponent=${tenantUriComponent}`,
            },
            { manual: true },
        );

    React.useEffect(() => {
        if (tenantUriComponent && !fetchedAudience) {
            setFetchedAudience(true);
            fetchAudience().catch(() => {}); // We'll throw below so an error boundary can catch it.
        }
    }, [fetchAudience, fetchedAudience, tenantUriComponent]);

    const bbLoginParams = React.useMemo(
        () => window.btoa(JSON.stringify({ tenantId: audienceData?.tenantId })),
        [audienceData?.tenantId],
    );

    if (audienceError) {
        history.push('/');
        throw new Error(
            `Login failed -> Failed to fetch audience for tenant ${tenantUriComponent}`,
        );
    }

    return {
        loading: audienceData === undefined,
        data: { ...(audienceData || {}), bbLoginParams } as AudienceResponse & {
            bbLoginParams: string;
        },
    };
};
