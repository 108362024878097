import React from 'react';
import { useAppConfigContext } from '../AppConfigProvider';
import { IApiConfig, useApi } from '../../hooks/useApi';
import { useUser } from '../../hooks/useUser';
import {
    UasMigrationGetStatusResponse,
    UasMigrationStatus,
    UasMigrationStatusContextProps,
} from './UasMigrationStatusContext.types';

export const UasMigrationStatusContext =
    React.createContext<UasMigrationStatusContextProps>({
        polling: false,
        restartPolling: () => {},
        isSupported: false,
        firstExecutionAvailable: false,
    });

export const useUasMigrationStatusContext = () =>
    React.useContext(UasMigrationStatusContext);

export const UasMigrationStatusContextProvider: React.FunctionComponent =
    props => {
        const { api } = useAppConfigContext();
        const { children } = props;
        const user = useUser();

        // API calls are allowed for all Incremental Auth users and for Universal Auth users
        // that have Snowflake access (i.e. for institutional admins and support personae)
        const isSupported = !!user?.hasSnowflakeAccess();

        const getStatusConfig = React.useMemo<IApiConfig>(
            () => ({
                url: encodeURI(
                    `${api?.authProv}/v1/data/tenants/${user?.tenantIdentifier}/migrations/uas/status`,
                ),
                useAuth: true,
                method: 'GET',
                executeType: 'manual',
            }),
            [api, user],
        );
        const {
            data,
            loading,
            error: fetchError,
            execute,
        } = useApi<UasMigrationGetStatusResponse>(getStatusConfig);

        // migration can be executed for the first time if no status data were found
        const firstExecutionAvailable =
            isSupported && fetchError?.response?.status === 404;
        // filter out 404 http error data as that's valid state for not found migrations available for execution
        const error = firstExecutionAvailable ? undefined : fetchError;

        const [timeoutId, setTimeoutId] = React.useState<
            NodeJS.Timeout | undefined
        >();

        const pollPlanned = loading || !!timeoutId;
        const canPlanPoll = !pollPlanned && isSupported;
        const willPlanPoll =
            !fetchError && data?.migrationStatus === UasMigrationStatus.RUNNING;

        React.useEffect(() => {
            if (canPlanPoll) {
                if (!data && !fetchError) {
                    execute();
                } else if (willPlanPoll) {
                    setTimeoutId(
                        setTimeout(() => {
                            execute();
                            setTimeoutId(undefined);
                        }, 2500),
                    );
                }
            }
        }, [canPlanPoll, willPlanPoll, data, fetchError, isSupported, execute]);

        const restartPolling = React.useCallback(() => {
            if (canPlanPoll) {
                execute();
            }
        }, [execute, canPlanPoll]);

        return (
            <UasMigrationStatusContext.Provider
                value={{
                    polling: pollPlanned || willPlanPoll,
                    data,
                    error,
                    restartPolling,
                    isSupported,
                    firstExecutionAvailable,
                }}
            >
                {children}
            </UasMigrationStatusContext.Provider>
        );
    };

export default UasMigrationStatusContextProvider;
