import * as React from 'react';
import classnames from 'classnames';
import {
    makeStyles,
    createStyles,
    Theme,
    getColor,
} from '@bb-ui/react-library/dist/components/styles';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import {
    CircularProgress,
    CircularProgressProps,
} from '@bb-ui/react-library/dist/components/Progress';
import { zIndex } from '../../utilities/sharedStyles';

interface LoadingIndicatorProps extends Pick<CircularProgressProps, 'size'> {
    theme?: 'light' | 'dark';
    // For now, 'fill-content' is just the default
    variant?: 'fill-window' | 'fill-content';
    className?: string;
    id: string;
    label?: string;
    hideRenderingElements?: true;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fillWindow: (props: LoadingIndicatorProps) => ({
            height: '100%',
            position: 'fixed' as 'fixed',
            top: 0,
            backgroundColor: props.hideRenderingElements
                ? 'rgba(138, 138, 138, 255)'
                : 'rgba(0, 0, 0, 0.5)',
            zIndex: zIndex.loadingIndicator,
        }),
        loadingIndicator: (props: LoadingIndicatorProps) => ({
            color: getColor('brand.main', props.theme || 'light'),
        }),
        // when in large breakpoints, make the label fontSize bigger
        indicatorLabel: {
            fontSize: theme.typography.fontSizeMedium,
            textAlign: 'center',
            padding: theme.spacing(1.5, 2, 0, 2),
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.fontSizeLarge,
            },
            whiteSpace: 'pre-line',
        },
    }),
);

export const LoadingIndicator: React.FunctionComponent<
    LoadingIndicatorProps
> = props => {
    const classes = useStyles(props);
    const { theme, variant, size, id, label, hideRenderingElements, ...other } =
        props;
    return (
        <BbThemeProvider theme={theme || 'light'}>
            <div
                className={classnames(classes.root, {
                    [classes.fillWindow]: variant === 'fill-window',
                })}
                role="status"
                data-testid="loading-indicator"
                {...other}
            >
                <CircularProgress
                    className={classes.loadingIndicator}
                    label={label || ''}
                    size={size}
                    id={id}
                    labelProps={{
                        className: classes.indicatorLabel,
                    }}
                />
            </div>
        </BbThemeProvider>
    );
};
