import React, { createContext, useState, useContext } from 'react';
import { useAppNavigationContext } from './AppNavigationContext';

import { OpenNavDrawer } from '../components/MainPage/MainPage.types';

const PageExpandContext = createContext<{
    expanded: boolean;
    setExpanded: (value: boolean) => void;
}>({
    expanded: false,
    setExpanded: () => {},
});

export const PageExpandProvider: React.FC = ({ children }) => {
    const {
        openNavDrawer,
        openGlobalNavDrawer,
        closeGlobalNavDrawer,
        openSecondaryNavDrawer,
    } = useAppNavigationContext();

    const [expanded, setExpanded] = useState<boolean>(false);
    const [drawerStateBeforeExpand, setDrawerStateBeforeExpand] =
        useState<OpenNavDrawer>('none');

    const setExpandedWithAction = React.useMemo(
        () => (value: boolean) => {
            if (value) {
                setDrawerStateBeforeExpand(openNavDrawer);
                closeGlobalNavDrawer();
            } else if (openNavDrawer !== drawerStateBeforeExpand) {
                switch (drawerStateBeforeExpand) {
                    case 'global':
                        openGlobalNavDrawer();
                        break;
                    case 'secondary':
                        openSecondaryNavDrawer();
                        break;
                    case 'none':
                        closeGlobalNavDrawer();
                        break;
                }
            }
            setExpanded(value);
        },
        [
            closeGlobalNavDrawer,
            drawerStateBeforeExpand,
            openGlobalNavDrawer,
            openNavDrawer,
            openSecondaryNavDrawer,
        ],
    );

    return (
        <PageExpandContext.Provider
            value={{ expanded, setExpanded: setExpandedWithAction }}
        >
            {children}
        </PageExpandContext.Provider>
    );
};

// Custom hook for easier usage
export const usePageExpand = () => useContext(PageExpandContext);
