import * as React from 'react';

import { Home, Lock, Report, Visualization } from '@bb-ui/icons/dist/medium';
import {
    BackArrow,
    Course,
    FolderClosed,
    Grade,
    GraduateCap,
    HandRaise,
    JoinSession,
    LeaveSession,
    Lightbulb,
    Settings,
} from '@bb-ui/icons/dist/small';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { Drawer } from '@bb-ui/react-library/dist/components/Drawer';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { List } from '@bb-ui/react-library/dist/components/List';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import {
    useMediaQuery,
    useTheme,
} from '@bb-ui/react-library/dist/components/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import { Key } from 'ts-key-enum';

import { AnthologyIlluminateLogoTwoLines } from '../../../app/assets/images/SVGs/AnthologyIlluminateLogoTwoLines';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';
import { useHelpLinks } from '../../hooks/useHelpLinks';
import { ListItemRouteLink } from '../ListNav/ListItemRouteLink';
import { ListNavButton } from '../ListNav/ListNavButton';
import { useStyles } from './SideBar.styles';
import { BadgeType } from '../ListNav/ListNav.types';
import { useUser } from '../../hooks/useUser';
import {
    NavigationRegistrationProps,
    useNavigationRegistrationContext,
} from '../NavigationRouting/NavigationRegistration';

const showBadgeBasedOnReleaseDate = (
    expectedReleaseDate: string | number | Date,
) => {
    const currentDate = new Date();
    const releaseDate = new Date(expectedReleaseDate);
    const twoMonthsAhead = new Date();
    twoMonthsAhead.setMonth(currentDate.getMonth() + 2);

    // Compare only the year, month, and day parts
    const isAfterReleaseDate = currentDate >= releaseDate;
    const isWithinTwoMonths = releaseDate <= twoMonthsAhead;

    if (isAfterReleaseDate && isWithinTwoMonths) {
        return BadgeType.NEW;
    }
    return undefined;
};

export interface ISideBarProps {
    logout: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    signin: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    openGlobalNavDrawerButtonRef: React.RefObject<HTMLButtonElement>;
    closeGlobalNavDrawerButtonRef: React.RefObject<HTMLButtonElement>;
}

export const SideBar: React.FC<ISideBarProps> = ({
    logout,
    signin,
    openGlobalNavDrawerButtonRef,
    closeGlobalNavDrawerButtonRef,
}) => {
    const homePageLink = '/';
    const { t } = useTranslation();
    const classes = useStyles();
    const getHelpLink = useHelpLinks();
    const user = useUser();

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const { openNavDrawer, closeGlobalNavDrawer, openSecondaryNavDrawer } =
        useAppNavigationContext();

    const { navigationLinks } = useNavigationRegistrationContext();

    const globalNavOpen = openNavDrawer === 'global';
    const closeGlobalNavDrawerInSmDown = () => {
        if (smDown) {
            closeGlobalNavDrawer();
        }
    };

    const onCloseGlobalNavDrawerButtonKeyDown: (
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => void = e => {
        if (e.key === ' ' || e.key === Key.Enter) {
            setTimeout(() => {
                if (openGlobalNavDrawerButtonRef.current) {
                    openGlobalNavDrawerButtonRef.current.focus();
                }
            }, 200);
        }
    };

    const navigationLevel1 = Object.values(navigationLinks)
        .filter(l => l.navigationItemLevel === 1)
        .sort((v1, v2) =>
            v1.navigationItemKey.localeCompare(v2.navigationItemKey),
        );

    const navigationLevel2 = Object.values(navigationLinks)
        .filter(l => l.navigationItemLevel === 2)
        .sort((v1, v2) =>
            v1.navigationItemKey.localeCompare(v2.navigationItemKey),
        )
        .reduce(
            (acc, v) => {
                if (!(v.navigationParentKey in acc)) {
                    acc[v.navigationParentKey] = [];
                }
                acc[v.navigationParentKey].push(v);
                return acc;
            },
            {} as { [key: string]: NavigationRegistrationProps[] },
        );

    const navigationLevelItems2WithChildren = Object.values(navigationLevel2)
        .flat()
        .filter(v =>
            Object.values(navigationLinks).some(
                l =>
                    l.navigationItemLevel === 3 &&
                    l.navigationParentKey === v.navigationItemKey,
            ),
        )
        .map(v => v.navigationItemKey);

    return (
        <BbThemeProvider theme="dark">
            <Drawer
                variant={mdUp ? 'persistent' : 'temporary'}
                open={globalNavOpen}
                onClose={closeGlobalNavDrawer}
                data-testid="main-menu-drawer"
                PaperProps={{
                    className: classes.globalNavDrawerPaper,
                }}
            >
                <nav
                    aria-label={t('general.mainMenu')}
                    className={classes.globalNavFlexColumnWrapper}
                >
                    {/* Top section */}
                    <div className={classes.globalNavTopSection}>
                        <IconButton
                            className={classes.closeGlobalNavDrawerButton}
                            onClick={closeGlobalNavDrawer}
                            // TODO this doesn't really function anymore nor makes sense to me.
                            // Waiting for UXD to confirm maybe other keyboard action.
                            onKeyDown={onCloseGlobalNavDrawerButtonKeyDown}
                            aria-label={t('general.closeMainMenu')}
                            aria-expanded={globalNavOpen}
                            ref={closeGlobalNavDrawerButtonRef}
                            data-testid="sidebar-close-main-menu"
                            color="inherit"
                        >
                            <BackArrow />
                        </IconButton>

                        <div className={classes.logoImageLinkContainer}>
                            <RouteLink
                                to={homePageLink}
                                className={classes.logoImageLink}
                                onClick={closeGlobalNavDrawerInSmDown}
                                aria-label={t('general.homePageLinkAriaLabel')}
                                data-testid="sidebar-logo-link"
                            >
                                <AnthologyIlluminateLogoTwoLines />
                            </RouteLink>
                        </div>
                        <div className={classes.globalNavSection}>
                            <List>
                                {navigationLevel1.map(level1 => (
                                    <React.Fragment
                                        key={`${level1.navigationItemKey}-container`}
                                    >
                                        <ListItemRouteLink
                                            key={level1.navigationItemKey}
                                            {...level1}
                                            listNavVariant="global"
                                            onClick={
                                                closeGlobalNavDrawerInSmDown
                                            }
                                            isAvailableInRegion={
                                                level1.access?.reason !==
                                                'REGION_UNAVAILABLE'
                                            }
                                        />
                                        {navigationLevel2[
                                            level1.navigationItemKey
                                        ] && (
                                            <List
                                                key={`${level1.navigationItemKey}-children`}
                                                className={classes.subMenu}
                                            >
                                                {navigationLevel2[
                                                    level1.navigationItemKey
                                                ]?.map(level2 => (
                                                    <ListItemRouteLink
                                                        key={
                                                            level2.navigationItemKey
                                                        }
                                                        {...level2}
                                                        listNavVariant="global"
                                                        onClick={
                                                            !level2.hideSecondaryNavigation &&
                                                            navigationLevelItems2WithChildren.includes(
                                                                level2.navigationItemKey,
                                                            )
                                                                ? openSecondaryNavDrawer
                                                                : closeGlobalNavDrawerInSmDown
                                                        }
                                                        isAvailableInRegion={
                                                            level2.access
                                                                ?.reason !==
                                                            'REGION_UNAVAILABLE'
                                                        }
                                                    />
                                                ))}
                                            </List>
                                        )}
                                    </React.Fragment>
                                ))}

                                <ListItem className={classes.helpLink}>
                                    <a
                                        href={getHelpLink('main')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-testid="sidebar-help-link"
                                    >
                                        {t('needHelp.title')}
                                    </a>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    {/* Bottom section */}
                    <div className={classes.globalNavSection}>
                        <List>
                            {user ? (
                                <ListNavButton
                                    label={t('auth.logout')}
                                    icon={<LeaveSession />}
                                    onClick={logout}
                                    listNavVariant="global"
                                    testId="sidebar-sign-out-link-in-drawer-nav"
                                />
                            ) : (
                                <ListNavButton
                                    label={t('auth.login')}
                                    icon={<JoinSession />}
                                    onClick={signin}
                                    listNavVariant="global"
                                    testId="sidebar-sign-in-button-in-drawer-nav"
                                />
                            )}
                        </List>
                    </div>
                </nav>
            </Drawer>
        </BbThemeProvider>
    );
};
