import React from 'react';

export type NavigationLevelContextProps = {
    level: number;
    address: string;
    hierarchyIndex: string;
};

export const NavigationLevelContext =
    React.createContext<NavigationLevelContextProps>({
        level: 0,
        address: '/',
        hierarchyIndex: '',
    });

export const useNavigationLevelContext = () =>
    React.useContext(NavigationLevelContext);

export const NavigationLevelContextProvider: React.FC<{
    addressPart: string;
    children: React.ReactNode;
    hierarchyIndex: number;
    routeWithoutContext?: true;
}> = props => {
    // Nested context, takes data from the previous level of the context and appends it
    // to be able to know which level of navigation we're in, and to concentrate the data from the previous route
    // into the next one

    const prevLevel = useNavigationLevelContext();
    const routeWithoutContext = props.routeWithoutContext ?? false;

    return (
        <NavigationLevelContext.Provider
            value={{
                level: prevLevel.level + 1,
                address: (routeWithoutContext
                    ? `/${props.addressPart}`
                    : `${prevLevel.address}/${props.addressPart}`
                ).replace('//', '/'),
                hierarchyIndex: prevLevel.hierarchyIndex
                    ? `${prevLevel.hierarchyIndex}-${props.hierarchyIndex.toString().padStart(3, '0')}`
                    : props.hierarchyIndex.toString().padStart(3, '0'),
            }}
        >
            {props.children}
        </NavigationLevelContext.Provider>
    );
};
