import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useStyles } from './ServiceUsers/SnowflakeServiceUsers.styles';
import { useAppConfigContext } from '../../../contexts/AppConfigProvider';
import { SnowflakeCreditsUsage } from './SnowflakeCredits/SnowflakeCreditsUsage';
import { SnowflakeCreditsBurndown } from './SnowflakeCredits/SnowflakeCreditsBurndown';
import { UasMigration } from '../UasMigration/UasMigration';
import { useFeatureContext } from '../../../contexts/FeatureContext';
import { SnowflakeServiceUsers } from './ServiceUsers/SnowflakeServiceUsers';
import { AccessControlledSection } from '../../RequiresApi/AccessControlledSection';
import { useAccessControl } from '../../../hooks/useAccessControl';

export const SnowflakeAccountSettings: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const access = useAccessControl();
    const showUasMigration = useFeatureContext().uasMigrationEnabled;
    const { api } = useAppConfigContext();

    return (
        <>
            {showUasMigration && (
                <AccessControlledSection
                    access={access['developer/settings/uas-migration']}
                    onApiMissing="HIDE"
                >
                    <UasMigration />
                </AccessControlledSection>
            )}

            <Typography component="p" className={classes.description}>
                {t('settings.userManagement.description')}
            </Typography>

            <Typography variant="h3" className={classes.header}>
                {t('settings.userManagement.creditsUsage.creditsHeader')}
            </Typography>

            <AccessControlledSection
                access={access['developer/settings/credits']}
            >
                <SnowflakeCreditsUsage />
            </AccessControlledSection>

            <AccessControlledSection
                access={access['reporting/reports']}
                onApiMissing="HIDE"
            >
                <SnowflakeCreditsBurndown />
            </AccessControlledSection>

            <Typography variant="h3" className={classes.header}>
                {t('settings.userManagement.tableHeading')}
            </Typography>

            <AccessControlledSection
                access={access['developer/settings/service-users']}
            >
                <SnowflakeServiceUsers />
            </AccessControlledSection>
        </>
    );
};
