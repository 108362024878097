import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAudience } from './useAudience';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import { Auth0Provider, useAuth0Context } from './Auth0Context';

const CallbackHandlerInner: React.FC = () => {
    const { loading, login } = useAuth0Context();
    const [error, setError] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        if (!loading && login) {
            try {
                login();
            } catch (e) {
                console.error(
                    `Failed to initialize login from Universal auth -> ${e}`,
                );
                setError(true);
            }
        }
    }, [loading, login]);

    if (error) {
        history.push('/');
        throw new Error(
            `Failed to initialize login from Universal Authentication.`,
        );
    }

    return <></>;
};

export const UASCallbackHandler: React.FC = () => {
    const location = useLocation();
    const { uas, regions, setRegion } = useAppConfigContext();
    const history = useHistory();
    const [regionHasBeenSet, markRegionAsSet] = React.useState(false);
    const [regionError, setRegionError] = React.useState<string>();
    const searchParams = new URLSearchParams(location.search);
    const tenantUriComponent = searchParams.get('tenantUriComponent');

    if (!tenantUriComponent) {
        history.push('/');
        throw new Error(
            'Invalid Universal auth callback; tenantUriComponent is missing.',
        );
    }

    if (!uas) {
        history.push('/');
        throw new Error('Universal auth is not supported.');
    }

    const audience = useAudience({
        tenantUriComponent,
        ssoUrl: uas?.config.apiBaseURL,
    });

    // set app region config
    React.useEffect(() => {
        if (audience.loading) {
            return;
        }
        const regionName = [...audience.data.region.split('-'), uas!.stage]
            .map(
                (part: string, index: number) =>
                    index === 0
                        ? part.toLowerCase()
                        : part[0].toUpperCase() +
                          part?.substring(1).toLowerCase(), // eslint-disable-line no-unsafe-optional-chaining
            )
            .join('');

        if (regions.includes(regionName)) {
            markRegionAsSet(true);
            setRegion(regionName);
        } else {
            setRegionError(`Tenant's region (${regionName}) is not supported!`);
        }
    }, [audience, regions, setRegion, uas]);

    if (regionError) {
        throw Error(regionError);
    }

    if (audience.loading || !regionHasBeenSet) {
        return <></>;
    }

    const clientId = uas?.config.auth0Clients[audience.data.region!]?.client_id;
    const domain = uas?.config.auth0Clients[audience.data.region!]?.domain;

    if (clientId === undefined || domain === undefined) {
        history.push('/');
        throw new Error(
            `User's region (${audience.data.region}) is not supported`,
        );
    }

    return (
        <Auth0Provider
            audience={audience.data.audience}
            bb_login_params={audience.data.bbLoginParams}
            client_id={clientId}
            domain={domain}
            redirect_uri={`${window.location.origin}/callback`}
            {...uas?.config}
        >
            <CallbackHandlerInner />
        </Auth0Provider>
    );
};
