import React from 'react';

import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useTranslation, Trans } from 'react-i18next';

import { useHelpLinks } from '../../hooks/useHelpLinks';
import { CustomCardPrimary } from '../CustomCards/CustomCardPrimary';
import { illuminateColors } from '../../utilities/sharedStyles';
import { OutlineLink } from '../StyledLinks/OutlineLink';
import { StyledItem } from '../CustomCards/StyledItem';
import { useStyles } from './HomePage.styles';

export const HomePageEnhancedAd = () => {
    const { t } = useTranslation();
    const classes: any = useStyles();
    const getHelpLink = useHelpLinks();

    return (
        <>
            <Typography
                component="h2"
                variant="h1"
                className={classes.subtitle}
                data-testid="home-page-enhanced-subtitle"
            >
                <Trans i18nKey="homePage.subtitles.enhanced">
                    Your data in a whole new light with
                    <span lang="en" style={{ fontWeight: 'bold' }}>
                        Illuminate Enhanced
                    </span>
                </Trans>
            </Typography>
            <CustomCardPrimary
                id="enhanced-teaser"
                title={
                    <Typography variant="h2" className={classes.title}>
                        <Trans i18nKey="homePage.cards.notEnhanced.title">
                            <span lang="en">Illuminate Enhanced</span> gives you
                            the power for data exploration and report creation
                            with:
                        </Trans>
                    </Typography>
                }
                imgSrc="/images/HomePage/illuminateEnhanced.svg"
                className={classes.singleRow}
                loadingImageColor={illuminateColors.brandYellowLight}
                imagePosition="left"
                cardActions={
                    <div className={classes.link}>
                        <OutlineLink
                            to={getHelpLink('exploreEnhanced')}
                            data-testid="home-page-explore-enhanced-button"
                            external
                        >
                            { /* prettier-ignore */}
                            <Trans i18nKey="buttons.exploreEnhanced">
                                Explore <span lang="en">Illuminate Enhanced</span>
                            </Trans>
                        </OutlineLink>
                    </div>
                }
            >
                <div className={classes.listItems}>
                    <StyledItem
                        id="custom-reports"
                        title={t('authoring.title')}
                    >
                        {t('homePage.cards.notEnhanced.customReportsItem.text')}
                    </StyledItem>
                    <StyledItem id="data-q-and-a" title="Data Q&A">
                        {t('homePage.cards.notEnhanced.dataQandAItem.text')}
                    </StyledItem>
                    <StyledItem
                        id="extra-seats"
                        title={t('homePage.cards.notEnhanced.seatsItem.title')}
                    >
                        {t('homePage.cards.notEnhanced.seatsItem.text')}
                    </StyledItem>
                </div>
            </CustomCardPrimary>
        </>
    );
};
