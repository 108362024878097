import React from 'react';
import {
    NavigationLevelContextProps,
    useNavigationLevelContext,
} from './NagivationLevel';
import { NavigatedRoutedLinkProps } from './NavigationRoutedLinkProps';

export type NavigationRegistrationProps = NavigatedRoutedLinkProps & {
    navigationItemKey: string;
    navigationItemLevel: number;
    navigationParentKey: string;
};

type NavigationRegistrationContextProps = {
    addNavigationLink: (
        navigationLevel: NavigationLevelContextProps,
        props: NavigatedRoutedLinkProps,
    ) => void;
    removeNavigationLink: (
        navigationLevel: NavigationLevelContextProps,
    ) => void;
    navigationLinks: { [key: string]: NavigationRegistrationProps };
};

export const NavigationRegistrationContext =
    React.createContext<NavigationRegistrationContextProps>({
        addNavigationLink: () => {},
        removeNavigationLink: () => {},
        navigationLinks: {},
    });

export const useNavigationRegistrationContext = () =>
    React.useContext(NavigationRegistrationContext);

export const NavigationRegistrationContextProvider: React.FC = props => {
    const [navigationLinks, setNavigationLinks] = React.useState<{
        [key: string]: NavigationRegistrationProps;
    }>({});

    const navigationLinksCache = React.useRef<{
        [key: string]: NavigationRegistrationProps;
    }>({});

    const addNavigationLink = React.useMemo(
        () =>
            (
                navigationLevel: NavigationLevelContextProps,
                props: NavigatedRoutedLinkProps,
            ) => {
                const navigationHierarchySplit =
                    navigationLevel.hierarchyIndex.split('-');
                navigationLinksCache.current[navigationLevel.address] = {
                    ...props,
                    navigationItemLevel: navigationLevel.level,
                    navigationItemKey: navigationLevel.hierarchyIndex,
                    navigationParentKey: navigationHierarchySplit
                        .splice(0, navigationHierarchySplit.length - 1)
                        .join('-'),
                };
                setNavigationLinks({ ...navigationLinksCache.current });
            },
        [],
    );
    const removeNavigationLink = React.useMemo(
        () => (navigationLevel: NavigationLevelContextProps) => {
            delete navigationLinksCache.current[navigationLevel.address];
            setNavigationLinks({ ...navigationLinksCache.current });
        },
        [],
    );

    return (
        <NavigationRegistrationContext.Provider
            value={{
                addNavigationLink,
                removeNavigationLink,
                navigationLinks,
            }}
        >
            {props.children}
        </NavigationRegistrationContext.Provider>
    );
};

export const NavigationItemRegistration: React.FC<
    NavigatedRoutedLinkProps
> = props => {
    /**
     * Wrapper component for registering metadata of a NavigationRoutedLink
     */

    const { addNavigationLink, removeNavigationLink } =
        useNavigationRegistrationContext();
    const navigationLevel = useNavigationLevelContext();

    React.useEffect(() => {
        addNavigationLink(navigationLevel, props);
    }, [navigationLevel, props, addNavigationLink]);

    React.useEffect(
        () =>
            // unmount
            () =>
                removeNavigationLink(navigationLevel),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return <>{props.children}</>;
};
