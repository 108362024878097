import {
    makeStyles,
    createStyles,
} from '@bb-ui/react-library/dist/components/styles';
import { Typography } from '@bb-ui/react-library/dist/components/Typography/Typography';

import React from 'react';

import { Warning } from '@bb-ui/icons/dist/medium/Warning';
import { useTranslation } from 'react-i18next';
import { Access } from '../../hooks/useAccessControl';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
        },
        logo: {
            marginRight: '6px',
            alignSelf: 'center',
        },
    }),
);

export const AccessControlledSection: React.FC<{
    access: Access;
    children: React.ReactNode[] | React.ReactNode;
    onApiMissing?: 'HIDE';
}> = props => {
    const styles = useStyles();
    const { t } = useTranslation();

    if (props.access.ok) {
        return <>{props.children}</>;
    }
    if (
        props.access.reason === 'REGION_UNAVAILABLE' &&
        props.onApiMissing !== 'HIDE'
    ) {
        return (
            <div
                className={styles.container}
                data-testid="not-available-in-this-region"
            >
                <Warning className={styles.logo} />
                <Typography color="textPrimary" variant="subtitle1">
                    {t('general.availability.unavailableInThisRegion')}
                </Typography>
            </div>
        );
    }
    return <></>;
};
