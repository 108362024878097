import React, { useMemo } from 'react';
import useAxios from 'axios-hooks';
import { Typography, useTheme } from '@bb-ui/react-library';
import { CursorPointer } from '@bb-ui/icons/dist/medium/CursorPointer';
import { Type } from '@bb-ui/icons/dist/medium/Type';
import { Visualization } from '@bb-ui/icons/dist/medium/Visualization';
import { Focus } from '@bb-ui/icons/dist/medium/Focus';
import { Pin } from '@bb-ui/icons/dist/medium/Pin';
import { Banner } from '@bb-ui/react-library/dist/components/Banner';
import { Information } from '@bb-ui/icons/dist/small';
import { Edit } from '@bb-ui/icons/dist/medium/Edit';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth0Context } from '../../auth/auth0/Auth0Context';
import { useAppNavigationContext } from '../../contexts/AppNavigationContext';

import { ResponsiveBox } from '../ResponsiveBox/ResponsiveBox';
import { QuickSightQSearchBar } from '../../quicksight/QuickSightQSearchBar';
import { MainHeader } from '../MainHeader/MainHeader';
import { ActionAreaButtons } from '../ActionAreaButtons/ActionAreaButtons';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { Frame } from '../Frame/Frame';
import { useStyles } from './DataQAndA.styles';
import { getTenantId } from '../../utilities/utilities';
import { useAppConfigContext } from '../../contexts/AppConfigProvider';
import QuickSightQError from '../../quicksight/QuickSightQError/QuickSightQError';
import { useApplicationTitle } from '../../hooks/useApplicationTitle';
import { DataQAndAUnauthorized } from './DataQAndAUnauthorized';
import { useHelpLinks } from '../../hooks/useHelpLinks';

/**
 * This IEmbedTopicAPI will be used as the interface for the final 'GetTopicUrl'
 * result set. Really, it could just be a url, but there may be additional information
 * that can only be used in the final QuickSightQSearchBar. For example, the theme--if
 * we wanted different themes per useage of the QuickSightQSearchBar. This will interface
 * should probably be moved.
 *
 * @interface IEmbedTopicAPI
 * @member {string} url is used as the url for the embeded Q Search Bar
 */
export interface IEmbedTopicAPI {
    url: string;
    title: string;
    description: string;
    name: string;
    id: string;
}

export const DataQAndA: React.FunctionComponent = () => {
    const { uas, devQTopicId, api } = useAppConfigContext();
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();

    // This does not get localized
    useApplicationTitle('Data Q&A');
    const getHelpLink = useHelpLinks();

    const { openNavDrawer } = useAppNavigationContext();
    const { user, accessToken } = useAuth0Context();

    const qAndADescription = useMemo(
        () => [
            {
                title: t('q&a.page.howTo.item1.title'),
                description: t('q&a.page.howTo.item1.description'),
                IconComponent: CursorPointer,
            },
            {
                title: t('q&a.page.howTo.item2.title'),
                description: (
                    <Trans i18nKey="q&a.page.howTo.item2.description">
                        From the <span lang="en">Data Q&A</span> bar, type or
                        choose a suggested question to generate a custom
                        visualization.
                    </Trans>
                ),
                IconComponent: Type,
            },
            {
                title: t('q&a.page.howTo.item3.title'),
                description: t('q&a.page.howTo.item3.description'),
                IconComponent: Edit,
            },
            {
                title: t('q&a.page.howTo.item4.title'),
                description: t('q&a.page.howTo.item4.description'),
                IconComponent: Focus,
            },
            {
                title: t('q&a.page.howTo.item5.title'),
                description: t('q&a.page.howTo.item5.description'),
                IconComponent: Visualization,
            },
            {
                title: t('q&a.page.howTo.item6.title'),
                description: t('q&a.page.howTo.item6.description'),
                IconComponent: Pin,
            },
        ],
        [t],
    );

    const tenantId = getTenantId(user, uas);

    const userId = user.sub;
    const topicId = devQTopicId;

    // this is needed so that the userId is encoded properly
    // the userId will look like: auth0|5ec4e9880d946b0cb8913b39
    // but Firefox and Safari do not encode the | correctly to a % which causes the API request to fail
    const url = encodeURI(
        `${
            api!.quicksightDashboards
        }/v1/data/dashboards/tenants/${tenantId}/users/${userId}/topics/${topicId}`,
    );

    const [{ data: topic, error: topicError, loading: topicLoading }] =
        useAxios<IEmbedTopicAPI>(
            {
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'GET',
            },
            {
                useCache: false,
            },
        );

    if (topicError?.response) {
        if (
            topicError.response.data?.details.includes(
                'reader_qs_seats_quota_exceeded',
            )
        ) {
            return <DataQAndAUnauthorized />;
        }
    }

    return (
        <div
            id="data-q-and-a-container"
            data-testid="data-q-and-a-container"
            lang="en"
        >
            <MainHeader
                title="Data Q&A"
                data-testid="data-q-and-a-header"
                withSideNavButton={openNavDrawer === 'none'}
            />
            <ResponsiveBox>
                <Frame>
                    {topicLoading && (
                        <LoadingIndicator
                            label={t('reports.loading.loadingData')}
                            id="loading-data-q-and-a"
                            data-testid="loading-data-q-and-a"
                            className={classes.qSearchBarLoadingIndicator}
                        />
                    )}
                    {topicError && <QuickSightQError />}
                    {!topicError && topic && (
                        <QuickSightQSearchBar
                            embedData={topic}
                            isError={false}
                        />
                    )}
                    <div
                        id="data-q-and-a-description"
                        data-testid="data-q-and-a-description"
                        className={classes.descriptionContainer}
                    >
                        <Typography
                            variant="h2"
                            className={classes.listSpacing}
                        >
                            {t('q&a.page.description.title')}
                        </Typography>
                        <Typography
                            component="p"
                            className={classes.listSpacing}
                        >
                            {t('q&a.page.description.subtitle')}
                        </Typography>
                        <Typography
                            component="ul"
                            className={classes.listSpacing}
                        >
                            <Typography
                                component="li"
                                className={classes.listSpacing}
                            >
                                {t('q&a.page.description.item1')}
                            </Typography>
                            <Typography
                                component="li"
                                className={classes.listSpacing}
                            >
                                {t('q&a.page.description.item2')}
                            </Typography>
                            <Typography
                                component="li"
                                className={classes.listSpacing}
                            >
                                {t('q&a.page.description.item3')}
                            </Typography>
                            <Typography
                                component="li"
                                className={classes.listSpacing}
                            >
                                {t('q&a.page.description.item4')}
                            </Typography>
                        </Typography>
                    </div>
                    <div className={classes.howToContainer}>
                        <Typography variant="h2">
                            { /* prettier-ignore */ }
                            <Trans i18nKey="q&a.page.howTo.title">
                                How to interact with <span lang="en">Data Q&A</span>:
                            </Trans>
                        </Typography>
                        {qAndADescription.map((item, index) => (
                            <div key={index} className={classes.row}>
                                <div className={classes.svgWrapper}>
                                    <item.IconComponent />
                                </div>
                                <div className={classes.textWrapper}>
                                    <Typography variant="h3">
                                        {item.title}
                                    </Typography>
                                    <Typography component="p">
                                        {item.description}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Banner
                        className={classes.banner}
                        severity="info"
                        icon=<Information />
                    >
                        { /* prettier-ignore */ }
                        <Trans i18nKey="q&a.page.banner.infoText">
                            This feature utilizes generative AI technology, powered by the language models from <span lang="en">AWS</span>.
                        </Trans>
                    </Banner>
                </Frame>
                <div className={classes.poweredByContainer}>
                    <Typography className={classes.poweredByText} component="p">
                        Powered by QuickSight Q
                    </Typography>
                </div>
            </ResponsiveBox>
            <ActionAreaButtons helpLink={getHelpLink('dataQAndA')} />
        </div>
    );
};
