import React from 'react';
import { useLocation } from 'react-use';
import createAuth0Client from '@auth0/auth0-spa-js';
import { Trans, useTranslation } from 'react-i18next';
import {
    createStyles,
    makeStyles,
    Theme,
} from '@bb-ui/react-library/dist/components/styles';
import { useHistory } from 'react-router-dom';
import {
    AuthProviderCacheProps,
    useAuthProviderCache,
} from './useAuthProviderCache';
import { Auth0CallbackHandler } from './Auth0CallbackHandler';
import { UASCallbackHandler } from './UASCallbackHandler';
import { Auth0Context, Auth0Provider } from './Auth0Context';
import { useAppLoadingContext } from '../../contexts/AppLoadingContext';
import { Message } from '../../components/Message/Message';

export interface Auth0CallbackProps {
    onRedirect: (path: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorContainer: {
            background: theme.palette.background.b3,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        },
    }),
);

export const AuthHandlingWrapper: React.FC = props => {
    const { cache, setCache } = useAuthProviderCache();
    const { setLoadingFlag } = useAppLoadingContext();
    const [authCallbackFailed, setAuthCallbackFailed] = React.useState(false);
    const history = useHistory();
    const classes = useStyles();

    const { t } = useTranslation();
    const location = useLocation();

    const initiateLoginWithRedirect = React.useCallback(
        async (props: AuthProviderCacheProps) => {
            setLoadingFlag('app.redirecting', true);
            const auth0Client = await createAuth0Client({
                ...props,
                redirect_uri: `${window.location.origin}/callback`,
            });
            setCache(props);

            auth0Client.loginWithRedirect({
                audience: props.audience,
                appState: { returnPath: window.location.pathname },
            });
        },
        [setCache, setLoadingFlag],
    );

    if (authCallbackFailed) {
        return (
            <div className={classes.errorContainer}>
                <Message
                    variant="error"
                    message={
                        <Trans i18nKey="general.applicationLoadError">
                            The <span lang="en">Anthology Illuminate</span>{' '}
                            application didn't load.
                        </Trans>
                    }
                    secondaryMessage={t('general.pleaseRefreshThePage')}
                />
            </div>
        );
    }

    if (location.pathname === '/sign-in-callback') {
        return <UASCallbackHandler />;
    }

    if (location.pathname === '/callback') {
        if (cache === undefined) {
            history.push('/');
            throw new Error('Invalid login attempt - no authContextData found');
        }
        const error = new URLSearchParams(window.location.search).get('error');
        if (error) {
            console.error(
                `Authentication callback returned an error -> ${error}`,
            );
            setAuthCallbackFailed(true);
            // there should be no flag set up at this point
            // but the default state is that the loading indicator is shown
            // before any flags appear; so this should force it to disappear again
            setLoadingFlag('auth.init', false);
            history.push('/');
            return <></>;
        }
    }

    if (cache === undefined) {
        return (
            <Auth0Context.Provider
                value={{
                    isAuthenticated: false,
                    loading: false,
                    initiateLoginWithRedirect,
                }}
            >
                {props.children}
            </Auth0Context.Provider>
        );
    }
    return (
        <Auth0Provider
            {...cache}
            redirect_uri={`${window.location.origin}/callback`}
            initiateLoginWithRedirect={initiateLoginWithRedirect}
        >
            {location.pathname === '/callback' ? (
                // at this moment silent auth should work
                // having it here makes it possible to not to have to
                // auth twice, as the context stays alive after the redirect
                <Auth0CallbackHandler />
            ) : (
                // the actual content of the page
                // -> not rendered when path is /callback, to not to re-render
                // the page before silent auth happens
                props.children
            )}
        </Auth0Provider>
    );
};
