import { Home } from '@bb-ui/icons/dist/medium/Home';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationRoutedLink } from '../NavigationRouting/NavigationRoutedLink';
import { NavigationRouted } from '../NavigationRouting/NavigationRouted';
import { HomePage } from '../HomePage/HomePage';
import { useUser } from '../../hooks/useUser';

export const HomeContent: React.FC<{
    navigationIndex: number;
}> = props => {
    const { t } = useTranslation();
    const user = useUser();
    return (
        <NavigationRoutedLink
            key="home"
            path=""
            exact
            label={t('general.home').toString()}
            icon={<Home />}
            testId="sidebar-home-button"
            index={props.navigationIndex}
        >
            <NavigationRouted>
                <HomePage
                    userHasSettingsAccess={user?.isInstitutionAdmin()}
                    userHasDataQAndA={user?.hasDataQAndA()}
                />
            </NavigationRouted>
        </NavigationRoutedLink>
    );
};
